import { GridColDef } from '@material-ui/data-grid'

export const COLORS = {
  white: '#FFFFFF',
  veryMostlyWhite: '#F8F8F8',
  veryLightGray: '#fafafa',
  cyanForte: '#11BFB6',
  red: '#FF0000',
  black: '#000000',
}

export const BREAKPOINTS = {
  mobile: '(max-width: 426px)',
  smallTablet: '(max-width: 600px)',
  tablet: '(max-width: 800px)',
  laptop: '(max-width: 1024px)',
  desktop: '(min-width: 1025px)',
}

export const REGISTRATION_STEP = {
  REGISTER: 0,
  PERSONAL: 1,
  PROFESSIONAL: 2,
}

export const REGISTRATION_STEP_LABELS = [ 'Créer un compte', 'Informations personnelles', 'Informations professionnelles' ]

export const ACTION_STATUS = [
  { key: 'À réaliser', label: 'À réaliser' },
  { key: 'En cours', label: 'En cours' },
  { key: 'Réalisée', label: 'Réalisée' },
  { key: 'Annulée', label: 'Annulée' },
  { key: 'Refusée', label: 'Refusée' },
]
export const BENEFICIARY_STATUS = [
  { key: 'Sans emploi', label: 'Sans emploi' },
  { key: 'Salarié', label: 'Salarié' },
  { key: 'Agent de la fonction publique', label: 'Agent de la fonction publique' },
  { key: 'Intérimaire', label: 'Intérimaire' },
  { key: 'Chef d\'entreprise', label: 'Chef d\'entreprise' },
  { key: 'Indépendant', label: 'Indépendant' },
]

export const BENEFICIARY_CONTRACT_TYPE = [
  { key: 'Non applicable', label: 'Non applicable' },
  { key: 'CDI', label: 'CDI' },
  { key: 'CDD', label: 'CDD' },
  { key: 'Contractuelle', label: 'Contractuelle' },
  { key: 'Apprentissage', label: 'Apprentissage' },
]

export const BENEFICIARY_LEVEL_SCHOOL = [
  { key: 'Pas de diplôme', label: 'Pas de diplôme' },
  { key: 'Brevet', label: 'Brevet' },
  { key: 'Bac professionnel', label: 'Bac professionnel' },
  { key: 'Bac + 2', label: 'Bac + 2' },
  { key: 'Bac + 3', label: 'Bac + 3' },
  { key: 'Bac +4', label: 'Bac +4' },
  { key: 'Bac + 5 ou +', label: 'Bac + 5 ou +' },
]

export const BENEFICIARY_LANGUAGE_LEVEL = [
  { key: 'A1 Débutant', label: 'A1 Débutant' },
  { key: 'A2 Connaissance de base', label: 'A2 Connaissance de base' },
  { key: 'B1 Connaissance améliorées', label: 'B1 Connaissance améliorées' },
  { key: 'B2 Autonomie linguistique', label: 'B2 Autonomie linguistique' },
  { key: 'C1 Connaissances expertes', label: 'C1 Connaissances expertes' },
  { key: 'C2 Maîtrise', label: 'C2 Maîtrise' },
]

export const BENEFICIARY_BOE_TYPE = [
  { key: 'BOETH', label: 'BOETH (Reconnaissance qualité de travailleur handicapé)' },
  { key: 'Invalidité de catégorie 1, 2, 3', label: 'Invalidité de catégorie 1, 2, 3' },
  { key: 'Incapacité partielle permanente > 10 %', label: 'Incapacité partielle permanente > 10 % (Maladie professionnelle, accident du travail)' },
]

export const BENEFICIARY_TYPE_WORK_ARREST = [
  { key: 'Arrêt Maladie', label: 'Arrêt Maladie' },
  { key: 'Accident du travail', label: 'Accident du travail' },
  { key: 'Maladie Professionnelle', label: 'Maladie Professionnelle' },
  { key: 'CLM (Congé Longue Maladie)', label: 'CLM (Congé Longue Maladie)' },
  { key: 'CLD (Congé Longue Durée)', label: 'CLD (Congé Longue Durée)' },
]
export const COLUMNS_BENEFICIARIES_LIST: GridColDef[] = [
  { field: 'firstname', description: 'Prénom', align: 'left', width: 170 },
  { field: 'lastname', description: 'Nom', align: 'left', width: 170 },
  { field: 'birthday', description: 'Date de naissance', align: 'left', width: 70 },
  { field: 'isBOE', description: 'Situation', align: 'left', width: 70 },
  { field: 'isWorkArrest', description: 'Arrêt', align: 'left', width: 70 },
]

export const COLUMNS_ACTIONS_LIST: GridColDef[] = [
  { field: 'deviceType', description: 'Dispositif', align: 'left', width: 170 },
  { field: 'status', description: 'Statut', align: 'left', width: 70 },
  { field: 'createdDate', description: 'Création le', align: 'left', width: 70 },
  { field: 'consultant', description: 'Consultant', align: 'left', width: 70 },
  { field: 'organism', description: 'Organisme', align: 'left', width: 70 },
  { field: 'updateDate', description: 'Modification le', align: 'left', width: 90 },
  { field: 'preview', description: '', align: 'left', width: 70 },
]
export const LOGO_BASIQUE = 'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/logo_transparent_background.png?alt=media&token=83c208aa-206a-4e34-9eff-cd237230e9a6'

export const IMAGES_CARROUSEL = [
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/CARROUSEL_ILLUS_1.png?alt=media&token=44343bee-6e4d-43a1-8cae-a839c7838712',
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/CARROUSEL_ILLUS_2.png?alt=media&token=9ea03dd1-fa9f-4a3c-baa1-d97a146e0aa2',
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/CARROUSEL_ILLUS_3.png?alt=media&token=264d0064-1e73-42fa-867d-40c161a855ea',
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/CARROUSEL_ILLUS_4.png?alt=media&token=e144c791-a4a2-4239-b5cb-ed688c9b8952',
]

export const IMAGES_CARROUSEL_MOBILE = [
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/TEXT_CARROUSEL_1.png?alt=media&token=5a360168-584f-46aa-9fd0-8a7a8e539861',
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/TEXT_CARROUSEL_2.png?alt=media&token=6d0cf15e-d64d-4de1-b9dc-fd524b2e569a',
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/TEXT_CARROUSEL_3.png?alt=media&token=b1cd3f5e-54fe-44aa-856f-699b147b383d',
  'https://firebasestorage.googleapis.com/v0/b/samibyt4w-77.appspot.com/o/TEXT_CARROUSEL_4.png?alt=media&token=10661d73-aadf-41f3-bb4c-e024a9927d49',
]

