import React, { FC, useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { InputField } from '../molecules/InputField'
import { DefaultButton } from '../atoms/DefaultButton'
import { useHistory } from 'react-router-dom'
import { auth } from '../utils/firebase'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signUpSchema } from '../utils/validation'
import { LoginField } from '../utils/type'
import { Terms } from '../molecules/Terms'
import { useStateMachine } from 'little-state-machine'
import { cleanGlobalState, updateIsAuthenticated } from '../stores/store'
import { Popup } from '../molecules/Popup'
import { getErrorMessage } from '../utils/PopupMessage'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'

export const SignUpForm: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const [ open, setOpen ] = useState(false)
  const [ popupMessage, setPopupMessage ] = useState<string>('')
  const { actions } = useStateMachine({ cleanGlobalState, updateIsAuthenticated })

  const signUpWithEmailAndPassword = (data: LoginField) => {
    auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(() => {
        actions.updateIsAuthenticated(true)
        history.push('/profil')
      })
      .catch((error) => {
        setOpen(true)
        setPopupMessage(getErrorMessage(error.code))
      })
  }

  const handleConnection = () => {
    actions.cleanGlobalState('')
    history.push('/connexion')
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  })

  return (
    <>
      <Popup
        open={open}
        title='Erreur'
        message={popupMessage}
        handleClose={() => setOpen(false)}
        handleValidate={() => undefined}
        backButton='Retour'
      />
      <form className={classes.form}>
        <InputField
          control={control}
          name='email'
          error={errors}
          placeholder={content.placeholderEmail}
          label={content.labelEmail}
          styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
        />
        <InputField
          control={control}
          name='password'
          error={errors}
          label={content.labelPassword}
          isPassword={true}
          styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
        />
        <InputField
          control={control}
          name='confirmPassword'
          error={errors}
          label={content.labelConfirmPassword}
          isPassword={true}
          styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
        />
        <Terms />
        <DefaultButton
          label={content.signUp}
          onClick={handleSubmit(signUpWithEmailAndPassword)}
          styleProps={clsx(classes.customButton, tablet ? classes.fixedWidthMobile : classes.fixedWidth)}
        />
        <Typography align='center' className={classes.typo}>
          {content.alreadyAccount}
          <DefaultButton styleProps={classes.link} variant='text' label={content.signIn} onClick={handleConnection} />
        </Typography>
        <Box component='div' className={clsx(classes.mention, tablet && classes.fixedMentionTablet)} display='flex' flexDirection={mobile ? 'column' : 'row'}>
          <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
          <Typography variant='caption'> Tous droits réservés</Typography>
        </Box>
      </form>
    </>
  )
}

const content = {
  labelEmail: 'E-mail',
  placeholderEmail: 'Saisissez votre e-mail',
  labelPassword: 'Mot de passe',
  labelConfirmPassword: 'Vérification du mot de passe',
  signIn: 'Se connecter',
  signUp: 'Inscription',
  alreadyAccount: 'Déjà un compte ? ',
}

const useStyles = makeStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
  link: {
    color: COLORS.black,
    fontWeight: 'bold',
    textDecorationLine: 'none',
  },
  fixedWidth: {
    width: '40vw',
  },
  fixedWidthMobile: {
    width: '80vw',
  },
  customButton: {
    marginBottom: '2rem',
    minWidth: '19rem',
  },
  typo: {
    fontSize: 12,
  },
  mention: {
    zIndex: 2,
    color: COLORS.white,
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  fixedMentionTablet: {
    color: COLORS.black,
    width: '100%',
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: '3rem',
    bottom: 5,
    right: 5,
  },
})
