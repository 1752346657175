import React, { FC, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { AuthContext } from '../utils/hooks'
import { useStateMachine } from 'little-state-machine'
import { InputField } from '../molecules/InputField'
import { DateField } from '../molecules/DateField'
import { DefaultButton } from '../atoms/DefaultButton'
import { profileBeneficiarySchema, profileSchema } from '../utils/validation'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateProfile } from '../stores/store'
import { Profile } from '../utils/type'
import { generateProfileFromForm, saveProfile } from '../stores/fbProfile'
import { useHistory } from 'react-router-dom'

interface EditProfileFormProps {
  nextStep: () => void
}

export const EditProfileForm: FC<EditProfileFormProps> = ({ nextStep }: EditProfileFormProps) => {
  const authentication = useContext(AuthContext)
  const history = useHistory()
  const { state, actions } = useStateMachine({ updateProfile })
  const validationSchema = state.profile.isConsultant ? profileSchema : profileBeneficiarySchema

  const updatingProfile = async(data: Profile) => {
    const updatedProfile = generateProfileFromForm(data, state.profile.isConsultant)
    await saveProfile(authentication?.uid, updatedProfile)
    actions.updateProfile(updatedProfile)
    nextStep()
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
  })

  return (
    <>
      <form>
        <Grid container justifyContent='center' spacing={2}>
          <InputField name='lastname' error={errors} control={control} label={content.lastname} defaultValue={state.profile.lastname} />
          <InputField name='firstname' error={errors} control={control} label={content.firstname} defaultValue={state.profile.firstname} />
          {!state.profile.isConsultant && (
            <DateField name='birthday' error={errors} control={control} label={content.birthday} defaultValue={state.profile.birthday} />
          )}
          <InputField name='street' error={errors} control={control} label={content.street} defaultValue={state.profile.street} />
          <InputField name='postcode' error={errors} control={control} label={content.postcode} defaultValue={state.profile.postcode} />
          <InputField name='city' error={errors} control={control} label={content.city} defaultValue={state.profile.city} />
          <InputField name='phone' error={errors} control={control} label={content.phone} defaultValue={state.profile.phone} />
        </Grid>
      </form>
      <Grid container justifyContent='flex-start' spacing={2}>
        <Grid item><DefaultButton onClick={() => history.push('/')} label={content.buttonPrevious} variant='outlined'/></Grid>
        <Grid item><DefaultButton onClick={handleSubmit(updatingProfile)} label={content.buttonNext} /></Grid>
      </Grid>

    </>
  )
}

const content = {
  buttonPrevious: 'Annuler',
  buttonNext: 'Suivant',
  lastname: 'Nom',
  street: 'Adresse',
  city: 'Ville',
  firstname: 'Prénom',
  postcode: 'Code postal',
  birthday: 'Date de naissance',
  phone: 'Téléphone (facultatif)',
}
