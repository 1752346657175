import React, { FC } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface PortalTemplateProps {
  sideMenu: JSX.Element | null
  main: JSX.Element | null
  display?: 'none' | 'block'
  smartScreen?: boolean
}

export const PortalTemplate: FC<PortalTemplateProps> = ({ main, sideMenu, display, smartScreen }: PortalTemplateProps) => {
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <div className={smartScreen ? classes.smartRoot : classes.root}>
      {sideMenu}
      <main className={classes.content} style={{ display: display }}>
        {main}
        <Box component='div' className={clsx(classes.mention, tablet && classes.fixedMentionTablet)} display='flex' flexDirection={mobile ? 'column' : 'row'}>
          <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
          <Typography variant='caption'> Tous droits réservés</Typography>
        </Box>
      </main>

    </div>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: COLORS.veryMostlyWhite,
  },
  smartRoot: {
    display: 'block',
    backgroundColor: COLORS.veryMostlyWhite,
  },
  content: {
    flexGrow: 1,
    zIndex: 1,
    minHeight: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  mention: {
    color: COLORS.white,
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  fixedMentionTablet: {
    color: COLORS.black,
    width: '100%',
    justifyContent: 'flex-end',
    position: 'relative',
  },
})
