import React, { FC, useContext, useEffect, useState } from 'react'
import { auth } from '../utils/firebase'
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { updateAccess } from '../stores/fbConsultant'
import { searchProfile } from '../stores/fbProfile'
import { updatePermissionForUidConsultant } from '../stores/store'
import { Box, Container, Typography } from '@material-ui/core'
import { DefaultButton } from '../atoms/DefaultButton'
import { Logo } from '../atoms/Logo'
import Grid from '@material-ui/core/Grid'
import { AuthContext, useQueryParameters } from '../utils/hooks'
import { Popup } from '../molecules/Popup'
import { InvitationPayload } from '../utils/type'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'

const RequestAccess: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const authentication = useContext(AuthContext)
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const [ open, setOpen ] = useState(false)
  const [ popupMessage, setPopupMessage ] = useState<string>('')
  const [ profileConsultant, setProfileConsultant ] = useState<firebase.firestore.DocumentData | undefined>()
  const { state, actions } = useStateMachine({ updatePermissionForUidConsultant })

  const queryParameters = useQueryParameters()

  const updatingAccess = () => {
    updateAccess(state.permissionForUidConsultant.uid, [ authentication?.uid! ])
      .then(() => actions.updatePermissionForUidConsultant({ uid: '', newAccount: false }))
      .then(() => history.push('/'))
  }

  const handleDenyAccess = () => {
    actions.updatePermissionForUidConsultant({ uid: '', newAccount: false })
    history.push('/')
  }

  const handleParamUidConsultant = () => {
    const params: string | null = queryParameters?.get('p')
    if (params) {
      const invitationPayload = JSON.parse(atob(params!)) as InvitationPayload
      if (invitationPayload.uid) {
        actions.updatePermissionForUidConsultant(invitationPayload)
      }
    }
  }

  useEffect(() => {
    if (!state.isLoadingUser) {
      handleParamUidConsultant()
      if (!state.permissionForUidConsultant) {
        setOpen(true)
        setPopupMessage('Aucune demande d\'association n\'a été enregistrée. Si le message persiste, contacter l’assistance technique\n.')
      }
      if (!auth.currentUser && state.permissionForUidConsultant.newAccount) {
        history.push('/beneficiaire/inscription')
      }
    }
  }, [ state.isLoadingUser ])

  useEffect(() => {
    if (state.permissionForUidConsultant.uid) {
      searchProfile(state.permissionForUidConsultant.uid).then((result) => {
        return setProfileConsultant(result)
      })
    } else {
      if (!auth.currentUser && state.permissionForUidConsultant.newAccount) {
        history.push('/beneficiaire/inscription')
      } else {
        history.push('/')
      }
    }
  }, [ state.permissionForUidConsultant ])

  return (
    <Container maxWidth='sm' className={classes.root}>
      <Popup
        open={open}
        title='Erreur'
        message={popupMessage}
        handleClose={() => history.push('/connexion')}
        handleValidate={() => undefined}
        backButton='Fermer'
      />
      <Logo styleProps={mobile ? classes.logoWidthMobile : classes.logoWidth} />
      <Typography variant='h4' align='center' className={classes.typoTitle}>
        {content.title}
      </Typography>
      <Typography align='center' className={classes.typoSubtitle}>
        {content.subject}
        <span className={classes.bolder}>
          {content.type} {profileConsultant?.firstname} {profileConsultant?.lastname}
        </span>
        {content.request}
      </Typography>
      <Grid container justifyContent='space-around' alignItems='center'>
        <DefaultButton variant='outlined' onClick={handleDenyAccess} label={content.deniedAccess} />
        <DefaultButton onClick={updatingAccess} label={content.allowAccess} />
      </Grid>
      <Box component='div' className={classes.mention} display='flex' flexDirection={mobile ? 'column' : 'row'} >
        <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
        <Typography variant='caption'> Tous droits réservés</Typography>
      </Box>
    </Container>
  )
}

export default RequestAccess

const content = {
  title: 'Demande d\'accès au parcours',
  subject: 'Le ',
  type: 'consultant',
  request: ' souhaite accéder à votre compte pour suivre votre parcours',
  allowAccess: 'Autoriser l\'accès',
  deniedAccess: 'Refuser l\'accès',
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  logoWidth: {
    width: '15rem',
  },
  logoWidthMobile: {
    width: '10rem',
  },
  typoTitle: {
    marginTop: '3rem',
    marginBottom: '5rem',
    fontWeight: 'bold',
  },
  bolder: {
    fontWeight: 'bold',
  },
  typoSubtitle: {
    paddingBottom: '3rem',
  },
  mention: {
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
})
