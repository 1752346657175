import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { DefaultButton } from '../atoms/DefaultButton'
import { useStateMachine } from 'little-state-machine'
import { Action, ActionHistory, ModalType } from '../utils/type'
import { generateActionFromForm, saveAction, updateAction, updateActionHistory } from '../stores/fbAction'
import { SelectField } from '../molecules/SelectField'
import { ALL_DEVICE, DEVISE_BOETH, DEVISE_BOETH_ARREST, DEVISE_NOT_BOETH, DEVISE_NOT_BOETH_ARREST } from '../utils/actionConstants'
import { InputField } from '../molecules/InputField'
import { IconButton, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core'
import { Timeline } from '@material-ui/lab'
import { Close } from '@material-ui/icons'
import { ACTION_STATUS, BREAKPOINTS, COLORS } from '../utils/constants'
import { ACTION_UPDATES } from '../utils/actionHistoryConstants'
import { yupResolver } from '@hookform/resolvers/yup'
import { actionFields } from '../utils/validation'
import { TimelineActionHistory } from '../molecules/TimelineActionHistory'
import { TabPanel } from '../molecules/TabPanel'
import clsx from 'clsx'

interface ActionFormModalProps {
  open: boolean
  handleClose: () => void
  modalType: ModalType
  setModalType: Dispatch<SetStateAction<ModalType>>
}

export const ActionFormModal: FC<ActionFormModalProps> = ({ open, handleClose, modalType, setModalType }: ActionFormModalProps) => {
  const classes = useStyles()
  const { state } = useStateMachine()
  const [ tabIndex, setTabIndex ] = useState<number>(0)
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleDate = () => {
    if (state.selectedAction.createdDate) {
      return { createdDate: state.selectedAction.createdDate, updateDate: new Date() }
    } else {
      return { createdDate: new Date(), updateDate: undefined }
    }
  }

  const updatingAction = async(data: Action) => {
    const date = handleDate()
    const savedAction = generateActionFromForm(data, date.createdDate, date.updateDate, state.consultant.organism, `${state.profile.lastname} ${state.profile.firstname}`)
    const updatedAction = generateActionFromForm(data, state.selectedAction.createdDate!, date.updateDate, state.selectedAction.organism, state.selectedAction.consultant)
    const author = `${state.profile.lastname} ${state.profile.firstname}`
    if (state.selectedAction.id) {
      await updateAction(state.selectedBeneficiary.id, state.selectedAction.id, updatedAction)
      const actionHistory: Array<ActionHistory> = []
      for (const actionUpdate in dirtyFields) {
        const today = new Date()
        if (actionUpdate === 'status' && data.status !== state.selectedAction.status) {
          actionHistory.push({
            changeType: (ACTION_UPDATES as any)[ actionUpdate ].key,
            organism: state.consultant.organism,
            currentState: data.status,
            previousState: state.selectedAction.status,
            updateDate: today,
            author,
          })
        } else if (actionUpdate === 'deviceType' && data.deviceType !== state.selectedAction.deviceType) {
          actionHistory.push({
            changeType: (ACTION_UPDATES as any)[ actionUpdate ].key,
            organism: state.consultant.organism,
            currentState: data.deviceType,
            previousState: state.selectedAction.deviceType,
            updateDate: today,
            author,
          })
        } else if (actionUpdate === 'actionDescription' && data.actionDescription !== state.selectedAction.actionDescription) {
          actionHistory.push({
            changeType: (ACTION_UPDATES as any)[ actionUpdate ].key,
            organism: state.consultant.organism,
            updateDate: today,
            author,
          })
        }
      }
      await updateActionHistory(state.selectedBeneficiary.id, state.selectedAction.id, actionHistory)
    } else {
      const actionHistory: ActionHistory[] = [
        {
          changeType: (ACTION_UPDATES as any).creation.key,
          organism: state.consultant.organism,
          currentState: data.status,
          previousState: state.selectedAction.status,
          updateDate: new Date(),
          author,
        },
      ]
      const createdActionId: string | void = await saveAction(state.selectedBeneficiary.id, savedAction)
      await updateActionHistory(state.selectedBeneficiary.id, createdActionId as string, actionHistory)
    }
    handleClose()
  }

  const deviceType = () => {
    switch (state.selectedBeneficiary.isBOE) {
      case 'BOETH':
        return state.selectedBeneficiary.isWorkArrest === 'Oui' ? DEVISE_BOETH_ARREST : DEVISE_BOETH
      case 'Non BOETH':
        return state.selectedBeneficiary.isWorkArrest === 'Non' ? DEVISE_NOT_BOETH : DEVISE_NOT_BOETH_ARREST
    }
  }

  const handleContent = () => {
    switch (modalType) {
      case 'add':
        return { title: content.addTitle, backButton: content.cancelButton, nextButton: content.addButton, fieldDisabled: false }
      case 'read-only':
      case 'preview':
        return { title: content.PreviewTitle, backButton: content.closeButton, nextButton: content.PreviewButton, fieldDisabled: true }
      case 'modify':
        return { title: content.modifyTitle, backButton: content.cancelButton, nextButton: content.modifyButton, fieldDisabled: false }
    }
  }
  const variableContent = handleContent()

  interface FormValues {
    [ status: string ]: string;
    deviceType: string;
    actionDescription: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    resolver: yupResolver(actionFields),
  })

  return (
    <Modal open={open} className={classes.modal}>
      <Grid container justifyContent='center' className={clsx(classes.paper, !mobile ? classes.fixedPadding : classes.fixedPaddingMobile)}>
        <Grid container style={{ marginBottom: '1.9rem' }}>
          <Grid container item xs={12} justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant={mobile ? 'h6' : 'h5'} className={classes.title}>
              {variableContent.title}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close fontSize={mobile ? 'small' : 'medium'} />
            </IconButton>
          </Grid>
          <Grid container item xs={12}>
            {modalType === 'read-only' ? (
              <Grid container item className={classes.verticalMargin} style={{ marginTop: '1rem' }}>
                <Typography variant='h6' component='h6' color='textSecondary' className={`${classes.fixedWidth} ${classes.verticalMarginTitle}`}>
                  Statut du dispositif
                </Typography>
                <Typography variant='body1' component='div'>{state.selectedAction?.status}</Typography>
              </Grid>
            ) : (
              <SelectField
                name='status'
                error={errors}
                control={control}
                label={content.status}
                options={ACTION_STATUS}
                widthInnerContainer='100%'
                disabled={variableContent.fieldDisabled}
                defaultValue={state.selectedAction?.status}
                styleProps={classes.fixedWidth}
              />
            )}
          </Grid>
          <Grid container item xs={12}>
            {modalType === 'read-only' ? (
              <Grid container item className={classes.verticalMargin}>
                <Typography variant='h6' component='h6' color='textSecondary'
                            className={`${classes.fixedWidth} ${classes.verticalMarginTitle}`}>Dispositif</Typography>
                <Typography variant='body1' component='div'>{ALL_DEVICE.find(d => d.key === state.selectedAction?.deviceType)?.label}</Typography>
              </Grid>
            ) : (
              <SelectField
                name='deviceType'
                error={errors}
                control={control}
                label={content.deviceType}
                options={deviceType()!}
                widthInnerContainer='100%'
                disabled={variableContent.fieldDisabled}
                defaultValue={state.selectedAction?.deviceType}
                styleProps={classes.fixedWidth}
              />
            )}
          </Grid>
        </Grid>
        {modalType !== 'add' &&
        <>
          <Tabs className={classes.tabContainer} value={tabIndex} onChange={handleTabChange} aria-label='action informations' indicatorColor='primary'>
            <Tab className={classes.tabButton} label='Général' />
            <Tab className={classes.tabButton} label='Historique des modifications' />
          </Tabs>
          <TabPanel className={classes.tabPanel} value={tabIndex} index={0}>
            {modalType === 'read-only' ? (
              <Grid container item className={classes.leftMargin}>
                <Typography variant='h6' component='h6' color='textSecondary'
                            className={`${classes.fixedWidth} ${classes.verticalMarginTitle}`}>Description</Typography>
                <Typography variant='body1' paragraph style={{ whiteSpace: 'pre-wrap' }}>{state.selectedAction?.actionDescription}</Typography>
              </Grid>
            ) : (
              <InputField
                name='actionDescription'
                error={errors}
                control={control}
                label={content.actionDescription}
                numberOfLigne={7}
                widthInnerContainer='100%'
                disabled={variableContent.fieldDisabled}
                defaultValue={state.selectedAction?.actionDescription}
                styleProps={classes.fixedWidth}
                helperText={content.length}
              />
            )}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={tabIndex} index={1}>
            <Grid container item justifyContent='center'>
              {state.selectedAction?.history && state.selectedAction.history.length > 0 ? (
                <Timeline align='left'>
                  {state.selectedAction.history.map((action, index, arr) =>
                    <TimelineActionHistory key={index} actionHistory={action} isLast={index === arr.length - 1} />,
                  )}
                </Timeline>
              ) : (
                <Typography variant='body2' component='div'>Il n&apos;y a pas encore eu de modifications sur ce dispositif.</Typography>
              )}
            </Grid>
          </TabPanel>
        </>
        }
        {modalType === 'add' &&
        <Grid container item xs={12}>
          <InputField
            name='actionDescription'
            error={errors}
            control={control}
            label={content.actionDescription}
            numberOfLigne={7}
            widthInnerContainer='100%'
            disabled={variableContent.fieldDisabled}
            defaultValue={state.selectedAction?.actionDescription}
            styleProps={classes.fixedWidth}
            helperText={content.length}
          />
        </Grid>
        }
        {modalType !== 'read-only' &&
        <Grid container item xs={12} justifyContent={tablet ? 'center' : 'flex-end'} direction='row'>
          <DefaultButton variant='outlined' onClick={handleClose} label={variableContent.backButton} styleProps={classes.button} />
          {variableContent.fieldDisabled ? (
            <DefaultButton onClick={() => setModalType('modify')} label={variableContent.nextButton} />
          ) : (
            <DefaultButton onClick={handleSubmit(updatingAction)} label={variableContent.nextButton} />
          )}
        </Grid>
        }
      </Grid>
    </Modal>
  )
}

const content = {
  addTitle: 'Ajouter une action',
  PreviewTitle: 'Détail de l\'action',
  modifyTitle: 'Modifier l\'action',
  status: 'Statut de l\'action',
  deviceType: 'Type de dispositif',
  actionDescription: 'Description de l\'action',
  createdDate: 'Date de création',
  updateDate: 'Date de modification',
  length: '800 caractères max.',
  cancelButton: 'Annuler',
  closeButton: 'Fermer',
  addButton: 'Ajouter',
  PreviewButton: 'Modifier',
  modifyButton: 'Sauvegarder',
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  paper: {
    width: '100%',
    maxWidth: '50rem',
    backgroundColor: COLORS.white,
    border: '0.1rem solid #00000070',
    borderRadius: '0.3rem',
  },
  fixedPadding: {
    padding: '1rem 2rem',
  },
  fixedPaddingMobile: {
    padding: '0.5rem',
  },
  tabPanel: {
    width: '100%',
    maxHeight: '45vh',
    overflow: 'hidden auto',
    padding: '.5rem 0',
  },
  tabButton: {
    minWidth: '100px',
  },
  tabContainer: {
    flexGrow: 1,
    flexBasis: '100%',
    marginBottom: '.5rem',
  },
  verticalMargin: {
    margin: theme.spacing(1, 0),
  },
  verticalMarginTitle: {
    margin: theme.spacing(0.5, 0),
  },
  leftMargin: {
    marginLeft: theme.spacing(2),
  },
  fixedWidth: {
    width: '100%',
  },
  button: {
    marginRight: '1rem',
  },
}))
