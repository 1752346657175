import React, { FC } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { fr } from 'date-fns/esm/locale'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Grid, makeStyles, ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface DateFieldProps {
  control: Control<Record<string, any>> | undefined
  name: string
  label: string
  error: FieldErrors
  defaultValue?: string
  styleProps?: string
  disabled?: boolean
}

export const DateField: FC<DateFieldProps> = ({
  name,
  label,
  error,
  control,
  defaultValue,
  styleProps,
  disabled,
}: DateFieldProps) => {
  const classes = useStyles()
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <Grid item>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref } }) => (
              <KeyboardDatePicker
                ref={ref}
                autoOk
                okLabel={'Valider'}
                cancelLabel={'Annuler'}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                label={label}
                value={value}
                inputVariant='outlined'
                format='dd/MM/yyyy'
                margin='normal'
                className={clsx(classes.input, styleProps, tablet ? classes.inputWidthMobile : classes.inputWidth)}
                onChange={(value) => onChange(value)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                error={!!error[ name ]}
                helperText={error[ name ] && error[ name ]?.message}
              />
            )}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Grid>
  )
}

const materialTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.cyanForte,
    },
  },
})

const useStyles = makeStyles({
  input: {
    minWidth: '19rem',
    marginTop: '1.3rem',
    '& label.Mui-focused': {
      color: COLORS.cyanForte,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.cyanForte,
      },
    },
  },
  inputWidth: {
    width: '32.3vw',
  },
  inputWidthMobile: {
    width: '70vw',
  },
})
