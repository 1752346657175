import React, { FC } from 'react'
import { SignInForm } from '../organisms/SignInForm'
import { HalfSplitTemplate } from '../templates/HalfSplitTemplate'
import { Logo } from '../atoms/Logo'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'

const SignIn: FC = () => {
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  return (
    <HalfSplitTemplate
      main={
        <>
          <Logo styleProps={mobile ? classes.logoWidthMobile : classes.logoWidth} />
          <SignInForm />
        </>
      }
    />
  )
}

const useStyles = makeStyles({
  logoWidth: {
    width: '15rem',
  },
  logoWidthMobile: {
    width: '10rem',
  },
})

export default SignIn
