import { Beneficiary, BeneficiaryProfile, Profile } from '../utils/type'
import { db } from '../utils/firebase'
import firebase from 'firebase/app'
import { fetchProfiles } from './fbProfile'

export const searchBeneficiary = async(uid: string | undefined) =>
  await db
    .collection('beneficiary')
    .doc(uid)
    .get()
    .then((payload) => payload.data() as Beneficiary)
    .catch((error) => {
      console.log('Error searchBeneficiary ======>', error)
      return undefined
    })

export const saveBeneficiary = async(uid: string | undefined, beneficiary: Beneficiary, dateWorkArrest?: string) => {
  await db
    .collection('beneficiary')
    .doc(uid)
    .set({...beneficiary, dateWorkArrest: dateWorkArrest })
    .then((payload) => payload)
    .catch((error) => console.log('Error save beneficiary ======>', error))
}

export const deleteBeneficiary = (uid: string | undefined) => {
   db
    .collection('beneficiary')
    .doc(uid)
    .delete()
    .then((payload) => payload)
    .catch((error) => console.log('Error deleteBeneficiary ======>', error))
}

export const generateBeneficiaryFromForm = (formData: any, isBOE: boolean, isWorkArrest: boolean): Beneficiary => ({
  status: formData.status,
  contractType: formData.contractType,
  postOccupied: formData.postOccupied,
  codeAPE: formData.codeAPE,
  formation: formData.formation,
  levelSchool: formData.levelSchool,
  language: formData.language,
  languageLevel: formData.languageLevel,
  isBOE,
  statusBOE: formData.statusBOE,
  isWorkArrest,
  dateWorkArrest: formData.dateWorkArrest,
  typeWorkArrest: formData.typeWorkArrest,
})

const fetchBeneficiaries = async(profiles: Map<string, Profile>, beneficiaryList: string[]) => {
  return await db
    .collection('beneficiary')
    .where(firebase.firestore.FieldPath.documentId(), 'in', beneficiaryList)
    .get()
    .then((payload) => {
      const beneficiaryCache: BeneficiaryProfile[] = []
      payload.forEach((doc) => {
        beneficiaryCache.push({ id: doc.id, profile: profiles.get(doc.id), beneficiary: doc.data() as Beneficiary })
      })
      return beneficiaryCache
    })
}

export const loadBeneficiaries = async(beneficiaryList: string[]) => {
  if (beneficiaryList?.length) {
    return fetchProfiles(beneficiaryList).then((profiles) => fetchBeneficiaries(profiles, beneficiaryList))
  }
}
