import React, { ChangeEvent, FC } from 'react'
import { FormGroup, Grid, Switch, Typography, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { COLORS } from '../utils/constants'

interface ToggleSwitchProps {
  value: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  name?: string
  title?: string
  subtitle?: string
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({ value, onChange, name, title, subtitle }: ToggleSwitchProps) => {
  const classes = useStyles()

  return (
    <>
      <Grid item>
        <Typography component='h2' className={classes.title}>
          {title}
        </Typography>
        <Typography variant='caption' className={classes.subtitle}>
          {subtitle}
        </Typography>
      </Grid>
      <FormGroup>
        <Typography component='div'>
          <Grid component='label' container alignItems='center' spacing={1}>
            <Grid item>Non</Grid>
            <Grid item>
              <CustomSwitch checked={value} onChange={onChange} name={name} />
            </Grid>
            <Grid item>Oui</Grid>
          </Grid>
        </Typography>
      </FormGroup>
    </>
  )
}

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: COLORS.cyanForte,
    },
    '&$checked + $track': {
      backgroundColor: COLORS.cyanForte,
    },
  },
  checked: {},
  track: {},
})(Switch)

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '',
    color: 'rgba(0,0,0,0.51)',
  },
})
