export const initProfile = {
  lastname: '',
  firstname: '',
  street: '',
  city: '',
  postcode: '',
  phone: '',
  birthday: '',
  isConsultant: false,
}

export const initConsultant = {
  followedBeneficiaries: [],
  organism: '',
  function: '',
}

export const initBeneficiary = {
  status: '',
  contractType: '',
  postOccupied: '',
  codeAPE: '',
  formation: '',
  levelSchool: '',
  language: '',
  languageLevel: '',
  isBOE: false,
  statusBOE: '',
  isWorkArrest: false,
  dateWorkArrest: '',
  typeWorkArrest: '',
}

export const initSelectedAction = {
  id: '',
  status: '',
  deviceType: '',
  actionDescription: '',
  organism: '',
  consultant: '',
  createdDate: undefined,
  updateDate: undefined,
  history: [],
}

export const initSelectedBeneficiary = {
  id: '',
  firstname: '',
  lastname: '',
  birthday: '',
  isBOE: '',
  isWorkArrest: '',
}

export const initInvitationPayload = {
  uid: '',
  newAccount: false
}
