import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { LOGO_BASIQUE } from '../utils/constants'
import clsx from 'clsx'

interface LogoProps {
  styleProps?: string
}

export const Logo: FC<LogoProps> = ({ styleProps }: LogoProps) => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <img className={clsx(classes.logo, styleProps)} src={LOGO_BASIQUE} alt='Logo Sam.I' />
    </div>
  )
}

const useStyles = makeStyles({
  content: {
    margin: '2rem 0 2rem 0',
  },
  logo: {
    height: 'auto',
  },
})
