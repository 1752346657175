import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'

export const ProfessionalInformation: FC = () => {
  const classes = useStyles()
  const { state } = useStateMachine()

  return (
    <>
      {state.profile.isConsultant ? (
        <>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.organism}</span>
            {state.consultant.organism}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.function}</span>
            {state.consultant.function}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.status}</span>
            {state.beneficiary.status}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.contractType}</span>
            {state.beneficiary.contractType}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.postOccupied}</span>
            {state.beneficiary.postOccupied}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.codeAPE}</span>
            {state.beneficiary.codeAPE}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.formation}</span>
            {state.beneficiary.formation}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.levelSchool}</span>
            {state.beneficiary.levelSchool}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.language}</span>
            {state.beneficiary.language}
          </Typography>
          <Typography variant='body1' className={classes.typo}>
            <span className={classes.scope}>{content.languageLevel}</span>
            {state.beneficiary.languageLevel}
          </Typography>
        </>
      )}
    </>
  )
}

const content = {

  organism: 'Organisme/Entreprise: ',
  function: 'Poste occupé: ',
  status: 'Statut: ',
  postOccupied: 'Poste occupé: ',
  formation: 'Formation: ',
  language: 'Langue: ',
  contractType: 'Type de contrat: ',
  codeAPE: 'Code APE: ',
  levelSchool: 'Niveau de formation: ',
  languageLevel: 'Niveau de langue: ',
}

const useStyles = makeStyles(() => (
  {
    typo: {
      margin: '0.5rem',
    },
    scope: {
      fontWeight: 'bold',
    },
  }
))
