import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'

export const BoeInformation: FC = () => {
  const classes = useStyles()
  const { state } = useStateMachine()

  return (
    <Typography variant='body1' className={classes.typo}>
      <span className={classes.scope}>{content.statusBOE}</span>
      {state.beneficiary.statusBOE}
    </Typography>
  )
}

const content = {
  statusBOE: 'bénéficiaire de l\'obligation d\'emploi: ',
}

const useStyles = makeStyles(() => (
  {
    aligneCenter: {
      textAlign: 'center',
    },
    typo: {
      margin: '0.5rem',
    },
    scope: {
      fontWeight: 'bold',
    },
  }
))
