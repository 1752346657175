import React, { FC, useContext } from 'react'
import { InputField } from '../molecules/InputField'
import { useForm } from 'react-hook-form'
import { Consultant } from '../utils/type'
import { yupResolver } from '@hookform/resolvers/yup'
import { consultantFields } from '../utils/validation'
import { Grid } from '@material-ui/core'
import { DefaultButton } from '../atoms/DefaultButton'
import { generateConsultantFromForm, saveConsultant } from '../stores/fbConsultant'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../utils/hooks'
import { useStateMachine } from 'little-state-machine'
import { updateConsultant } from '../stores/store'

interface EditConsultantFormProps {
  previousStep: () => void
}

export const EditConsultantForm: FC<EditConsultantFormProps> = ({ previousStep }: EditConsultantFormProps) => {
  const history = useHistory()
  const authentication = useContext(AuthContext)
  const { state, actions } = useStateMachine({ updateConsultant })

  const updatingConsultant = async(data: Consultant) => {
    const updatedConsultant = generateConsultantFromForm({...data, followedBeneficiaries: state.consultant.followedBeneficiaries})
    await saveConsultant(authentication?.uid, updatedConsultant)
    actions.updateConsultant(updatedConsultant)
    history.push('/')
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(consultantFields),
    shouldFocusError: false,
  })

  return (
    <>
      <form>
        <Grid container justifyContent='center' spacing={2}>
          <InputField name='organism' control={control} error={errors} label={content.organism} defaultValue={state.consultant.organism} />
          <InputField name='function' error={errors} control={control} label={content.function} defaultValue={state.consultant.function} />
        </Grid>
      </form>
      <Grid container justifyContent='flex-start' spacing={2}>
        <Grid item><DefaultButton onClick={() => previousStep()} label={content.buttonPrevious} variant='outlined' /></Grid>
        <Grid item><DefaultButton onClick={handleSubmit(updatingConsultant)} label={content.buttonNext} /></Grid>
      </Grid>
    </>
  )
}

const content = {
  buttonPrevious: 'Précédent',
  buttonNext: 'Valider',
  organism: 'Organisme/Entreprise',
  function: 'Poste occupé',
}
