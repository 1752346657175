import React, {FC} from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  style?: any
  className?: any
}

export const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  
  return (
    <div
    className={other.className}
    role="tabpanel"
    hidden={value !== index}
    id={`tab-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    >
      {children}
    </div>
  )
}
