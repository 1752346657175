import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import { StepLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { COLORS } from '../utils/constants'

interface SteppersProps {
  steps: string[]
  currentStep: number
}

export const Steppers: React.FC<SteppersProps> = ({ steps, currentStep }: SteppersProps) => {
  const classes = useStyles()
  return (
    <Stepper className={classes.root} activeStep={currentStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label + index}>
          <StepLabel
            StepIconProps={{
              classes: { root: classes.icon, active: classes.activeIcon, completed: classes.completedIcon },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
  icon: {
    '&$activeIcon': {
      color: COLORS.cyanForte,
    },
    '&$completedIcon': {
      color: COLORS.cyanForte,
    },
  },
  activeIcon: {},
  completedIcon: {},
})
