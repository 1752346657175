import React, { FC, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { Loading } from '../atoms/Loading'
import { useStateMachine } from 'little-state-machine'
import { useQueryParameters } from '../utils/hooks'
import { updatePermissionForUidConsultant } from '../stores/store'
import { InvitationPayload } from '../utils/type'

interface PrivateRouteProps extends RouteProps {}

export const PrivateRoute: FC<PrivateRouteProps> = ({ ...rest }) => {
  const { state, actions } = useStateMachine({updatePermissionForUidConsultant})
  const queryParameters = useQueryParameters()

  useEffect(() => {
    const params: string | null = queryParameters?.get('p')
    if (params) {
      const invitationPayload = JSON.parse(atob(params!)) as InvitationPayload
      if (invitationPayload.uid) {
        actions.updatePermissionForUidConsultant(invitationPayload)
      }
    }
  }, [queryParameters])

  if (state.isAuthenticated === null) return <Loading />

  if (!state.isAuthenticated) {
    return <Redirect to='/connexion' push />
  } else {
    return <Route {...rest} />
  }
}
