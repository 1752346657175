import { Consultant } from '../utils/type'
import { db } from '../utils/firebase'
import firebase from 'firebase/app'

export const searchConsultant = async(uid: string | undefined) =>
  await db
    .collection('consultant')
    .doc(uid)
    .get()
    .then((payload) => payload.data() as Consultant)
    .catch((error) => {
      console.log('Error searchConsultant ======>', error)
      return undefined
    })

export const saveConsultant = async(uid: string | undefined, consultant: Consultant) => {
  await db
    .collection('consultant')
    .doc(uid)
    .set(consultant)
    .then((payload) => payload)
    .catch((error) => console.log('Error save consultant ======>', error))
}

export const deleteConsultant = (uid: string | undefined) => {
  db
    .collection('consultant')
    .doc(uid)
    .delete()
    .then((payload) => payload)
    .catch((error) => console.log('Error delete consultant ======>', error))
}

export const generateConsultantFromForm = (formData: any): Consultant => ({
  followedBeneficiaries: formData.followedBeneficiaries,
  organism: formData.organism,
  function: formData.function,
})

export const updateAccess = async(
  uid: string | undefined,
  followedBeneficiaries: Consultant['followedBeneficiaries'],
) => {
  return await db
    .collection('consultant')
    .doc(uid)
    .update({
      followedBeneficiaries: firebase.firestore.FieldValue.arrayUnion(...followedBeneficiaries),
    })
    .then((payload) => payload)
    .catch((error) => console.log('Error updateAccess ======>', error))
}
