import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { Carousel } from '../molecules/Carousel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'

interface HalfSplitTemplateProps {
  main: JSX.Element | null
}

export const HalfSplitTemplate: FC<HalfSplitTemplateProps> = ({ main }: HalfSplitTemplateProps) => {
  const classes = useStyles()
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <Grid container className={classes.root}>
      {tablet && (
        <Grid item xs={12}>
          <Carousel />
        </Grid>
      )}
      <Grid item xs={tablet ? 12 : 6}>
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent={tablet ? 'start' : 'center'}
          height={tablet ? '70vh' : '100vh'}
        >
          {main}
        </Box>
      </Grid>
      {!tablet && (
        <Grid item xs={6}>
          <Carousel />
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    height: '100vh',
  },
})
