import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'
import { DefaultButton } from '../atoms/DefaultButton'
import { Logo } from '../atoms/Logo'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'

const NotFound: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  return (
    <Container maxWidth='sm' className={classes.root}>
      <Logo styleProps={mobile ? classes.fixedWidthMobile : classes.fixedWidth} />
      <Typography variant='h4' align='center' className={classes.typoTitle}>
        {content.title}
      </Typography>
      <Typography align='center' className={classes.typoSubtitle}>
        {content.subject}
      </Typography>
      <Grid container justifyContent='space-around' alignItems='center'>
        <DefaultButton onClick={() => history.push('/')} label={content.button} />
      </Grid>
      <Box component='div' className={classes.mention} display='flex' flexDirection={mobile ? 'column' : 'row'} >
        <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
        <Typography variant='caption'> Tous droits réservés</Typography>
      </Box>
    </Container>
  )
}

export default NotFound

const content = {
  title: 'Erreur 404',
  subject: 'Nous sommes désolés, le lien sur lequel vous avez cliqué renvoie vers une page qui n’existe pas ou plus.',
  button: 'Retour',
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  fixedWidth: {
    width: '15rem',
  },
  fixedWidthMobile: {
    width: '10rem',
  },
  typoTitle: {
    marginTop: '3rem',
    marginBottom: '5rem',
    fontWeight: 'bold',
  },
  typoSubtitle: {
    paddingBottom: '3rem',
  },
  mention: {
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
})
