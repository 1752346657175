import React, { FC, useEffect, useState } from 'react'
import { PortalTemplate } from '../templates/PortalTemplate'
import PeopleIcon from '@material-ui/icons/People'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useStateMachine } from 'little-state-machine'
import { BeneficiarySend } from '../organisms/BeneficiarySend'
import { BeneficiaryList } from '../organisms/BeneficiaryList'
import { ActionList } from '../organisms/ActionList'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { cleanGlobalState, cleanState, updateIsAuthenticated } from '../stores/store'
import { EditRegistrationSteps } from '../organisms/EditRegistrationSteps'
import { AccountCircle, ExitToApp } from '@material-ui/icons'
import { ProfileInformation } from '../organisms/ProfileInformation'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import { Box, Grid, Typography } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { auth } from '../utils/firebase'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import { Logo } from '../atoms/Logo'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const PortalConsultant: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [ open, setOpen ] = useState(true)
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const smallTablet = useMediaQuery(BREAKPOINTS.smallTablet)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const { state, actions } = useStateMachine({ cleanState, cleanGlobalState, updateIsAuthenticated })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (smallTablet) {
        setOpen(false)
      }
    }, 3000)
    return () => clearTimeout(timer)
  }, [smallTablet])

  const toggleComponent = () => {
    switch (location.pathname) {
      case '/envoie':
        return <BeneficiarySend />
      case '/liste-des-actions':
        return <ActionList />
      case '/modifier-mon-compte':
        return <EditRegistrationSteps />
      case '/liste-des-beneficiaires':
        return <BeneficiaryList />
      default:
        return <ProfileInformation />
    }
  }

  const handleAccount = () => {
    actions.cleanState('')
    history.push('/')
    mobile && setOpen(false)
  }

  const handlePortal = () => {
    actions.cleanState('')
    history.push('/liste-des-beneficiaires')
    mobile && setOpen(false)
  }

  const signOutUser = () => {
    auth
      .signOut()
      .then(() => actions.cleanGlobalState(''))
      .then(() => {
        actions.updateIsAuthenticated(false)
        history.push('/connexion')
      })
      .catch((error) => console.log(error))
  }

  const pathColorHome = location.pathname === '/' ? classes.buttonFocus : classes.button
  const pathColorBeneficiaries = location.pathname === '/liste-des-beneficiaires' ? classes.buttonFocus : classes.button

  const onStyle = () => {
    if (smallTablet) {
      return open && [ classes.drawerPaper, classes.drawerSmartphones ]
    } else if (tablet) {
      return open && [ classes.drawerPaper, classes.drawerTablet ]
    } else {
      return open && [ classes.drawerPaper, classes.drawerLaptop ]
    }
  }

  return (
    <PortalTemplate
      smartScreen={open && smallTablet}
      display={open && mobile ? 'none' : 'block'}
      sideMenu={
        <>
          <Paper elevation={6} className={classes.sideMenu}>
            <Drawer
              variant='permanent'
              classes={{ paper: clsx(onStyle(), !open && classes.drawerPaperClose) }}
              open={open}
            >
              <Box component='div' className={classes.toolbarIcon}>
                {open ? (
                  <IconButton onClick={() => setOpen(false)}>
                    <ChevronLeftIcon fontSize='large' style={{ color: COLORS.cyanForte }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setOpen(true)}>
                    <MenuIcon style={{ color: COLORS.cyanForte }} />
                  </IconButton>
                )}
              </Box>
              <List>
                {open && (
                  <Box component='div' display='flex' flexDirection='column' className={classes.containerItem}>
                    <Logo styleProps={classes.logoWidth} />
                    <div className={classes.titleContainer}>
                      <Typography variant='h5' className={classes.title}>
                        {state.profile.firstname.charAt(0).toUpperCase() + state.profile.firstname.slice(1)}
                      </Typography>
                      <Typography variant='h5' className={classes.title}>
                        {state.profile.lastname.toUpperCase()}
                      </Typography>
                    </div>
                  </Box>
                )}
                <ListItem button onClick={handleAccount} className={pathColorHome}>
                  <ListItemIcon className={pathColorHome}>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary={content.account} />
                </ListItem>
                <ListItem button onClick={handlePortal} className={pathColorBeneficiaries}>
                  <ListItemIcon className={pathColorBeneficiaries}>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary={content.beneficiary} />
                </ListItem>
                <ListItem button onClick={signOutUser} className={classes.button} style={open ? { marginTop: '19rem' } : { marginTop: '34rem' }}>
                  <ListItemIcon className={classes.button}>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary='Déconnexion' />
                </ListItem>
              </List>
            </Drawer>
          </Paper>
        </>
      }
      main={
        <Grid container className={open ? classes.paddingOpen : classes.paddingNotOpen} style={{ height: '100vh' }}>
          {location.pathname === '/liste-des-actions' ? (toggleComponent()) : (
            <>
              <Paper className={clsx(classes.paperMain, mobile && classes.fixedPadding)}>
                {toggleComponent()}
              </Paper>
            </>
          )}
        </Grid>
      }
    />
  )
}

export default PortalConsultant

const content = {
  beneficiary: 'Mes bénéficiaires',
  account: 'Mon compte',
}

const useStyles = makeStyles((theme) => (
  {
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginBottom: '2rem',
    },
    title: {
      fontWeight: 'bold',
      marginLeft: '5px',
    },
    logoWidth: {
      width: '10rem',
    },
    sideMenu: {
      height: '100vh',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    containerItem: {
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      zIndex: 2,
    },
    drawerPaper: {
      backgroundColor: COLORS.white,
      position: 'relative',
      whiteSpace: 'nowrap',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      width: '3rem',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerSmartphones: {
      width: 'auto',
    },
    drawerTablet: {
      width: '13rem',
    },
    drawerLaptop: {
      width: '22rem',
    },
    button: {
      textTransform: 'none',
      fontSize: 'medium',
      paddingLeft: '6px',
    },
    buttonFocus: {
      textTransform: 'none',
      paddingLeft: '6px',
      color: COLORS.cyanForte,
      fontSize: 'medium',
    },
    paperMain: {
      padding: '1rem 2.3rem 1rem 2.3rem',
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
    fixedPadding: {
      padding: '1rem',
    },
    paddingOpen: {
      padding: '1.5rem 1.5rem 3rem 1.5rem',
    },
    paddingNotOpen: {
      padding: '1.5rem 1.5rem 3rem 4.5rem',
    },
  }
))
