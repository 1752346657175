import React, { FC } from 'react'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link, useParams } from 'react-router-dom'
import { PrivacyPoliciesUserType } from '../utils/type'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const Terms: FC = () => {
  const classes = useStyles()
  const { type } = useParams<{ [ type: string ]: PrivacyPoliciesUserType }>()
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <div className={clsx(classes.containerTerms, tablet ? classes.fixedWidthMobile : classes.fixedWidth)}>
      <Typography align='center' className={classes.typo} >
        {content.terms}
        <Link className={classes.link} to='/conditions-generales-utilisation'>
          {content.linkCGU}
        </Link>
        {content.termsNext}
        <Link className={classes.link} to={`/${type}/politique-de-confidentialite`}>
          {content.linkPrivacy}
        </Link>
      </Typography>
    </div>
  )
}

const content = {
  terms: 'En cliquant sur Inscription ci-dessous, je reconnais et j\'accepte avoir pris connaissance des ',
  linkCGU: 'Conditions Générales d\'Utilisation ',
  termsNext: 'du site ainsi que sa ',
  linkPrivacy: 'Politique de Confidentialité ',
}

const useStyles = makeStyles({
  containerTerms: {
    minWidth: '18rem',
    marginTop: '2.3rem',
    marginBottom: '1.3rem',
    display: 'flex',
  },
  fixedWidth: {
    width: '40vw',
  },
  fixedWidthMobile: {
    width: '80vw',
  },
  link: {
    color: COLORS.black,
    fontWeight: 'bold',
    textDecorationLine: 'none',
  },
  typo: {
    fontSize: 12,
  },
})
