import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'

export const ArrestInformation: FC = () => {
  const classes = useStyles()
  const { state } = useStateMachine()

  const formatDate = state.beneficiary.dateWorkArrest ? new Date(state.beneficiary.dateWorkArrest).toLocaleDateString() : '...'

  return (
    <>
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.typeWorkArrest}</span>
        {state.beneficiary.typeWorkArrest}
      </Typography>
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.dateWorkArrest}</span>
        {formatDate}
      </Typography>
    </>
  )
}

const content = {
  typeWorkArrest: 'Type d\'arrêt de travail: ',
  dateWorkArrest: 'Date du début d\'arrêt: ',
}

const useStyles = makeStyles(() => (
  {
    aligneCenter: {
      textAlign: 'center',
    },
    typo: {
      margin: '0.5rem',
    },
    scope: {
      fontWeight: 'bold',
    },
  }
))
