import React, { FC, useContext } from 'react'
import { InputField } from '../molecules/InputField'
import { useForm } from 'react-hook-form'
import { generateProfileFromForm, saveProfile } from '../stores/fbProfile'
import { Profile } from '../utils/type'
import { FormTemplate } from '../templates/FormTemplate'
import { Grid } from '@material-ui/core'
import { DefaultButton } from '../atoms/DefaultButton'
import { DateField } from '../molecules/DateField'
import { profileBeneficiarySchema, profileSchema } from '../utils/validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { useStateMachine } from 'little-state-machine'
import { updateProfile } from '../stores/store'
import { AuthContext } from '../utils/hooks'

interface ProfileFormProps {
  nextStep: () => void
}

export const ProfileForm: FC<ProfileFormProps> = ({ nextStep }: ProfileFormProps) => {
  const authentication = useContext(AuthContext)
  const { state, actions } = useStateMachine({ updateProfile })

  const updatingProfile = async(data: Profile) => {
    const updatedProfile = generateProfileFromForm(data, state.profile.isConsultant)
    await saveProfile(authentication?.uid, updatedProfile)
    actions.updateProfile(updatedProfile)
    nextStep()
  }

  const validationSchema = state.profile.isConsultant ? profileSchema : profileBeneficiarySchema

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
  })

  return (
    <FormTemplate
      main={
        <form>
          <Grid container justifyContent='center' spacing={2}>
            <InputField name='lastname' error={errors} control={control} label={content.lastname} />
            <InputField name='firstname' error={errors} control={control} label={content.firstname} />
            {!state.profile.isConsultant && (
              <DateField name='birthday' error={errors} control={control} label={content.birthday} />
            )}
            <InputField name='street' error={errors} control={control} label={content.street} />
            <InputField name='postcode' error={errors} control={control} label={content.postcode} />
            <InputField name='city' error={errors} control={control} label={content.city} />
            <InputField name='phone' error={errors} control={control} label={content.phone} />
          </Grid>
        </form>
      }
      previous={<DefaultButton disabled onClick={() => undefined} label={content.buttonPrevious} variant='outlined'/>}
      next={<DefaultButton onClick={handleSubmit(updatingProfile)} label={content.buttonNext}/>}
    />
  )
}

const content = {
  buttonPrevious: 'Annuler',
  buttonNext: 'Suivant',
  lastname: 'Nom',
  street: 'Adresse',
  city: 'Ville',
  firstname: 'Prénom',
  postcode: 'Code postal',
  birthday: 'Date de naissance',
  phone: 'Téléphone (facultatif)',
}
