import React, { ChangeEvent, FC, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Beneficiary } from '../utils/type'
import { DefaultButton } from '../atoms/DefaultButton'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../utils/hooks'
import { useStateMachine } from 'little-state-machine'
import { updateBeneficiary } from '../stores/store'
import { generateBeneficiaryFromForm, saveBeneficiary } from '../stores/fbBeneficiary'
import { yupResolver } from '@hookform/resolvers/yup'
import { beneficiaryFields } from '../utils/validation'
import { Grid, makeStyles } from '@material-ui/core'
import { SelectField } from '../molecules/SelectField'
import {
  BENEFICIARY_BOE_TYPE,
  BENEFICIARY_CONTRACT_TYPE,
  BENEFICIARY_LANGUAGE_LEVEL,
  BENEFICIARY_LEVEL_SCHOOL,
  BENEFICIARY_STATUS,
  BENEFICIARY_TYPE_WORK_ARREST,
  BREAKPOINTS,
} from '../utils/constants'
import { InputField } from '../molecules/InputField'
import { ToggleSwitch } from '../molecules/ToggleSwitch'
import { RadioButtonGroup } from '../molecules/RadioButtonGroup'
import { DateField } from '../molecules/DateField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

interface EditBeneficiaryFormProps {
  previousStep: () => void
}

export const EditBeneficiaryForm: FC<EditBeneficiaryFormProps> = ({ previousStep }: EditBeneficiaryFormProps) => {
  const classes = useStyles()
  const history = useHistory()
  const authentication = useContext(AuthContext)
  const { state, actions } = useStateMachine({ updateBeneficiary })
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  const [ status, setStatus ] = useState({
    isBOE: state.beneficiary.isBOE,
    isWorkArrest: state.beneficiary.isWorkArrest,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus({ ...status, [ event.target.name ]: event.target.checked })
  }

  const updatingBeneficiary = async(data: Beneficiary) => {
    const updatedBeneficiary = generateBeneficiaryFromForm(data, status.isBOE, status.isWorkArrest)
    const dateWorkArrest = updatedBeneficiary.dateWorkArrest && updatedBeneficiary.dateWorkArrest.toString()
    await saveBeneficiary(authentication?.uid, updatedBeneficiary, dateWorkArrest)
    actions.updateBeneficiary(updatedBeneficiary)
    history.push('/')
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(beneficiaryFields),
    shouldFocusError: false,
  })

  return (
    <>
      <form>
        <Grid container justifyContent='center' spacing={2}>
          <SelectField
            name='status'
            error={errors}
            control={control}
            label={content.status}
            options={BENEFICIARY_STATUS}
            defaultValue={state.beneficiary.status}
          />
          <SelectField
            name='contractType'
            error={errors}
            control={control}
            label={content.contractType}
            options={BENEFICIARY_CONTRACT_TYPE}
            defaultValue={state.beneficiary.contractType} />
          <InputField
            name='postOccupied'
            error={errors}
            control={control}
            label={content.postOccupied}
            defaultValue={state.beneficiary.postOccupied}
          />
          <InputField
            name='codeAPE'
            error={errors}
            control={control}
            label={content.codeAPE}
            placeholder={content.codeAPEPlaceholder}
            defaultValue={state.beneficiary.codeAPE}
          />
          <InputField
            name='formation'
            error={errors}
            control={control}
            label={content.formation}

            defaultValue={state.beneficiary.formation}
          />
          <SelectField
            name='levelSchool'
            error={errors}
            control={control}
            label={content.levelSchool}
            options={BENEFICIARY_LEVEL_SCHOOL}
            defaultValue={state.beneficiary.levelSchool}
          />
          <InputField
            name='language'
            error={errors}
            control={control}
            label={content.language}
            defaultValue={state.beneficiary.language}
          />
          <SelectField
            name='languageLevel'
            error={errors}
            control={control}
            label={content.languageLevel}
            options={BENEFICIARY_LANGUAGE_LEVEL}
            defaultValue={state.beneficiary.languageLevel}
          />
        </Grid>
      </form>
      <form className={clsx(classes.form, tablet ? classes.widthContentTablet : classes.widthContent)}>
        <Grid
          container
          className={classes.gridContent}
          direction={mobile ? 'column' : 'row'}
          justifyContent='space-between'
        >
          <ToggleSwitch
            name='isBOE'
            value={status.isBOE}
            onChange={handleChange}
            title={content.BOETitle}
            subtitle={content.BOESubtitle}
          />
        </Grid>
        {status.isBOE && (
          <Grid container justifyContent='space-between' className={classes.gridContent}>
            <RadioButtonGroup
              name='statusBOE'
              control={control}
              label={content.titleRadio}
              choices={BENEFICIARY_BOE_TYPE}
              defaultValue={state.beneficiary.isBOE ? state.beneficiary.statusBOE : ''}
            />
          </Grid>
        )}
        <Grid
          container
          className={classes.gridContent}
          direction={mobile ? 'column' : 'row'}
          justifyContent='space-between'
        >
          <ToggleSwitch
            name='isWorkArrest'
            onChange={handleChange}
            value={status.isWorkArrest}
            title={content.switchArrest}
          />
        </Grid>
        {status.isWorkArrest && (
          <Grid container justifyContent='center' spacing={2}>
            <DateField
              name='dateWorkArrest'
              error={errors}
              control={control}
              label={content.dateWorkArrest}
              defaultValue={state.beneficiary.dateWorkArrest}
              styleProps={tablet ? classes.inputMobile : classes.input}
            />
            <SelectField
              name='typeWorkArrest'
              error={errors}
              control={control}
              label={content.typeWorkArrest}
              options={BENEFICIARY_TYPE_WORK_ARREST}
              defaultValue={state.beneficiary.typeWorkArrest}
              styleProps={tablet ? classes.inputMobile : classes.input}
            />
          </Grid>
        )}
      </form>
      <Grid container justifyContent='flex-start' spacing={2}>
        <Grid item><DefaultButton onClick={() => previousStep()} label={content.buttonPrevious} variant='outlined' /></Grid>
        <Grid item><DefaultButton onClick={handleSubmit(updatingBeneficiary)} label={content.buttonNext} /></Grid>
      </Grid>
    </>
  )
}
const content = {
  buttonPrevious: 'Précédent',
  buttonNext: 'Suivant',
  status: 'Statut',
  postOccupied: 'Poste occupé',
  formation: 'Formation',
  language: 'Langue',
  contractType: 'Type de contrat',
  codeAPE: 'Code APE',
  codeAPEPlaceholder: 'exemple: 1234A',
  levelSchool: 'Niveau de formation',
  languageLevel: 'Niveau de langue',
  BOETitle: 'Êtes-vous BOE ?',
  BOESubtitle: '(bénéficiaire de l\'obligation d\'emploi)',
  titleRadio: 'Modifier votre situation',
  typeWorkArrest: 'Type d\'arrêt de travail',
  dateWorkArrest: 'Date du début d\'arrêt',
  switchArrest: 'Êtes-vous en arrêt de travail ?',
}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  widthContent: {
    width: '65.5vw',
  },
  widthContentTablet: {
    width: '70vw',
  },
  gridContent: {
    paddingTop: '1.5rem',
  },
  input: {
    width: '31vw',
  },
  inputMobile: {
    width: '70vw',
  },
})
