import React, { FC } from 'react'
import { Control, FieldErrors } from 'react-hook-form'
import { BENEFICIARY_BOE_TYPE, BENEFICIARY_TYPE_WORK_ARREST, BREAKPOINTS } from '../utils/constants'
import { SelectField } from '../molecules/SelectField'
import { Grid, makeStyles } from '@material-ui/core'
import { ToggleSwitch } from '../molecules/ToggleSwitch'
import { RadioButtonGroup } from '../molecules/RadioButtonGroup'
import { DateField } from '../molecules/DateField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

interface StageTwoBeneficiaryFormProps {
  control: Control<Record<string, any>> | undefined
  error: FieldErrors
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isBOE: boolean
  isWorkArrest: boolean
}

export const BeneficiaryHealthForm: FC<StageTwoBeneficiaryFormProps> = ({
  control,
  error,
  onChange,
  isBOE,
  isWorkArrest,
}: StageTwoBeneficiaryFormProps) => {
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <form className={clsx(classes.form, tablet ? classes.widthContentTablet : classes.widthContent)}>
      <Grid
        container
        className={classes.gridContent}
        direction={mobile ? 'column' : 'row'}
        justifyContent='space-between'
      >
        <ToggleSwitch
          name='isBOE'
          value={isBOE}
          onChange={onChange}
          title={content.BOETitle}
          subtitle={content.BOESubtitle}
        />
      </Grid>
      {isBOE && (
        <Grid container justifyContent='flex-start' className={classes.gridContent}>
          <RadioButtonGroup
            name='statusBOE'
            control={control}
            label={content.titleRadio}
            choices={BENEFICIARY_BOE_TYPE}
          />
        </Grid>
      )}
      <Grid
        container
        className={classes.gridContent}
        direction={mobile ? 'column' : 'row'}
        justifyContent='space-between'
      >
        <ToggleSwitch
          name='isWorkArrest'
          onChange={onChange}
          value={isWorkArrest}
          title={content.switchArrest}
        />
      </Grid>
      {isWorkArrest && (
        <Grid container justifyContent='center' spacing={2}>
          <DateField
            name='dateWorkArrest'
            error={error}
            control={control}
            label={content.dateWorkArrest}
            styleProps={tablet ? classes.inputMobile : classes.input}
          />
          <SelectField
            name='typeWorkArrest'
            error={error}
            control={control}
            label={content.typeWorkArrest}
            options={BENEFICIARY_TYPE_WORK_ARREST}
            styleProps={tablet ? classes.inputMobile : classes.input}
          />
        </Grid>
      )}
    </form>
  )
}

const content = {
  BOETitle: 'Êtes-vous BOE ?',
  BOESubtitle: '(bénéficiaire de l\'obligation d\'emploi)',
  titleRadio: 'Sélectionnez votre situation',
  typeWorkArrest: 'Type d\'arrêt de travail',
  dateWorkArrest: 'Date du début d\'arrêt',
  switchArrest: 'Êtes-vous en arrêt de travail ?',
}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  widthContent: {
    width: '65.5vw',
  },
  widthContentTablet: {
    width: '70vw',
  },
  gridContent: {
    paddingTop: '1.5rem',
  },
  input: {
    width: '31vw',
  },
  inputMobile: {
    width: '70vw',
  },
})
