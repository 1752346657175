import React, { FC, useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { PortalTemplate } from '../templates/PortalTemplate'
import { useStateMachine } from 'little-state-machine'
import { useHistory } from 'react-router-dom'
import { EditRegistrationSteps } from '../organisms/EditRegistrationSteps'
import { ProfileInformation } from '../organisms/ProfileInformation'
import { ActionList } from '../organisms/ActionList'
import { cleanGlobalState, cleanState, updateIsAuthenticated, updateSelectedBeneficiary } from '../stores/store'
import { AuthContext } from '../utils/hooks'
import { BeneficiariesRows } from '../utils/type'
import { loadBeneficiaries } from '../stores/fbBeneficiary'
import { auth } from '../utils/firebase'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import { useLocation } from 'react-router'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import { Box, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import { AccountCircle, Event, ExitToApp } from '@material-ui/icons'
import List from '@material-ui/core/List'
import { Logo } from '../atoms/Logo'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const PortalBeneficiary: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [ open, setOpen ] = useState(true)
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const smallTablet = useMediaQuery(BREAKPOINTS.smallTablet)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const { state, actions } = useStateMachine({ cleanState, cleanGlobalState, updateIsAuthenticated, updateSelectedBeneficiary })
  const authentication = useContext(AuthContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (smallTablet) {
        setOpen(false)
      }
    }, 3000)
    return () => clearTimeout(timer)
  }, [smallTablet])

  useEffect(() => {
    if (state.permissionForUidConsultant.uid.length > 0)
      history.push('/acces')
  }, [ state.permissionForUidConsultant.uid ])

  useEffect(() => {
    if (authentication?.uid) {
      loadBeneficiaries([ authentication.uid ]).then(
        (result) => {
          if (!!result && !!result.length) {
            const selectedBeneficiary: BeneficiariesRows = {} as BeneficiariesRows
            const date = result![ 0 ].profile?.birthday ? new Date(result![ 0 ].profile.birthday).toLocaleDateString() : ''
            selectedBeneficiary.id = result![ 0 ].id
            selectedBeneficiary.firstname = result![ 0 ].profile?.firstname!
            selectedBeneficiary.lastname = result![ 0 ].profile?.lastname!
            selectedBeneficiary.birthday = date
            selectedBeneficiary.isBOE = result![ 0 ].beneficiary?.isBOE ? 'BOETH' : 'Non BOETH'
            selectedBeneficiary.isWorkArrest = result![ 0 ].beneficiary?.isWorkArrest ? 'Oui' : 'Non'
            actions.updateSelectedBeneficiary(selectedBeneficiary)
          }
        },
      )
    }
  }, [ authentication ])

  const toggleComponent = () => {
    switch (location.pathname) {
      case '/modifier-mon-compte':
        return <EditRegistrationSteps />
      case '/mon-parcours':
        return <ActionList />
      default:
        return <ProfileInformation />
    }
  }

  const handleAccount = () => {
    actions.cleanState('')
    history.push('/')
    mobile && setOpen(false)
  }

  const handlePortal = () => {
    actions.cleanState('')
    history.push('/mon-parcours')
    mobile && setOpen(false)
  }

  const signOutUser = () => {
    auth.signOut()
      .then(() => actions.cleanGlobalState(''))
      .then(() => {
        actions.updateIsAuthenticated(false)
        history.push('/connexion')
      })
      .catch((error) => console.log(error))
  }

  const pathColorBeneficiaries = location.pathname === '/mon-parcours' ? classes.buttonFocus : classes.button
  const pathColorHome = location.pathname === '/' ? classes.buttonFocus : classes.button

  const onStyle = () => {
    if (smallTablet) {
      return open && [ classes.drawerPaper, classes.drawerSmartphones ]
    } else if (tablet) {
      return open && [ classes.drawerPaper, classes.drawerTablet ]
    } else {
      return open && [ classes.drawerPaper, classes.drawerLaptop ]
    }
  }

  return (
    <PortalTemplate
      display={open && mobile ? 'none' : 'block'}
      smartScreen={open && smallTablet}
      sideMenu={
        <>
          <Paper elevation={6} className={classes.sideMenu}>
            <Drawer
              variant='permanent'
              classes={{ paper: clsx(onStyle(), !open && classes.drawerPaperClose) }}
              open={open}
            >
              <Box component='div' className={classes.toolbarIcon}>
                {open ? (
                  <IconButton onClick={() => setOpen(false)}>
                    <ChevronLeftIcon fontSize='large' style={{ color: COLORS.cyanForte }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setOpen(true)}>
                    <MenuIcon style={{ color: COLORS.cyanForte }} />
                  </IconButton>
                )}
              </Box>
              <List className={classes.items}>
                {open && (
                  <Box component='div' display='flex' flexDirection='column' className={classes.containerItem}>
                    <Logo styleProps={classes.logoWidth} />
                    <Typography variant='h5' className={classes.title}>
                      {state.profile.firstname.charAt(0).toUpperCase() + state.profile.firstname.slice(1)} {state.profile.lastname.toUpperCase()}
                    </Typography>
                  </Box>
                )}
                <ListItem button onClick={handleAccount} className={pathColorHome}>
                  <ListItemIcon className={pathColorHome}>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary={content.account} />
                </ListItem>
                <ListItem button onClick={handlePortal} className={pathColorBeneficiaries}>
                  <ListItemIcon className={pathColorBeneficiaries}>
                    <Event />
                  </ListItemIcon>
                  <ListItemText primary={content.background} />
                </ListItem>
                <ListItem button onClick={signOutUser} className={classes.button} style={open ? { marginTop: '19rem' } : { marginTop: '34rem' }}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary='Déconnexion' />
                </ListItem>
              </List>
            </Drawer>
          </Paper>
        </>
      }
      main={
        <Grid container justifyContent='center' alignItems='center' className={open ? classes.paddingOpen : classes.paddingNotOpen} style={{ height: '100vh' }}>
          <Paper className={clsx(classes.paperMain, mobile && classes.fixedPadding)}>
            {toggleComponent()}
          </Paper>
        </Grid>
      }
    />
  )
}

export default PortalBeneficiary

const content = {
  account: 'Mon compte',
  background: 'Mon parcours',
}

const useStyles = makeStyles((theme) => (
  {
    title: {
      fontWeight: 'bold',
      marginBottom: '2rem',
    },
    logoWidth: {
      width: '10rem',
    },
    sideMenu: {
      height: '100vh',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    items: {
      marginLeft: theme.spacing(0.5),
    },
    containerItem: {
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      zIndex: 2,
    },
    drawerPaper: {
      backgroundColor: COLORS.white,
      position: 'relative',
      whiteSpace: 'nowrap',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '4.1rem',
    },
    drawerSmartphones: {
      width: 'auto',
    },
    drawerTablet: {
      width: '15rem',
    },
    drawerLaptop: {
      width: '22rem',
    },
    button: {
      textTransform: 'none',
      fontSize: 'medium',
    },
    buttonFocus: {
      textTransform: 'none',
      color: COLORS.cyanForte,
      fontSize: 'medium',
    },
    paddingOpen: {
      padding: '1.5rem 1.5rem 3rem 1.5rem',
    },
    paddingNotOpen: {
      padding: '1.5rem 1.5rem 3rem 4.5rem',
    },
    paperMain: {
      padding: '1rem 2.3rem 1rem 2.3rem',
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
    fixedPadding: {
      padding: '1rem',
    },
  }
))
