import { object, ref, string } from 'yup'

const content = {
  requiredMsg: 'Ce champ doit être rempli.',
  invalidEmail: 'Adresse email invalide.',
  invalidDescriptionLength: 'La description doit faire moins de 800 caractères.',
  minPassword: 'Minimum 6 caractères requis.',
  confirm: 'Les mots de passe doivent correspondre.',
  lengthPhone: 'Le numéro de téléphone doit contenir 10 chiffres.',
  lengthPostcode: 'Le code postal doit contenir 5 chiffres.',
  digitPhone: 'Le numéro de téléphone doit être exclusivement constitué de chiffres.',
}

const emailFields = {
  email: string().email(content.invalidEmail).required(content.requiredMsg).trim(),
}

const passwordFields = {
  password: string().min(6, content.minPassword).required(content.requiredMsg).trim(),
}

const confirmPasswordFields = {
  confirmPassword: string()
    .required(content.requiredMsg)
    .oneOf([ref('password'), null], content.confirm)
    .trim(),
}

export const forgotSchema = object().shape({ ...emailFields })
export const resetSchema = object().shape({ ...passwordFields })
export const signInSchema = object().shape({ ...emailFields, ...passwordFields })
export const signUpSchema = object().shape({ ...emailFields, ...passwordFields, ...confirmPasswordFields })

const profileFields = {
  firstname: string().required(content.requiredMsg),
  lastname: string().required(content.requiredMsg),
  street: string().required(content.requiredMsg),
  postcode: string().min(5, content.lengthPostcode).required(content.requiredMsg),
  city: string().required(content.requiredMsg),
  phone: string().nullable(),
}

const birthdayFields = {
  birthday: string().required(content.requiredMsg),
}

export const profileSchema = object().shape({ ...profileFields })
export const profileBeneficiarySchema = object().shape({ ...profileFields, ...birthdayFields })

export const consultantFields = object().shape({
  organism: string().required(content.requiredMsg),
  function: string().required(content.requiredMsg),
})

export const beneficiaryFields = object().shape({
  status: string().required(content.requiredMsg),
  contractType: string().required(content.requiredMsg),
  postOccupied: string().required(content.requiredMsg),
  codeAPE: string().required(content.requiredMsg),
  formation: string().required(content.requiredMsg),
  levelSchool: string().required(content.requiredMsg),
  language: string().required(content.requiredMsg),
  languageLevel: string().required(content.requiredMsg),
})

export const actionFields = object().shape({
  status: string().required(content.requiredMsg),
  deviceType: string().required(content.requiredMsg),
  actionDescription: string().max(800, content.invalidDescriptionLength).nullable(),
})
