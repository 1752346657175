import React, { FC } from 'react'
import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { Control, Controller } from 'react-hook-form'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { makeStyles, withStyles } from '@material-ui/styles'
import { COLORS } from '../utils/constants'

interface RadioButtonGroupProps {
  control: Control<Record<string, any>> | undefined
  name: string
  choices: { key: string; label: string }[]
  label?: string
  defaultValue?: string
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({ control, choices, defaultValue, name, label }: RadioButtonGroupProps) => {
  const classes = useStyles()
  return (
    <Grid item>
      <FormLabel className={classes.formLabel}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <RadioGroup value={value || ''} onChange={(value) => onChange(value)}>
            {choices.map((choice) => (
              <FormControlLabel key={choice.key} value={choice.key} control={<CustomRadio />} label={choice.label} />
            ))}
          </RadioGroup>
        )}
      />
    </Grid>
  )
}

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: COLORS.cyanForte,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Radio {...props} />)

const useStyles = makeStyles({
  formLabel: {
    color: COLORS.black,
    fontSize: '0.8rem',
    fontWeight: 'bolder',
  },
})
