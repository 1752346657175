import React, { FC, useMemo } from 'react'
import { Grid, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import { ActionHistory } from '../utils/type'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab'
import { ArrowForward, DescriptionRounded, FlagRounded, FolderRounded, TrackChangesRounded } from '@material-ui/icons'
import { ALL_DEVICE } from '../utils/actionConstants'

interface TimelineActionProps {
  children?: React.ReactNode
  actionHistory: ActionHistory
  isLast: boolean
}

export const TimelineActionHistory: FC<TimelineActionProps> = ({ actionHistory, isLast }: TimelineActionProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const getDateStringFromTimestamp = (timestamp: any) => {
    return new Date((timestamp as any).seconds * 1000).toLocaleDateString()
  }

  const previousState = useMemo<string>(
    () => {
      return ALL_DEVICE.find(d => d.key === actionHistory.previousState)?.label as string
    }, [ ALL_DEVICE, actionHistory.previousState ],
  )
  const currentState = useMemo<string>(
    () => {
      return ALL_DEVICE.find(d => d.key === actionHistory.currentState)?.label as string
    }, [ ALL_DEVICE, actionHistory.currentState ],
  )

  const getBgColor = (action: Partial<ActionHistory>) => {
    switch (action.changeType) {
      case 'STATUS_CREATED':
        return '#CACACA'
      case 'STATUS_CHANGED':
        return '#F7B267'
      case 'DEVICE_CHANGED':
        return '#36558F'
      case 'DESCRIPTION_CHANGED':
        return '#FFD972'
      default:
        return theme.palette.grey[ 50 ]
    }
  }

  return (
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent style={{ padding: 0 }} />
      <TimelineSeparator>
        <TimelineDot style={{ alignSelf: 'center', color: '#FFFFFF', backgroundColor: getBgColor(actionHistory) }}>
          {actionHistory.changeType === 'STATUS_CREATED' && (
            <FlagRounded color='inherit' />
          )}
          {actionHistory.changeType === 'STATUS_CHANGED' && (
            <TrackChangesRounded color='inherit' />
          )}
          {actionHistory.changeType === 'DEVICE_CHANGED' && (
            <FolderRounded color='inherit' />
          )}
          {actionHistory.changeType === 'DESCRIPTION_CHANGED' && (
            <DescriptionRounded color='inherit' />
          )}
        </TimelineDot>
        {!isLast && (
          <TimelineConnector />
        )}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Grid container item justifyContent='space-between' alignItems='center'>
          <Grid item xs={6}>
            {actionHistory.changeType === 'STATUS_CREATED' && (
              <Typography variant='body1' color='textPrimary' component='div'>
                <strong>Création </strong> du dispositif
              </Typography>
            )}
            {actionHistory.changeType === 'STATUS_CHANGED' && (
              <Typography variant='body1' color='textPrimary' component='div'>
                <strong>Modification </strong> du statut
              </Typography>
            )}
            {actionHistory.changeType === 'DEVICE_CHANGED' && (
              <Typography variant='body1' color='textPrimary' component='div'>
                <strong>Modification </strong> du dispositif
              </Typography>
            )}
            {actionHistory.changeType === 'DESCRIPTION_CHANGED' && (
              <Typography variant='body1' color='textPrimary' component='div'>
                <strong>Modification </strong> de la description
              </Typography>
            )}
          </Grid>
          <Grid item container justifyContent='flex-end' xs={6}>
            <Typography variant='body2' color='textSecondary' component='div'>
              le {getDateStringFromTimestamp(actionHistory.updateDate)} par <strong>{actionHistory.author} <Tooltip title="organisme de l'intervenant"
                                                                                                                    style={{ cursor: 'help' }}><span>{actionHistory.organism ? `(${actionHistory.organism})` : ''}</span></Tooltip></strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: theme.spacing(1) }}>
          {actionHistory.changeType === 'STATUS_CHANGED' && (
            <Typography className={classes.actionStates} variant='body2' color='textPrimary' component='div'>
              {actionHistory.previousState}<ArrowForward fontSize='small' color='action' style={{ margin: '0 .5rem' }} />{actionHistory.currentState}
            </Typography>
          )}
          {actionHistory.changeType === 'DEVICE_CHANGED' && (
            <Typography className={classes.actionStates} variant='body2' color='textPrimary' component='div'>
              <Tooltip placement='top' title={previousState}>
                <p className={classes.tooltipedStates}>{previousState}</p>
              </Tooltip>
              <ArrowForward fontSize='small' color='action' style={{ margin: '0 .5rem' }} />
              <Tooltip placement='top' title={currentState}>
                <p className={classes.tooltipedStates}>{currentState}</p>
              </Tooltip>
            </Typography>
          )}
        </Grid>
      </TimelineContent>
    </TimelineItem>
  )
}

const useStyles = makeStyles({
  timelineItem: {
    borderRadius: '4px',
    padding: '0 10px',
    transition: 'all.2s ease-out',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  timelineContent: {
    flexBasis: '100%',
    marginBottom: '1rem',
  },
  actionStates: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipedStates: {
    cursor: 'default',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
})
