import { createStore, GlobalState } from 'little-state-machine'
import { Action, BeneficiariesRows, Beneficiary, BeneficiaryProfile, Consultant, InvitationPayload, Profile } from '../utils/type'
import { initBeneficiary, initConsultant, initInvitationPayload, initProfile, initSelectedAction, initSelectedBeneficiary } from './initStore'

createStore(
  {
    isAuthenticated: false,
    isLoadingUser: true,
    permissionForUidConsultant: initInvitationPayload,
    profile: initProfile,
    consultant: initConsultant,
    beneficiary: initBeneficiary,
    beneficiariesProfiles: [],
    selectedAction: initSelectedAction,
    selectedBeneficiary: initSelectedBeneficiary,
    actions: [],
  },
  {},
)

export const cleanState = (state: GlobalState, payload: string) => ({
  ...state,
  selectedAction: initSelectedAction,
  selectedBeneficiary: initSelectedBeneficiary,
  actions: [],
})

export const cleanGlobalState = (state: GlobalState, payload: string) => ({
  ...state,
  profile: initProfile,
  consultant: initConsultant,
  beneficiary: initBeneficiary,
  beneficiariesProfiles: [],
  permissionForUidConsultant: initInvitationPayload,
  selectedAction: initSelectedAction,
  selectedBeneficiary: initSelectedBeneficiary,
  actions: [],
})

export const updateIsAuthenticated = (state: GlobalState, payload: boolean) => ({
  ...state,
  isAuthenticated: payload,
})

export const updateIsLoadingUser = (state: GlobalState, payload: boolean) => ({
  ...state,
  isLoadingUser: payload,
})

export const updateIsConsultant = (state: GlobalState, payload: boolean) => ({
  ...state,
  profile: {
    ...state.profile,
    isConsultant: payload,
  },
})

export const updatePermissionForUidConsultant = (state: GlobalState, payload: InvitationPayload) => ({
  ...state,
  permissionForUidConsultant: payload,
})

export const updateProfile = (state: GlobalState, payload: Profile) => ({
  ...state,
  profile: {
    ...state.profile,
    ...payload,
  },
})

export const updateConsultant = (state: GlobalState, payload: Consultant) => ({
  ...state,
  consultant: {
    ...state.consultant,
    ...payload,
  },
})

export const updateBeneficiary = (state: GlobalState, payload: Beneficiary) => ({
  ...state,
  beneficiary: {
    ...state.beneficiary,
    ...payload,
  },
})

export const updateBeneficiariesProfiles = (state: GlobalState, payload: BeneficiaryProfile[]) => ({
  ...state,
  beneficiariesProfiles: payload,
})

export const updateSelectedBeneficiary = (state: GlobalState, payload: BeneficiariesRows) => ({
  ...state,
  selectedBeneficiary: {
    ...state.selectedBeneficiary,
    ...payload,
  },
})

export const updateSelectedAction = (state: GlobalState, payload: Action) => ({
  ...state,
  selectedAction: {
    ...state.selectedAction,
    ...payload,
  },
})

export const updateActions = (state: GlobalState, payload: Action[]) => ({
  ...state,
  actions: payload,
})
