import React, { FC } from 'react'
import { InputField } from '../molecules/InputField'
import { Control, FieldErrors } from 'react-hook-form'
import { BENEFICIARY_CONTRACT_TYPE, BENEFICIARY_STATUS } from '../utils/constants'
import { SelectField } from '../molecules/SelectField'
import { Grid } from '@material-ui/core'

interface StageOneBeneficiaryFormProps {
  control: Control<Record<string, any>> | undefined
  error: FieldErrors
}

export const BeneficiaryProfessionalForm: FC<StageOneBeneficiaryFormProps> = ({
  control,
  error,
}: StageOneBeneficiaryFormProps) => {

  return (
    <form>
      <Grid container justifyContent='center' spacing={2}>
        <SelectField name='status' error={error} control={control} label={content.status} options={BENEFICIARY_STATUS} />
        <SelectField name='contractType' error={error} control={control} label={content.contractType} options={BENEFICIARY_CONTRACT_TYPE} />
        <InputField name='postOccupied' error={error} control={control} label={content.postOccupied} />
        <InputField name='codeAPE' error={error} control={control} label={content.codeAPE} placeholder={content.codeAPEPlaceholder} />
      </Grid>
    </form>
  )
}

const content = {
  status: 'Statut',
  postOccupied: 'Poste occupé',
  contractType: 'Type de contrat',
  codeAPE: 'Code APE',
  codeAPEPlaceholder: 'exemple: 1234A',
}
