import React, { FC, useContext, useState } from 'react'
import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useStateMachine } from 'little-state-machine'
import { DefaultButton } from '../atoms/DefaultButton'
import { useHistory } from 'react-router-dom'
import { Delete, Edit } from '@material-ui/icons'
import { Popup } from '../molecules/Popup'
import { deleteProfile } from '../stores/fbProfile'
import { deleteConsultant } from '../stores/fbConsultant'
import { deleteBeneficiary } from '../stores/fbBeneficiary'
import { deleteAction } from '../stores/fbAction'
import { auth } from '../utils/firebase'
import { AuthContext } from '../utils/hooks'
import { cleanGlobalState, updateIsAuthenticated } from '../stores/store'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import IconButton from '@material-ui/core/IconButton'
import { PersonalInformation } from './PersonalInformation'
import { ProfessionalInformation } from './ProfessionalInformation'
import { ArrestInformation } from './ArrestInformation'
import clsx from 'clsx'
import { BoeInformation } from './BoeInformation'

export const ProfileInformation: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const authentication = useContext(AuthContext)
  const [ open, setOpen ] = useState(false)
  const { state, actions } = useStateMachine({ cleanGlobalState, updateIsAuthenticated })
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const laptop = useMediaQuery(BREAKPOINTS.laptop)

  const handleDeleteUser = () => {
    deleteProfile(authentication?.uid)
    deleteConsultant(authentication?.uid)
    deleteBeneficiary(authentication?.uid)
    deleteAction(authentication?.uid)
    actions.cleanGlobalState('')
    actions.updateIsAuthenticated(false)
    history.push('/connexion')
  }

  const deletedUser = () => {
    auth.currentUser?.delete()
      .then(handleDeleteUser)
      .catch((error) => console.log(error))
  }

  return (
    <>
      <Popup
        open={open}
        title='Validation'
        message={content.choiceDelete}
        handleClose={() => setOpen(false)}
        handleValidate={deletedUser}
        validateButton='Oui'
        backButton='Annuler'
        variant='outlined'
      />
      <Grid
        container
        alignItems='center'
        direction={tablet ? 'column' : 'row'}
        justifyContent={tablet ? 'center' : 'space-between'}
      >
        <Typography variant={mobile ? 'h5' : 'h4'} className={classes.title}>
          {content.title}
        </Typography>
        {mobile ? (
          <Box component='div' className={classes.buttonContainer}>
            <IconButton onClick={() => history.push('/modifier-mon-compte')} style={{ color: COLORS.cyanForte }}>
              <Edit />
            </IconButton>
          </Box>
        ) : (
          <Box component='div' className={classes.buttonContainer}>
            <DefaultButton
              icon={<Edit />}
              label={content.editButton}
              styleProps={classes.editButton}
              onClick={() => history.push('/modifier-mon-compte')}
            />
          </Box>
        )}
      </Grid>
      <div style={{ width: '100%', height: '2rem' }} />
      <Grid container spacing={2}>
        <Grid item xs={tablet ? 12 : 6}>
          <Typography variant={mobile ? 'h6' : 'h5'}
                      className={clsx(classes.title, laptop && classes.alignCenter, mobile && classes.marginVertical)}>
            {content.subTitlePersonal}
          </Typography>
          <Paper elevation={2} className={clsx(classes.paper, !tablet && classes.fixedHeight, !mobile && classes.fixedPadding)}>
            <PersonalInformation />
          </Paper>
        </Grid>
        <Grid item xs={tablet ? 12 : 6}>
          <Typography variant={mobile ? 'h6' : 'h5'}
                      className={clsx(classes.title, laptop && classes.alignCenter, mobile && classes.marginVertical)}>
            {content.subTitleBusiness}
          </Typography>
          <Paper elevation={2}
                 className={clsx(classes.paper, !tablet && classes.fixedHeight, !mobile && classes.fixedPadding)}>
            <ProfessionalInformation />
          </Paper>
        </Grid>
        {state.beneficiary.isBOE && (
          <Grid item xs={12}>
            <Typography variant={mobile ? 'h6' : 'h5'}
                        className={clsx(classes.title, laptop && classes.alignCenter, mobile && classes.marginVertical)}>
              {content.subStatusBOE}
            </Typography>
            <Paper elevation={2}
                   className={clsx(classes.paper, !mobile && classes.fixedPadding)}>
              <BoeInformation />
            </Paper>
          </Grid>
        )}
        {state.beneficiary.isWorkArrest && (
          <Grid item xs={12}>
            <Typography variant={mobile ? 'h6' : 'h5'}
                        className={clsx(classes.title, laptop && classes.alignCenter, mobile && classes.marginVertical)}>
              {content.subWorkArrest}
            </Typography>
            <Paper elevation={2}
                   className={clsx(classes.paper, !mobile && classes.fixedPadding)}>
              <ArrestInformation />
            </Paper>
          </Grid>
        )}
        {mobile ? (
          <Box component='div' alignItems='center' width='100%' className={classes.buttonContainer}>
            <IconButton onClick={() => setOpen(true)} style={{ color: COLORS.red }}>
              <Delete />
            </IconButton>
          </Box>
        ) : (
          <Box component='div' alignItems='center' width='100%' className={classes.buttonContainer}>
            <DefaultButton
              type='danger'
              variant='outlined'
              icon={<Delete />}
              label={content.delete}
              styleProps={classes.deleteButton}
              onClick={() => setOpen(true)}
            />
          </Box>
        )}
      </Grid>
    </>
  )
}

const content = {
  editButton: 'Modifier mon profil',
  title: 'Description',
  delete: 'Supprimer mon compte',
  choiceDelete: ' Étes-vous sur de vouloir supprimer ce compte ?',
  subTitlePersonal: 'Informations personnelles',
  subTitleBusiness: 'Informations\n professionnelles',
  subWorkArrest: 'Arrêt de travail',
  subStatusBOE: 'Bénéficiaire de l\'obligation d\'emploi',
}

const useStyles = makeStyles(() => (
  {
    title: {
      fontWeight: 'bold',
      marginRight: '1rem',
    },
    buttonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    editButton: {
      marginRight: '1rem',
      width: '15.5rem',
      alignSelf: 'center',
    },
    deleteButton: {
      marginRight: '1rem',
      width: '15.5rem',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      overflow: 'auto',
    },
    fixedPadding: {
      padding: '1.5rem',
    },
    fixedHeight: {
      height: '26rem',
    },
    alignCenter: {
      textAlign: 'center',
    },
    marginVertical: {
      margin: '1rem 0',
    },
  }
))
