import React, { FC, ReactNode } from 'react'
import { Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { DefaultButton } from '../atoms/DefaultButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'
import clsx from 'clsx'

interface CardWithButtonProps {
  title: string
  text: string
  buttonLabel: string
  onClick: () => void
  endIcon?: ReactNode
  icon?: ReactNode
}

export const CardWithButton: FC<CardWithButtonProps> = ({
  title,
  text,
  buttonLabel,
  onClick,
  endIcon,
  icon,
}: CardWithButtonProps) => {
  const classes = useStyles()
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const laptop = useMediaQuery(BREAKPOINTS.laptop)

  return (
    <Grid item xs={tablet ? 12 : 6}>
      <Card className={clsx(classes.card, !laptop && classes.fixedHeight)}>
        <CardContent className={classes.cardContent}>
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='body1' className={classes.text}>
            {text}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <DefaultButton icon={icon} endIcon={endIcon} label={buttonLabel} onClick={onClick} />
        </CardActions>
      </Card>
    </Grid>
  )
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fixedHeight: {
    height: '20rem',
  },
  cardContent: {
    height: '50%',
  },
  cardActions: {
    height: '50%',
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    marginTop: '2rem',
  },
})
