import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'

export const PersonalInformation: FC = () => {
  const classes = useStyles()
  const { state } = useStateMachine()

  const birthday = state.profile?.birthday ? new Date(state.profile.birthday).toLocaleDateString() : '...'

  return (
    <>
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.firstname}</span>
        {state.profile.firstname}
      </Typography>
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.lastname}</span>
        {state.profile.lastname}
      </Typography>
      {!state.profile.isConsultant &&
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.birthday}</span>
        {birthday}
      </Typography>
      }
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.street}</span>
        {state.profile.street}
      </Typography>
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.city}</span>
        {state.profile.city}
      </Typography>
      <Typography variant='body1' className={classes.typo}>
        <span className={classes.scope}>{content.postcode}</span>
        {state.profile.postcode}
      </Typography>
      {state.profile.phone && (
        <Typography variant='body1' className={classes.typo}>
          <span className={classes.scope}>{content.phone}</span>
          {state.profile.phone}
        </Typography>
      )}
    </>
  )
}

const content = {
  firstname: 'Prénom: ',
  lastname: 'Nom: ',
  birthday: 'Date de naissance: ',
  street: 'Adresse: ',
  city: 'Ville: ',
  postcode: 'Code postal: ',
  phone: 'Téléphone: ',
}

const useStyles = makeStyles(() => (
  {
    typo: {
      margin: '0.5rem',
    },
    scope: {
      fontWeight: 'bold',
    },
  }
))
