export const ACTION_UPDATES = {
  creation: {
    key: 'STATUS_CREATED',
    label: 'Creation du dispositif',
  },
  status: {
    key: 'STATUS_CHANGED',
    label: 'Changement de status',
  },
  deviceType: {
    key: 'DEVICE_CHANGED',
    label: 'Changement de dispositif',
  },
  actionDescription: {
    key: 'DESCRIPTION_CHANGED',
    label: 'Changement de description',
  },
}
