import React, { FC, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { InputField } from '../molecules/InputField'
import { DefaultButton } from '../atoms/DefaultButton'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { auth } from '../utils/firebase'
import { HalfSplitTemplate } from '../templates/HalfSplitTemplate'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetSchema } from '../utils/validation'
import { makeStyles } from '@material-ui/styles'
import { LoginField } from '../utils/type'
import { Logo } from '../atoms/Logo'
import { useQueryParameters } from '../utils/hooks'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'

const ResetPassword: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const [ oobCode, setOobCode ] = useState<string>('')
  const [ verified, setVerified ] = useState<boolean>(false)
  const queryParameters = useQueryParameters()

  useEffect(() => {
    if (queryParameters) {
      const oobCode = queryParameters.get('oobCode')
      if (oobCode) {
        verifyPasswordResetLink(oobCode)
      } else {
        setVerified(false)
      }
    } else {
      setVerified(false)
    }
  }, [])

  const verifyPasswordResetLink = (_oobCode: string) => {
    auth
      .verifyPasswordResetCode(_oobCode)
      .then(() => {
        setOobCode(_oobCode)
        setVerified(true)
      })
      .catch((error) => {
        console.log('error verifyPasswordResetCode => ', error.message)
        setVerified(false)
      })
  }

  const passwordResetRequest = (data: LoginField) => {
    auth
      .confirmPasswordReset(oobCode, data.password)
      .then(() => {
        history.push('/connexion')
      })
      .catch((error) => {
        console.log('error verifyPasswordResetCode => ', error.message)
      })
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  })

  return (
    <HalfSplitTemplate
      main={
        <>
          <Logo styleProps={mobile ? classes.logoWidthMobile : classes.logoWidth} />
          <Typography variant={tablet ? 'h5' : 'h4'} align='center' className={classes.title}>
            {content.title}
          </Typography>
          {verified ? (
            <>
              <Typography variant='subtitle2' align='center' className={classes.text}>
                {content.textVerified}
              </Typography>
              <form className={classes.form}>
                <InputField
                  defaultValue=''
                  control={control}
                  name='password'
                  error={errors}
                  label={content.labelPassword}
                  isPassword={true}
                  styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
                />
                <DefaultButton
                  label={content.signIn}
                  onClick={handleSubmit(passwordResetRequest)}
                  styleProps={clsx(classes.customButton, tablet ? classes.fixedWidthMobile : classes.fixedWidth)}
                />
                <Box component='div' className={clsx(classes.mention, tablet && classes.fixedMentionTablet)} display='flex'
                     flexDirection={mobile ? 'column' : 'row'}>
                  <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
                  <Typography variant='caption'> Tous droits réservés</Typography>
                </Box>
              </form>
            </>
          ) : (
            <>
              <Typography variant='subtitle2' align='center' className={classes.text}>
                {content.textNotVerified}
              </Typography>
              <Box component='div' className={clsx(classes.mention, tablet && classes.fixedMentionTablet)} display='flex'
                   flexDirection={mobile ? 'column' : 'row'}>
                <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
                <Typography variant='caption'> Tous droits réservés</Typography>
              </Box>
            </>
          )}
        </>
      }
    />
  )
}

export default ResetPassword

const content = {
  labelPassword: 'Mot de passe',
  signIn: 'Envoyer',
  requiredMsg: 'Ce champ doit être rempli.',
  minPassword: 'Minimum 6 caractères requis.',
  title: 'Réinitialisation du mot de passe',
  textVerified: 'Veuillez entrer un nouveau mot de passe fort.',
  textNotVerified: 'Lien non valide.',
}

const useStyles = makeStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
  logoWidth: {
    width: '15rem',
  },
  logoWidthMobile: {
    width: '10rem',
  },
  title: {
    fontWeight: 'bold',
    margin: '0 1rem 2.5rem 1rem',
  },
  fixedWidth: {
    width: '40vw',
  },
  fixedWidthMobile: {
    width: '80vw',
  },
  customButton: {
    marginTop: '2rem',
    minWidth: '19rem',
  },
  text: {
    marginBottom: '2.5rem',
    minWidth: '28.5vw',
  },
  mention: {
    zIndex: 2,
    color: COLORS.white,
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  fixedMentionTablet: {
    color: COLORS.black,
    width: '100%',
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: '3rem',
    bottom: 5,
    right: 5,
  },
})
