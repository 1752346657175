import React, { FC } from 'react'
import { Authentification } from './nav/Authentification'
import { StateMachineProvider } from 'little-state-machine'
import { DevTool } from 'little-state-machine-devtools'

const App: FC = () => {
  return (
    <StateMachineProvider>
      <DevTool />
      <Authentification />
    </StateMachineProvider>
  )
}

export default App
