import React, { FC, useState } from 'react'
import { Logo } from '../atoms/Logo'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ProfileForm } from '../organisms/ProfileForm'
import { Steppers } from '../molecules/Steppers'
import { BREAKPOINTS, REGISTRATION_STEP, REGISTRATION_STEP_LABELS } from '../utils/constants'
import { ConsultantForm } from '../organisms/ConsultantForm'
import { BeneficiaryForm } from '../organisms/BeneficiaryForm'
import { useStateMachine } from 'little-state-machine'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const RegistrationSteps: FC = () => {
  const classes = useStyles()
  const [ currentStep, setCurrentStep ] = useState(1)
  const { state } = useStateMachine()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  const stepRender = () => {
    switch (currentStep) {
      case REGISTRATION_STEP.PROFESSIONAL:
        if (state.profile.isConsultant) {
          return <ConsultantForm previousStep={() => setCurrentStep(1)} />
        }
        return <BeneficiaryForm previousStep={() => setCurrentStep(1)} />
      default:
        return <ProfileForm nextStep={() => setCurrentStep(2)} />
    }
  }

  return (
    <>
      <Grid container direction='column' justifyContent='center' alignItems='center' className={classes.root}>
        <Logo styleProps={classes.logoWidth} />
        <Steppers currentStep={currentStep} steps={REGISTRATION_STEP_LABELS} />
      </Grid>
      {stepRender()}
      <Box component='div' className={classes.mention} display='flex' flexDirection={mobile ? 'column' : 'row'}>
        <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
        <Typography variant='caption'> Tous droits réservés</Typography>
      </Box>
    </>
  )
}

export default RegistrationSteps

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  logoWidth: {
    width: '10rem',
  },
  mention: {
    position: 'relative',
    marginTop: '3rem',
    justifyContent: 'flex-end',
    bottom: 5,
    right: 5,
  },
})
