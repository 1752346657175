import React, { FC, useEffect, useState } from 'react'
import { searchProfile } from '../stores/fbProfile'
import { auth } from '../utils/firebase'
import firebase from 'firebase/app'
import { Loading } from '../atoms/Loading'
import { useStateMachine } from 'little-state-machine'
import { updateBeneficiary, updateConsultant, updateIsAuthenticated, updateIsLoadingUser, updateProfile } from '../stores/store'
import { Routes } from './Routes'
import { searchBeneficiary } from '../stores/fbBeneficiary'
import { searchConsultant } from '../stores/fbConsultant'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { COLORS } from '../utils/constants'
import { AuthContext } from '../utils/hooks'

const materialTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.cyanForte,
    },
  },
})

export const Authentification: FC = () => {
  const [ user, setUser ] = useState<firebase.User | undefined>(undefined)

  const { state, actions } = useStateMachine({
    updateProfile,
    updateConsultant,
    updateBeneficiary,
    updateIsLoadingUser,
    updateIsAuthenticated,
  })

  const handleConsultant = async(uid: string) => {
    await searchConsultant(uid).then((consultant) => {
      actions.updateConsultant(consultant!)
    })
  }

  const handleBeneficiary = async(uid: string) => {
    await searchBeneficiary(uid).then((beneficiary) => {
      actions.updateBeneficiary(beneficiary!)
    })
  }

  const handleProfile = async(uid: string) => {
    await searchProfile(uid)
      .then((profile) => {
        actions.updateProfile(profile!)
      })
      .then(() => {
        state.profile.isConsultant ? handleConsultant(uid) : handleBeneficiary(uid)
      })
  }

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (user) {
        handleProfile(user.uid)
          .then(() => setUser(user))
          .then(() => actions.updateIsAuthenticated(true))
          .then(() => actions.updateIsLoadingUser(false))
      } else {
        setUser(undefined)
        actions.updateIsAuthenticated(false)
        actions.updateIsLoadingUser(false)
      }
    })
    return () => {
      unsub()
    }
  }, [ state.profile.isConsultant ])

  if (state.isLoadingUser) return <Loading />

  return (
    <AuthContext.Provider value={user}>
      <ThemeProvider theme={materialTheme}>
        <Routes />
      </ThemeProvider>
    </AuthContext.Provider>
  )
}
