export const getErrorMessage = (type: string) => {
  switch (type) {
    case 'auth/email-already-in-use':
      return 'Cette adresse e-mail est déjà utilisée'
    case 'auth/user-not-found':
      return 'Cet email ne correspond à aucun compte'
    case 'auth/wrong-password':
      return 'Le mot de passe entré est invalide'
    default:
      return 'Une erreur inattendue s\'est produite. Veuillez réessayer ultérieurement.'
  }
}
