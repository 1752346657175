import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'

export const Loading: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.circularContainer}>
      <CircularProgress />
    </div>
  )
}

const useStyles = makeStyles({
  circularContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
