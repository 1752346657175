import React, { ChangeEvent, FC, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Beneficiary } from '../utils/type'
import { FormTemplate } from '../templates/FormTemplate'
import { DefaultButton } from '../atoms/DefaultButton'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../utils/hooks'
import { useStateMachine } from 'little-state-machine'
import { updateBeneficiary } from '../stores/store'
import { generateBeneficiaryFromForm, saveBeneficiary } from '../stores/fbBeneficiary'
import { BeneficiaryProfessionalForm } from './BeneficiaryProfessionalForm'
import { BeneficiaryHealthForm } from './BeneficiaryHealthForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { beneficiaryFields } from '../utils/validation'
import { BeneficiarySchoolForm } from './BeneficiarySchoolForm'

interface BeneficiaryFormProps {
  previousStep: () => void
}

export const BeneficiaryForm: FC<BeneficiaryFormProps> = ({ previousStep }: BeneficiaryFormProps) => {
  const authentication = useContext(AuthContext)
  const history = useHistory()
  const { state, actions } = useStateMachine({ updateBeneficiary })

  const [ status, setStatus ] = useState({
    isBOE: false,
    isWorkArrest: false,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus({ ...status, [ event.target.name ]: event.target.checked })
  }

  const updatingBeneficiary = async(data: Beneficiary) => {
    const updatedBeneficiary = generateBeneficiaryFromForm(data, status.isBOE, status.isWorkArrest)
    const dateWorkArrest = updatedBeneficiary.dateWorkArrest && updatedBeneficiary.dateWorkArrest.toString()
    await saveBeneficiary(authentication?.uid, updatedBeneficiary, dateWorkArrest)
    actions.updateBeneficiary(updatedBeneficiary)
    state.permissionForUidConsultant ? history.push('/acces') : history.push('/')
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(beneficiaryFields),
    shouldFocusError: false,
  })

  return (
    <FormTemplate
      main={
        <>
          <BeneficiaryProfessionalForm control={control} error={errors} />
          <BeneficiarySchoolForm control={control} error={errors} />
          <BeneficiaryHealthForm
            control={control}
            error={errors}
            onChange={handleChange}
            isBOE={status.isBOE}
            isWorkArrest={status.isWorkArrest}
          />
        </>
      }
      previous={<DefaultButton onClick={() => previousStep()} label={content.buttonPrevious} variant='outlined' />}
      next={<DefaultButton onClick={handleSubmit(updatingBeneficiary)} label={content.buttonNext} />}
    />
  )
}

const content = {
  buttonPrevious: 'Précédent',
  buttonNext: 'Suivant',
}
