import React, { FC } from 'react'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import { IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { DefaultButton } from '../atoms/DefaultButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Variant } from '../utils/type'
import { COLORS } from '../utils/constants'

interface PopupProps {
  title: string
  message: string
  open: boolean
  handleClose: () => void
  backButton: string
  handleValidate: () => void
  validateButton?: string
  variant?: Variant
}

export const Popup: FC<PopupProps> = ({
  title,
  message,
  open,
  handleClose,
  handleValidate,
  validateButton,
  backButton,
  variant,
}: PopupProps) => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Grid container spacing={0} justifyContent='center' className={classes.paper}>
        <Grid container item xs={12} justifyContent='flex-end'>
          <IconButton onClick={handleClose}>
            <Close fontSize='medium' />
          </IconButton>
        </Grid>

        <Grid container item xs={12} justifyContent='center' alignItems='center' direction='column' className={classes.gridHead}>
          <Typography variant='h5' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='subtitle2' className={classes.subTitle}>
            {message}
          </Typography>
        </Grid>
        <Grid container item xs={12} alignItems='center' justifyContent='center' className={classes.gridBody}>
          <DefaultButton variant={variant} onClick={handleClose} label={backButton} />
          {validateButton &&
          <DefaultButton onClick={handleValidate} label={validateButton} styleProps={classes.buttonValidate} type="danger" />
          }
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: '24rem',
    backgroundColor: COLORS.white,
    border: '0.1rem solid #00000070',
    borderRadius: '0.3rem',
  },
  gridHead: {
    marginBottom: '1rem',
  },
  gridBody: {
    marginBottom: '1rem',
  },
  title: {
    fontWeight: 'bold',
    margin: '1rem 0',
  },
  subTitle: {
    textAlign: 'center',
    margin: '0 1rem',
  },
  buttonValidate: {
    marginLeft: '1rem',
  },
})
