import React, { FC } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { ButtonType, Variant } from '../utils/type'
import { COLORS } from '../utils/constants'

interface DefaultButtonProps {
  onClick: () => void
  label: string
  type?: ButtonType
  variant?: Variant
  styleProps?: string
  disabled?: boolean
  icon?: React.ReactNode
  endIcon?: React.ReactNode
}

export const DefaultButton: FC<DefaultButtonProps> = ({
  onClick,
  label,
  disabled,
  styleProps,
  variant,
  icon,
  endIcon,
  type,
}: DefaultButtonProps) => {
  const classes = useStyles()

  const onStyle = () => {
    switch (variant) {
      case 'text':
        if (type === 'danger') {
          return `${classes.textDanger}`
        } else if (type === 'info') {
          return `${classes.textInfo}`
        } else {
          return ''
        }
      case 'outlined':
        if (type === 'danger') {
          return `${classes.button} ${classes.outlinedDanger}`
        } else {
          return `${classes.button} ${classes.outlined}`
        }
      default:
        if (type === 'danger') {
          return `${classes.button} ${classes.containedDanger}`
        } else {
          return `${classes.button} ${classes.contained}`
        }
    }
  }

  return (
    <Button
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      startIcon={icon}
      endIcon={endIcon}
      className={`${onStyle()} ${styleProps}`}
    >
      {label}
    </Button>
  )
}

DefaultButton.defaultProps = {
  variant: 'contained',
}

const useStyles = makeStyles({
  button: {
    backgroundBlendMode: 'soft-light, normal',
    fontWeight: 300,
    margin: '0.5rem 0',
    '&:hover': {
      backgroundColor: 'rgba(17,191,182,0.40)',
    },
  },
  contained: {
    color: COLORS.veryLightGray,
    background: COLORS.cyanForte,
  },
  containedDanger: {
    color: COLORS.white,
    backgroundColor: COLORS.red,
    '&:hover': {
      backgroundColor: 'rgba(213,64,64,0.40)',
    },
  },
  outlined: {
    color: COLORS.cyanForte,
    borderColor: COLORS.cyanForte,
  },
  textDanger: {
    color: COLORS.red,
  },
  textInfo: {
    color: COLORS.cyanForte,
  },
  outlinedDanger: {
    color: COLORS.red,
    borderColor: COLORS.red,
    '&:hover': {
      backgroundColor: 'rgba(213,64,64,0.40)',
    },
  },
})
