import React, { FC, useContext } from 'react'
import { InputField } from '../molecules/InputField'
import { useForm } from 'react-hook-form'
import { Consultant } from '../utils/type'
import { yupResolver } from '@hookform/resolvers/yup'
import { consultantFields } from '../utils/validation'
import { FormTemplate } from '../templates/FormTemplate'
import { Grid } from '@material-ui/core'
import { DefaultButton } from '../atoms/DefaultButton'
import { generateConsultantFromForm, saveConsultant } from '../stores/fbConsultant'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../utils/hooks'
import { useStateMachine } from 'little-state-machine'
import { updateConsultant } from '../stores/store'

interface ConsultantFormProps {
  previousStep: () => void
}

export const ConsultantForm: FC<ConsultantFormProps> = ({ previousStep }: ConsultantFormProps) => {
  const history = useHistory()
  const authentication = useContext(AuthContext)
  const { actions } = useStateMachine({ updateConsultant })

  const updatingConsultant = async(data: Consultant) => {
    const updatedConsultant = generateConsultantFromForm(data)
    await saveConsultant(authentication?.uid, updatedConsultant)
    actions.updateConsultant(updatedConsultant)
    history.push('/')
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(consultantFields),
    shouldFocusError: false,
  })

  return (
    <FormTemplate
      main={
        <form>
          <Grid container justifyContent='center' spacing={2}>
            <InputField name='organism' error={errors} control={control} label={content.organism} />
            <InputField name='function' error={errors} control={control} label={content.function} />
          </Grid>
        </form>
      }
      previous={<DefaultButton onClick={() => previousStep()} label={content.buttonPrevious} variant='outlined' />}
      next={<DefaultButton onClick={handleSubmit(updatingConsultant)} label={content.buttonNext} />}
    />
  )
}

const content = {
  buttonPrevious: 'Précédent',
  buttonNext: 'Suivant',
  organism: 'Organisme/Entreprise',
  function: 'Poste occupé',
}
