import React, { FC } from 'react'
import { InputField } from '../molecules/InputField'
import { Control, FieldErrors } from 'react-hook-form'
import { BENEFICIARY_LANGUAGE_LEVEL, BENEFICIARY_LEVEL_SCHOOL } from '../utils/constants'
import { SelectField } from '../molecules/SelectField'
import { Grid } from '@material-ui/core'

interface BeneficiarySchoolFormProps {
  control: Control<Record<string, any>> | undefined
  error: FieldErrors
}

export const BeneficiarySchoolForm: FC<BeneficiarySchoolFormProps> = ({
  control,
  error,
}: BeneficiarySchoolFormProps) => {

  return (
    <form>
      <Grid container justifyContent='center' spacing={2}>
        <InputField name='formation' error={error} control={control} label={content.formation} />
        <SelectField name='levelSchool' error={error} control={control} label={content.levelSchool} options={BENEFICIARY_LEVEL_SCHOOL} />
        <InputField name='language' error={error} control={control} label={content.language} />
        <SelectField name='languageLevel' error={error} control={control} label={content.languageLevel} options={BENEFICIARY_LANGUAGE_LEVEL} />
      </Grid>
    </form>
  )
}

const content = {
  formation: 'Formation',
  language: 'Langue',
  levelSchool: 'Niveau de formation',
  languageLevel: 'Niveau de langue',
}
