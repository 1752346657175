import React, { FC, useContext } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { CardWithButton } from '../molecules/cardWithButton'
import { AuthContext } from '../utils/hooks'
import { mailAccess, mailRegistration } from '../utils/mailTemplate'
import { useStateMachine } from 'little-state-machine'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'

export const BeneficiarySend: FC = () => {
  const authentication = useContext(AuthContext)
  const { state } = useStateMachine()
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  return (
    <>
      <Typography variant={mobile ? 'h5' : 'h4'} className={classes.title}>
        {content.title}
      </Typography>
      <div style={{ width: '100%', height: '2rem' }} />
      <Grid container spacing={2}>
        <CardWithButton
          title={content.subtitleAccessingBeneficiary}
          text={content.textAccessingBeneficiary}
          buttonLabel={content.accessBequestButton}
          onClick={() =>
            (window.location.href =
              'mailto:?subject=' + content.subjectAccessing + '&body=' + mailAccess(state.profile.lastname, authentication?.uid))
          }
          endIcon={<Send />}
        />
        <CardWithButton
          title={content.subtitleInviteNewBeneficiary}
          text={content.textInviteNewBeneficiary}
          buttonLabel={content.registrationRequestButton}
          onClick={() =>
            (window.location.href =
              'mailto:?subject=' + content.subjectRegistration + '&body=' + mailRegistration(state.profile.lastname, authentication?.uid))
          }
          endIcon={<Send />}
        />
      </Grid>
    </>
  )
}

const content = {
  title: 'Inviter un bénéficiaire',
  subtitleAccessingBeneficiary: 'Accéder au compte d’un bénéficiaire',
  textAccessingBeneficiary: 'Le bénéficiaire a déjà un compte sur la plateforme SAM.I.',
  subtitleInviteNewBeneficiary: 'Inviter un nouveau bénéficiaire à s’inscrire',
  textInviteNewBeneficiary: 'Le bénéficiaire n’a pas encore de compte sur la plateforme SAM.I.',
  accessBequestButton: 'Envoyer une demande d’accès',
  registrationRequestButton: 'Envoyer une demande d’inscription',

  subjectAccessing: 'SAM.I la plateforme collaborative intelligente - Notification de demande d\'accès',
  subjectRegistration: 'SAM.I la plateforme collaborative intelligente - Demande d\'inscription',
}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
})
