import React, { FC, useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { InputField } from '../molecules/InputField'
import { DefaultButton } from '../atoms/DefaultButton'
import { useHistory } from 'react-router-dom'
import { auth } from '../utils/firebase'
import { HalfSplitTemplate } from '../templates/HalfSplitTemplate'
import { Logo } from '../atoms/Logo'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginField } from '../utils/type'
import { forgotSchema } from '../utils/validation'
import { getErrorMessage } from '../utils/PopupMessage'
import { Popup } from '../molecules/Popup'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'

const ForgotPassword: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const [ open, setOpen ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ popupMessage, setPopupMessage ] = useState<string>('')

  const resetPasswordRequest = (data: LoginField) => {
    auth
      .sendPasswordResetEmail(data.email)
      .then(() => {
        setOpen(true)
        setError(false)
        setPopupMessage('Un email a été envoyé à l\'adresse indiquée.')
      })
      .catch((error) => {
        setOpen(true)
        setError(true)
        setPopupMessage(getErrorMessage(error.code))
        console.log('error.code => ', error.code)
      })
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  })

  return (
    <HalfSplitTemplate
      main={
        <>
          <Popup
            open={open}
            title={error ? 'Erreur' : 'E-mail envoyé'}
            message={popupMessage}
            handleClose={() => {
              error ? setOpen(false) : history.push('/connexion')
            }}
            handleValidate={() => undefined}
            backButton='Valider'
          />
          <Logo styleProps={mobile ? classes.logoWidthMobile : classes.logoWidth} />
          <Typography variant={tablet ? 'h5' : 'h4'} align='center' className={classes.title}>
            {content.title}
          </Typography>
          <Typography variant='subtitle2' align='center' className={tablet ? classes.fixedWidthMobile : classes.fixedWidth}>
            {content.text}
          </Typography>
          <form className={classes.form}>
            <InputField
              control={control}
              name='email'
              error={errors}
              placeholder={content.placeholderEmail}
              label={content.labelEmail}
              styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
            />
            <DefaultButton
              label={content.signIn}
              onClick={handleSubmit(resetPasswordRequest)}
              styleProps={clsx(classes.customButton, tablet ? classes.fixedWidthMobile : classes.fixedWidth)}
            />
            <Box component='div' className={clsx(classes.mention, tablet && classes.fixedMentionTablet)} display='flex' flexDirection={mobile ? 'column' : 'row'}>
              <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
              <Typography variant='caption'> Tous droits réservés</Typography>
            </Box>
          </form>
        </>
      }
    />
  )
}

export default ForgotPassword

const content = {
  labelEmail: 'E-mail',
  placeholderEmail: 'Saisissez votre e-mail',
  signIn: 'Envoyer',
  requiredMsg: 'Ce champ doit être rempli.',
  invalidEmail: 'Adresse email invalide.',
  title: 'Réinitialisation du mot de passe',
  text: 'Si vous avez oublié votre mot de passe, un e-mail va vous être envoyé avec un lien de réinitialisation. Saisissez l\'adresse e-mail que vous utilisez pour votre compte SAM.I.',
}

const useStyles = makeStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
  logoWidth: {
    width: '15rem',
  },
  logoWidthMobile: {
    width: '10rem',
  },
  title: {
    fontWeight: 'bold',
    margin: '0 1rem 2.5rem 1rem',
  },
  text: {
    minWidth: '28.5vw',
    margin: '0 2.5rem 2.5rem 2.5rem',
  },
  fixedWidth: {
    width: '40vw',
  },
  fixedWidthMobile: {
    width: '80vw',
  },
  customButton: {
    marginTop: '2rem',
    minWidth: '19rem',
  },
  mention: {
    zIndex: 2,
    color: COLORS.white,
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  fixedMentionTablet: {
    color: COLORS.black,
    width: '100%',
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: '3rem',
    bottom: 5,
    right: 5,
  },
})
