import { Typography, Grid, makeStyles, Paper } from '@material-ui/core'
import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DefaultButton } from '../atoms/DefaultButton'
import { PrivacyPoliciesUserType } from '../utils/type'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'
import clsx from 'clsx'

const useStyles = makeStyles({
  container: {
    padding: '1.5rem 1.5rem 3.5rem 1.5rem',
    height: '100vh',
  },
  paperMain: {
    padding: '1rem 2.3rem 1rem 2.3rem',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  fixedPadding: {
    padding: '1rem',
  },
  list: {
    '& li:not(:first-child)': {
      margin: '2rem 0',
    },
    '& li *': {
      margin: '.5rem 0',
    },
  },
  listHyphen: {
    '& li': {
      listStyleType: '"- "',
      margin: '0 !important',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  fixedMarginBottom: {
    marginBottom: '2rem',
  },
})

const PrivacyPoliciesBeneficiary: FC = () => {
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  return (
    <Paper className={clsx(classes.paperMain, mobile && classes.fixedPadding)}>
      <Typography variant={mobile ? 'h5' : 'h4'} className={clsx(classes.title, classes.fixedMarginBottom)}>
        Politique de confidentialité bénéficiaires
      </Typography>
      <Typography variant="body2" component="div">
        Notre société (ci-après <strong>« T4W »</strong>), en tant que responsable de traitement, attache une importance toute particulière à la protection de
        vos données personnelles (ci-après <strong>« Données Personnelles » ou « Données »</strong>), telles que définies par le Règlement (UE) 2016/679 relatif
        à la protection des Données Personnelles et par la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Informatique et Libertés » (ci-après dénommés
        ensemble la <strong>« Règlementation »</strong>).
        <br/>
        <br/>
        La Politique de Confidentialité ci-dessous détaille la politique de T4W en matière de données personnelles pour toutes les activités ayant lieu sur
        l’application SAMi (ci-après l’<strong>« Application »</strong>).
        <br/>
        <br/>
        Cette Politique de Confidentialité s’applique à toutes les informations fournies à T4W par vous-même, par les professionnels et organismes choisis par
        vous ou recueillies par T4W lors de votre navigation. Elle décrit nos pratiques de collecte, d’utilisation, de conservation, de divulgation et de
        protection des Données Personnelles. Elle s’applique à toute utilisation de l’Application.
        <br/>
        <br/>
        En utilisant l’Application, vous consentez au traitement des données dans les conditions prévues dans la présente Politique de confidentialité.
        <br/>
        <br/>
        Nous vous invitons à lire cette Politique de confidentialité très attentivement ainsi que les différentes notifications qui pourraient vous être
        communiquées au moment de la collecte afin d’être pleinement informé sur la collecte et sur les utilisations de vos Données Personnelles.
      </Typography>
      <ol className={classes.list}>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Informations générales</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            En utilisant l’Application, vous pouvez être amené à communiquer à T4W vos Données Personnelles :
            <ul className={classes.listHyphen}>
              <li>soit directement dans le cadre, notamment, de la création d’un compte utilisateur ; </li>
              <li>soit indirectement, par l’intermédiaire des professionnels et organismes choisis par vous.</li>
            </ul>
            <br/>
            Par ailleurs, T4W pourra être amenée à recueillir certaines informations relatives à votre navigation sur l’Application. Cette collecte est réalisée
            dans les conditions décrites dans l’article 6 « Cookies » de la présente Politique de Confidentialité.
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Droit d’accès de rectification, de suppression et d’opposition au traitement</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            Conformément aux articles 15 à 22 du Règlement Général sur la Protection des Données du 27 avril 2016, relatif à la protection des personnes
            physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces Données (2016/679) (« RGPD »), vous disposez,
            sous réserve de justifier de votre identité, d’un droit d’accès, de rectification, d’effacement, d’opposition, à la portabilité des Données
            Personnelles vous concernant et à la limitation des traitements, ainsi qu’au sort de ces mêmes Données après la mort. Ces droits peuvent être
            exercés en s’adressant à <u>contact@tech4work.fr</u>
            <br/>
            <br/>
            Si vous estimez que les efforts mis en œuvre par T4W pour préserver la confidentialité des Données sont insuffisants, vous avez la possibilité
            d’introduire une réclamation auprès de l’autorité de contrôle compétente (CNIL ou toute autre autorité mentionnée sur la liste disponible auprès de
            la Commission européenne).
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Utilisation des Données Personnelles par T4W</strong>
            </Typography>
          </u>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.1 Finalité de traitement</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données Personnelles collectées et traitées par T4W incluent non seulement des données administratives (principalement, civilité, nom, prénom,
            adresse postale, courriel) mais également les données communiquées par les professionnels et organismes choisis par vous. En aucune manière T4W ne
            collecte de données de santé et les Bénéficiaires comme les professionnels s’engagent à ne pas communiquer de telles données. T4W exclue toute
            responsabilité dans l’hypothèse où les utilisateurs communiqueraient des données de santé ou autres données sensibles.
            <br/>
            <br/>
            Au travers du Site, T4W recueille et traite uniquement les Données Personnelles nécessaires à la poursuite des finalités suivantes : vous fournir
            les services liés à l’Application (c’est-à-dire assurer votre accompagnement social et professionnel pour le retour et le maintien dans l’emploi),
            améliorer lesdits services, assurer le service client, détecter et prévenir les tentatives de fraudes.
            <br/>
            <br/>
            Lors de la collecte des Données Personnelles, les informations essentielles à la réalisation de ces finalités et indispensables pour répondre à vos
            demandes sont indiquées au moyen d’une astérisque.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.2 Base légale de traitement</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données Personnelles traitées pour les finalités ci-dessus exposées reposent sur les bases légales suivantes :
            <ul className={classes.listHyphen}>
              <li>fournir les services liées à l’Application : Conclusion et exécution du contrat / consentement ;</li>
              <li>améliorer les services : intérêt légitime ;</li>
              <li>
                assurer le service client : Conclusion et exécution du contrat, intérêt légitime (gestion de la qualité de la relation client) et obligation
                légale (demande d’exercice des droits) ;
              </li>
              <li>détecter et prévenir les tentatives de fraudes : intérêt légitime.</li>
            </ul>
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.3 Conservation des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données sont conservées pendant une durée qui n’excède pas la durée nécessaire aux finalités qui justifient leur traitement. Nous conservons vos
            données personnelles a minima pour la durée légale prescrite par les textes lorsque celle-ci est précisée. Lorsque nos intérêts légitimes exigent
            une durée de conservation différente, il sera tenu compte du caractère sensible des données, du risque potentiel et des exigences en matière de
            sécurité nécessaire pour ces données afin d’établir la durée de conservation.
            <br/>
            <br/>
            Par ailleurs, T4W pourra être amenée à conserver des données relatives à des comptes clos afin de respecter des exigences légales, au titre de la
            prévention de la fraude ou dans le cadre de la résolution de litiges, par exemple.
            <br/>
            <br/>
            Lorsque la conservation de vos Données Personnelles ne sera plus nécessaire à l’exécution des finalités pour lesquelles elles sont conservées, T4W
            procèdera à la suppression desdites Données Personnelles vous concernant.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.4 Destinataires des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données Personnelles sont collectées uniquement à votre bénéfice, ainsi qu’aux professionnels et organismes avec qui vous choisissez de partager
            lesdites données. <u>Vous gardez en toutes circonstances la maitrise de qui accède à vos Données Personnelles</u>.<br/>
            <br/>
            TW4 est susceptible d’accéder au contenu administratif de votre compte et donc à ses données administratives uniquement à des fins de bonne
            administration /gestion de votre compte.
            <br/>
            <br/>
            Les Données Personnelles recueillies pourront toutefois être communiquées à des tiers liés par contrat à T4W, pour l’exécution de prestations
            sous-traitées et/ou nécessaires à l’exécution des services disponibles sur l’Application. Avant tout transfert d’informations, nous vérifierons la
            conformité de la finalité de chaque demande et garantissons la transmission des éléments strictement nécessaires à l’exécution des services par le
            prestataire.
            <br/>
            <br/>
            En aucun cas, T4W ne cède, vend ou loue les Données Personnelles collectées à des tiers, que ce soit à titre onéreux ou gratuit.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.5 Hébergement / transfert des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            L’Application et les Données Personnelles traitées dans le cadre de son utilisation sont hébergées auprès d’un hébergeur situé sur le territoire de
            l’Union européenne.
            <br/>
            <br/>
            L’hébergeur est la société :<br/>
            <ul>
              <li style={{ listStyle: 'none' }}>
                Google Cloud Platform (GCP), région « eur3 (europe-west) » (correspondant à europe-west1 Belgique et europe-west4 Pays Bas sur les hébergements
                GCP)
              </li>
            </ul>
            <br/>
            <br/>
            Aucun transfert de données ne sera effectué sans votre accord préalable et exprès.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.6 Sécurité et confidentialité des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            T4W a fait le nécessaire pour mettre en place toutes les précautions utiles pour assurer la sécurité et la confidentialité des Données Personnelles
            traitées et empêcher qu’elles ne soient déformées endommagées, détruites ou qu’un tiers non autorisé y ait accès. L’ensemble des moyens de sécurité
            renforcés mis en place sont conformes à l’état de l’art, notamment pour ce qui relève des systèmes d’information.
            <br/>
            <br/>
            Dans la mesure où T4W ne maîtrise pas tous les risques liés au fonctionnement d’Internet, elle attire l’attention des visiteurs sur l’existence
            d’éventuels risques inhérents à son utilisation et à son fonctionnement.
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Modification des termes de la Politique de confidentialité</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            Nous sommes susceptibles de modifier la présente Politique de Confidentialité. Les nouveaux termes prendront effet dans le mois suivant la
            publication. Le cas échéant, toute modification importante pourra vous être notifiée par e-mail.
            <br/>
            <br/>
            T4W vous encourage à consulter la Politique de Confidentialité chaque fois que vous utilisez l’Application, afin de rester informé des pratiques
            mises en place en matière d’information et des moyens que vous pouvez utiliser pour protéger votre vie privée.
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Loi et Juridiction applicable</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            La présente Politique de Confidentialité est régie par la loi française et tout litige à son sujet relèvera de la compétence des Tribunaux
            territorialement compétents en application des règles édictées par le code de procédure civile français.
          </Typography>
        </li>
      </ol>
    </Paper>
  )
}

const PrivacyPoliciesConsultant: FC = () => {
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  return (
    <Paper className={clsx(classes.paperMain, mobile && classes.fixedPadding)}>
      <Typography variant={mobile ? 'h5' : 'h4'} className={clsx(classes.title, classes.fixedMarginBottom)}>
        Politique de confidentialité professionnels
      </Typography>
      <Typography variant="body2" component="div">
        Notre société (ci-après <strong>« T4W »</strong>), en tant que responsable de traitement, attache une importance toute particulière à la protection de
        vos données personnelles (ci-après <strong>« Données Personnelles » ou « Données »</strong>), telles que définies par le Règlement (UE) 2016/679 relatif
        à la protection des Données Personnelles et par la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Informatique et Libertés » (ci-après dénommés
        ensemble la <strong>« Règlementation »</strong>).
        <br/>
        <br/>
        La Politique de Confidentialité ci-dessous détaille la politique de T4W en matière de données personnelles pour toutes les activités ayant lieu sur
        l’application SAMi (ci-après l’<strong>« Application »</strong>).
        <br/>
        <br/>
        Cette Politique de Confidentialité s’applique à toutes les informations fournies à T4W, qu’elles vous concernent vous ou les Bénéficiaires des services
        SAMi. Elle décrit nos pratiques de collecte, d’utilisation, de conservation, de divulgation et de protection des Données Personnelles. Elle s’applique à
        toute utilisation de l’Application.
        <br/>
        <br/>
        En utilisant l’Application, vous consentez au traitement des données dans les conditions prévues dans la présente Politique de confidentialité.
        <br/>
        <br/>
        Nous vous invitons à lire cette Politique de confidentialité très attentivement ainsi que les différentes notifications qui pourraient vous être
        communiquées au moment de la collecte afin d’être pleinement informé sur la collecte et sur les utilisations des Données Personnelles.
      </Typography>
      <ol className={classes.list}>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Informations générales</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            En utilisant l’Application, vous pouvez être amené à communiquer à T4W:
            <ul className={classes.listHyphen}>
              <li>vos Données Personnelles ;</li>
              <li>les Données Personnelles des Bénéficiaires vous ayant préalablement désigné.</li>
            </ul>
            <br/>
            En aucune manière T4W ne collecte de données de santé et les Bénéficiaires comme les professionnels s’engagent à ne pas communiquer de telles
            données. T4W exclue toute responsabilité dans l’hypothèse où les utilisateurs communiqueraient des données de santé ou autres données sensibles.
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Droit d’accès de rectification, de suppression et d’opposition au traitement</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            Conformément aux articles 15 à 22 du Règlement Général sur la Protection des Données du 27 avril 2016, relatif à la protection des personnes
            physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces Données (2016/679) (« RGPD »), vous disposez,
            sous réserve de justifier de votre identité, d’un droit d’accès, de rectification, d’effacement, d’opposition, à la portabilité des Données
            Personnelles vous concernant et à la limitation des traitements, ainsi qu’au sort de ces mêmes Données après la mort. Ces droits peuvent être
            exercés en s’adressant à <u>contact@tech4work.fr</u>
            <br/>
            <br/>
            Si vous estimez que les efforts mis en œuvre par T4W pour préserver la confidentialité des Données sont insuffisants, vous avez la possibilité
            d’introduire une réclamation auprès de l’autorité de contrôle compétente (CNIL ou toute autre autorité mentionnée sur la liste disponible auprès de
            la Commission européenne).
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Utilisation des Données Personnelles par T4W</strong>
            </Typography>
          </u>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.1 Finalité de traitement</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données Personnelles collectées et traitées par T4W incluent non seulement des données administratives vous concernant (principalement,
            civilité, nom, prénom, adresse postale, courriel) mais également des données (notamment d’identification) des Bénéficiaires.
            <br/>
            <br/>
            Au travers du Site, T4W recueille et traite uniquement les Données Personnelles nécessaires à la poursuite des finalités suivantes : fournir les
            services liés à l’Application (c’est-à-dire assurer l’accompagnement social et professionnel des Bénéficiaires pour le retour et le maintien dans
            l’emploi), améliorer lesdits services, assurer le service client, détecter et prévenir les tentatives de fraudes.
            <br/>
            <br/>
            Lors de la collecte des Données Personnelles, les informations essentielles à la réalisation de ces finalités et indispensables à la fourniture des
            services sont indiquées au moyen d’une astérisque.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.2 Base légale de traitement</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données Personnelles traitées pour les finalités ci-dessus exposées reposent sur les bases légales suivantes :
            <ul className={classes.listHyphen}>
              <li>fournir les services liées à l’Application : Conclusion et exécution du contrat / consentement ;</li>
              <li>améliorer les services : intérêt légitime ;</li>
              <li>
                assurer le service client : Conclusion et exécution du contrat, intérêt légitime (gestion de la qualité de la relation client) et obligation
                légale (demande d’exercice des droits) ;
              </li>
              <li>détecter et prévenir les tentatives de fraudes : intérêt légitime.</li>
            </ul>
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.3 Conservation des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données sont conservées pendant une durée qui n’excède pas la durée nécessaire aux finalités qui justifient leur traitement. Nous conservons les
            données personnelles a minima pour la durée légale prescrite par les textes lorsque celle-ci est précisée. Lorsque nos intérêts légitimes exigent
            une durée de conservation différente, il sera tenu compte du caractère sensible des données, du risque potentiel et des exigences en matière de
            sécurité nécessaire pour ces données afin d’établir la durée de conservation.
            <br/>
            <br/>
            Par ailleurs, T4W pourra être amenée à conserver des données afin de respecter des exigences légales, au titre de la prévention de la fraude ou dans
            le cadre de la résolution de litiges, par exemple.
            <br/>
            <br/>
            Lorsque la conservation des Données Personnelles n’est plus nécessaire à l’exécution des finalités pour lesquelles elles sont conservées, T4W
            procèdera à la suppression desdites Données Personnelles.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.4 Destinataires des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Les Données Personnelles vous concernant sont réservées à T4W.
            <br/>
            <br/>
            Les Données Personnelles du Bénéficiaire sont collectées uniquement au bénéfice de ce dernier, ainsi qu’aux professionnels et organismes désignés
            par celui-ci. Le Bénéficiaire garde en toutes circonstances la maitrise de qui accède à ses Données Personnelles.TW4 est susceptible d’accéder aux
            données administratives du Bénéficiaire uniquement à des fins de bonne administration /gestion de son compte.
            <br/>
            <br/>
            Les Données Personnelles recueillies pourront toutefois être communiquées à des tiers liés par contrat à T4W, pour l’exécution de prestations
            sous-traitées et/ou nécessaires à l’exécution des services disponibles sur l’Application. Avant tout transfert d’informations, nous vérifierons la
            conformité de la finalité de chaque demande et garantissons la transmission des éléments strictement nécessaires à l’exécution des services par le
            prestataire.
            <br/>
            <br/>
            En aucun cas, T4W ne cède, vend ou loue les Données Personnelles collectées à des tiers, que ce soit à titre onéreux ou gratuit.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.5 Hébergement / transfert des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            L’Application et les Données Personnelles traitées dans le cadre de son utilisation sont hébergées auprès d’un hébergeur situé sur le territoire de
            l’Union européenne.
            <br/>
            <br/>
            L’hébergeur est la société :<br/>
            <ul>
              <li style={{ listStyle: 'none' }}>
                Google Cloud Platform (GCP), région « eur3 (europe-west) » (correspondant à europe-west1 Belgique et europe-west4 Pays Bas sur les hébergements
                GCP)
              </li>
            </ul>
            <br/>
            <br/>
            Aucun transfert de données ne sera effectué sans votre accord préalable et exprès, et/ou celui du Bénéficiaire.
          </Typography>
          <br/>
          <Typography variant="body2" component="div">
            <strong>3.6 Sécurité et confidentialité des Données</strong>
          </Typography>
          <Typography variant="body2" component="div">
            T4W a fait le nécessaire pour mettre en place toutes les précautions utiles pour assurer la sécurité et la confidentialité des Données Personnelles
            traitées et empêcher qu’elles ne soient déformées endommagées, détruites ou qu’un tiers non autorisé y ait accès. L’ensemble des moyens de sécurité
            renforcés mis en place sont conformes à l’état de l’art, notamment pour ce qui relève des systèmes d’information.
            <br/>
            <br/>
            Dans la mesure où T4W ne maîtrise pas tous les risques liés au fonctionnement d’Internet, elle attire l’attention des visiteurs sur l’existence
            d’éventuels risques inhérents à son utilisation et à son fonctionnement.
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Modification des termes de la Politique de confidentialité</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            Nous sommes susceptibles de modifier la présente Politique de Confidentialité. Les nouveaux termes prendront effet dans le mois suivant la
            publication. Le cas échéant, toute modification importante pourra vous être notifiée par e-mail.
            <br/>
            <br/>
            T4W vous encourage à consulter la Politique de Confidentialité chaque fois que vous utilisez l’Application, afin de rester informé des pratiques
            mises en place en matière d’information et des moyens que vous pouvez utiliser pour protéger votre vie privée.
          </Typography>
        </li>
        <li>
          <u>
            <Typography variant="body1">
              <strong>Loi et Juridiction applicable</strong>
            </Typography>
          </u>
          <Typography variant="body2" component="div">
            La présente Politique de Confidentialité est régie par la loi française et tout litige à son sujet relèvera de la compétence des Tribunaux
            territorialement compétents en application des règles édictées par le code de procédure civile français.
          </Typography>
        </li>
      </ol>
    </Paper>
  )
}

export const PrivacyPolicies: FC = () => {
  const { type } = useParams<{ type: PrivacyPoliciesUserType }>()
  const history = useHistory()
  const classes = useStyles()

  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      {type === 'beneficiaire' ? <PrivacyPoliciesBeneficiary /> : <PrivacyPoliciesConsultant />}
      <DefaultButton variant="contained" onClick={() => history.goBack()} label="retour"/>
    </Grid>
  )
}
