import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const appConfig = {
  firebase: {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  },
}

const Firebase = firebase.initializeApp(appConfig.firebase)

export const auth = firebase.auth()
export const db = firebase.firestore()
db.settings({ ignoreUndefinedProperties: true })
export default Firebase
