import React, { FC, useContext, useEffect, useState } from 'react'
import { Box, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { DefaultButton } from '../atoms/DefaultButton'
import AddIcon from '@material-ui/icons/Add'
import { ACTION_STATUS, BREAKPOINTS, COLUMNS_ACTIONS_LIST } from '../utils/constants'
import { ActionFormModal } from './ActionFormModal'
import { useStateMachine } from 'little-state-machine'
import { updateActions, updateSelectedAction } from '../stores/store'
import { fetchActionHistory, fetchActions } from '../stores/fbAction'
import { Action, ModalType } from '../utils/type'
import { Message } from '@material-ui/icons'
import { initSelectedAction } from '../stores/initStore'
import { ALL_DEVICE } from '../utils/actionConstants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AuthContext } from '../utils/hooks'
import clsx from 'clsx'

export const ActionList: FC = () => {
  const classes = useStyles()
  const authentication = useContext(AuthContext)
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const [ actionRows, setActionRows ] = useState([])
  const [ open, setOpen ] = useState(false)
  const [ modalType, setModalType ] = useState<ModalType>('add')
  const { state, actions } = useStateMachine({ updateActions, updateSelectedAction })

  useEffect(() => {
    fetchActions(state.selectedBeneficiary.id || authentication?.uid).then((result) => {
      actions.updateActions(result!)
    })
  }, [ state.selectedAction ])

  useEffect(() => {
    if (Array.isArray(state.actions) && state.actions.length) {
      const rows: any = []
      state.actions.forEach((value) => {
        const status = ACTION_STATUS.find((l) => l.key === value.status)?.label
        const deviceType = ALL_DEVICE.find((l) => l.key === value.deviceType)?.label
        rows.push({
          id: value.id,
          deviceTypeCode: value.deviceType,
          deviceType,
          statusCode: value.status,
          status,
          createdDate: value.createdDate,
          consultant: value.consultant,
          organism: value.organism,
          updateDate: value.updateDate ? value.updateDate : '-',
          preview: (
            <IconButton onClick={() => handleUpdateSelectedAction(value)}>
              <Message />
            </IconButton>
          ),
        })
      })
      setActionRows(rows)
    }
  }, [ state.actions ])

  const handleAddAction = () => {
    setModalType('add')
    setOpen(true)
  }

  const handleUpdateSelectedAction = async(row: Action) => {
    const actionHistory: any = await fetchActionHistory(state.selectedBeneficiary.id || authentication?.uid, row.id)
    actions.updateSelectedAction({ ...row, history: actionHistory })
    setModalType(state.profile.isConsultant ? 'preview' : 'read-only')
    setOpen(true)
  }

  const handleCloseModal = () => {
    actions.updateSelectedAction(initSelectedAction)
    setOpen(false)
  }

  return (
    <>
      {open && <ActionFormModal modalType={modalType} setModalType={setModalType} handleClose={handleCloseModal} open={open} />}
      <Grid item className={classes.containerTitle}>
        <Typography variant={mobile ? 'h5' : 'h4'} className={classes.mainTitle}>
          {state.profile.isConsultant ? content.title : content.titleJourney}
        </Typography>
      </Grid>
      {state.profile.isConsultant &&
      <Paper className={clsx(classes.paperHead, tablet && classes.fixedDirection)}>
        <Typography variant='body1' className={classes.title}>
          <span className={classes.bold}>{content.fullName}</span>
          {state.selectedBeneficiary.firstname.charAt(0).toUpperCase() +
          state.selectedBeneficiary.firstname.slice(1)} {state.selectedBeneficiary.lastname.toUpperCase()}
        </Typography>
        <Box
          component='div'
          display='flex'
          textAlign={tablet ? 'center' : 'start'}
          flexDirection={tablet ? 'column' : 'row'}
        >
          <Typography variant='body1'>
            <span className={classes.bold}>{content.birthday}</span>
            {state.selectedBeneficiary.birthday}
          </Typography>
          <Typography variant='body1'>
            <span className={clsx(classes.bold, !tablet && classes.fixedMarginLeft)}>{content.statusBOE}</span>
            {state.selectedBeneficiary.isBOE}
          </Typography>
          <Typography variant='body1'>
            <span className={clsx(classes.bold, !tablet && classes.fixedMarginLeft)}>{content.typeWorkArrest}</span>
            {state.selectedBeneficiary.isWorkArrest}
          </Typography>
        </Box>
      </Paper>}
      <Grid container justifyContent='center' alignItems='center' className={classes.containerMain}>
        <Paper className={clsx(classes.paper, tablet ? mobile ? classes.fixedHeightMobile : classes.fixedHeightTablet : classes.fixedHeight)}>
          <Grid container spacing={2}>
            <Grid container item xs={12} alignItems='center' justifyContent={tablet ? 'center' : 'space-between'}>
              <Typography variant={mobile ? 'h6' : 'h5'} className={clsx(classes.title, tablet && classes.alignCenter)}>
                {content.course}
              </Typography>
              {
                state.profile.isConsultant && (
                  <DefaultButton icon={<AddIcon />} label={mobile ? content.actionMobile : content.addAction} onClick={handleAddAction} />
                )
              }
            </Grid>
            <Grid container item xs={12} zeroMinWidth>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {COLUMNS_ACTIONS_LIST.map((column) => (
                        <TableCell key={column.field} align={column.align} style={{ minWidth: column.width }}>
                          {column.description}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {actionRows.map((row: any) => {
                      return (
                        <TableRow hover key={row.id}>
                          {COLUMNS_ACTIONS_LIST.map((column) => {
                            const value = row[ column.field ]
                            return (
                              <TableCell key={column.field} align={column.align}>
                                {
                                  value.seconds ?
                                    new Date((value as any).seconds * 1000).toLocaleDateString() :
                                    value
                                }
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

const content = {
  title: 'Fiche Bénéficiaire',
  fullName: 'Nom complet: ',
  titleJourney: 'Mon parcours',
  course: 'Parcours en cours',
  addAction: 'Ajouter une action',
  actionMobile: 'Action',
  birthday: 'Date de naissance: ',
  statusBOE: ' Situation: ',
  typeWorkArrest: ' Arrêt: ',
}

const useStyles = makeStyles({
  containerTitle: {
    width: '100%',
    paddingLeft: '1rem',
    alignSelf: 'flex-start',
  },
  mainTitle: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '0.2rem',
  },
  paperHead: {
    padding: '1rem',
    marginBottom: '2rem',
    width: '100%',
    height: 'auto',
  },
  fixedDirection: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  fixedMarginLeft: {
    marginLeft: '1rem',
  },
  alignCenter: {
    textAlign: 'center',
  },
  paper: {
    padding: '1rem',
    width: '100%',
    overflow: 'auto',
  },
  fixedHeight: {
    height: '70vh',
  },
  fixedHeightTablet: {
    height: '67vh',
  },
  fixedHeightMobile: {
    height: '61vh',
  },
  containerMain: {
    height: '73%',
    paddingBottom: '3rem',
  },
})
