import React, { FC } from 'react'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { DefaultButton } from '../atoms/DefaultButton'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'
import clsx from 'clsx'

const useStyles = makeStyles({
  container: {
    padding: '1.5rem 1.5rem 3.5rem 1.5rem',
    height: '100vh',
  },
  paperMain: {
    padding: '1rem 2.3rem 1rem 2.3rem',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  fixedPadding: {
    padding: '1rem',
  },
  list: {
    '& li:not(:first-child)': {
      margin: '2rem 0',
    },
    '& li *': {
      margin: '.5rem 0',
    },
  },
  listHyphen: {
    '& li': {
      listStyleType: '"- "',
    },
  },
  title: {
    fontWeight: 'bold',
  },
fixedMarginBottom: {
    marginBottom: '2rem',
  },
})

const content = {
  title: 'Conditions générales d\'utilisation SAM.I',
}

export const CGU: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)

  return (
    <Grid container justifyContent='center' alignItems='center' className={classes.container}>
      <Paper className={clsx(classes.paperMain, mobile && classes.fixedPadding)}>
        <div>
          <Typography variant={mobile ? 'h5' : 'h4'} className={classes.title}>
            {content.title}
          </Typography>
          <Typography variant='subtitle2' className={clsx(classes.title, classes.fixedMarginBottom)}>
            Dernière modification: 31/12/2021
          </Typography>
        </div>
        <Typography paragraph>
          Bienvenue sur la plateforme SAM.I, un service créé et développé TECH FOR WORK, société par actions simplifiée ayant son siège 2 rue Danton, 77330
          Ozoir-la-Ferrière et enregistrée sous le numéro SIREN 899 652 481 au RCS de Melun.
        </Typography>

        <Typography variant='body2' component='div'>
          Ces présentes conditions générales d&apos;utilisation (ci-après <strong>«CGU»</strong>) ont vocation à régir votre utilisation de la plateforme SAM.I
          (ci-après «<strong>SAM.I</strong>» ou la «<strong>Plateforme</strong>» disponible sur le site internet suivant:<br />
          <a href='https://sami.tech4work.fr/connexion' target='_blank' rel='noreferrer'>
            https://sami.tech4work.fr/connexion
          </a>
        </Typography>
        <Typography variant='body2' component='div'>
          Toute utilisation de SAM.I et de ses Services implique l’adhésion sans réserve aux présentes CGU. Ces CGU s’appliquent sans préjudice des dispositions
          légales applicables en la matière et notamment celles prévues au Code de la consommation.
        </Typography>
        <Typography variant='body2' component='div'>
          Vous pouvez également consulter notre Politique de confidentialité pour plus d’informations sur la manière dont nous collectons et utilisons les
          données relatives à l’utilisation de SAM.I, ainsi que nos responsabilités et vos droits relatifs au traitement de vos données à caractère personnel.
        </Typography>
        <div>
          <Typography variant='body2' component='div'>
            <strong>Préambule :</strong>
          </Typography>
          <ol className={classes.list}>
            <li>
              <Typography variant='body2'>
                <strong>Définitions</strong>
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Action :</strong> Désigne tout action d&apos;un Consultant sur la fiche de suivi d&apos;un Bénéficiaire permettant d&apos;avoir des
                informations sur son parcours.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Dispositif :</strong> Désigne la nature et le type de droit commun ou spécifique que le consultant renseignera sur la fiche du
                bénéficiaire.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Bénéficiaire :</strong> Désigne tout Utilisateur inscrit sur SAM.I et disposant d&apos;une fiche de suivi contenant ses informations
                personnelles et les différentes actions de son parcours.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Compte :</strong> Désigne le compte personnel d&apos;un Utilisateur créé à partir des informations personnelles qu&apos;il consent de
                transmettre à la Plateforme et auquel il peut accéder grâce à ses identifiants personnels.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Consultant :</strong> Désigne tout Utilisateur ayant la capacité de suivre le parcours d’un Bénéficiaire et d’ajouter des actions sur sa
                fiche de suivi.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Donnée à caractère personnel :</strong> Désigne toute information concernant une personne physique identifiée ou identifiable telle que
                définie par le Règlement Général sur la Protection des Données (RGPD).
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Fiche de suivi :</strong> Désigne l’ensemble des informations personnelles et de parcours attachés au compte d’un Bénéficiaire. La fiche
                de suivi permet au Consultant de connaître la situation du Bénéficiaire à une date donnée.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Services :</strong> Désigne la ou les fonctionnalités mises à disposition des Utilisateurs.
              </Typography>
              <Typography variant='body2' component='div'>
                <strong>Utilisateur :</strong> Désigne toute personne parcourant la Plateforme et/ou créant un compte personnel sur la Plateforme.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                <strong>Acceptation des présentes CGU</strong>
              </Typography>
              <Typography variant='body2' component='div'>
                En créant son compte personnel sur la Plateforme SAM.I, en cochant la case « Je certifie avoir lu et accepté sans réserve les CGU de [la
                Plateforme] », l’Utilisateur déclare et garantit avoir lu et accepté les présentes CGU et s’engage à les respecter.
                <br />
                L’utilisation de SAM.I est réservée aux personnes physiques âgées de 18 ans ou plus disposant de la capacité juridique pleine et entière. Toute
                inscription sur SAM.I par une personne mineure est strictement interdite. En accédant, utilisant ou vous inscrivant sur la Plateforme, vous
                déclarez et garantissez avoir 18 ans ou plus et être juridiquement capable.
                <br />
                Les présentes CGU peuvent être modifiées et remplacées à tout moment. La date de la dernière modification figure à la fin des présentes CGU.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                <strong>Présentation de la Plateforme</strong>
              </Typography>
              <Typography variant='body2' component='div'>
                En vertu de l’article 6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux Utilisateurs
                l’identité des différents intervenants dans le cadre de la réalisation de SAM.I et de son suivi :<br />
                &nbsp;Propriétaire : TECH FOR WORK &nbsp;Responsable de publication : TECH FOR WORK &nbsp;Webmaster : TECH FOR WORK &nbsp;Hébergeur : Google
                Cloud Platform (GCP), région « eur3 (europe-west) » (correspondant à europe-west1 Belgique et europe-west4 Pays Bas)
                <br />
                SAM.I est un service collaboratif de mise en relation entre un Bénéficiaire et ses Consultants afin de simplifier le retour dans l’emploi.
                <br />
                Il est précisé aux Utilisateurs que la Plateforme ne doit contenir aucune information soumise au secret médical. Le Consultant engage son
                entière responsabilité s&#39;il contraint cette règle.
                <br />À tout moment, de nouveaux Services, ressources et fonctionnalités peuvent être ajoutés sur la Plateforme ou de nouvelles versions de
                la Plateforme publiées. Les nouveaux Services seront régis par les présentes CGU ainsi que toute condition complémentaire que la Plateforme est
                susceptible de publier au regard de ces Services.
              </Typography>
            </li>
          </ol>
        </div>
        <div>
          <Typography variant='h6' component='div'>
            Article 1. Parcours Bénéficiaire
          </Typography>
          <Typography variant='body2' component='div'>
            L’Utilisateur ne peut accéder aux Services de SAM.I qu’après avoir créé un Compte.
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>1.1 Création d&apos;un compte Bénéficiaire</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Pour la création d’un Compte Bénéficiaire, l’Utilisateur doit fournir au moment de son inscription :<br />
            <ul className={classes.listHyphen}>
              <li>son adresse mail,</li>
              <li>son mot de passe,</li>
              <li>son nom et prénom,</li>
              <li>sa date de naissance,</li>
              <li>son adresse,</li>
              <li>son numéro de téléphone (facultatif),</li>
              <li>son statut,</li>
              <li>son type de contrat de travail et le poste occupé,</li>
              <li>son code APE,</li>
              <li>son domaine et son niveau de formation,</li>
              <li>sa langue et son niveau de langue,</li>
              <li>s’il est bénéficiaire de l’obligation emploi,</li>
              <li>s’il est en arrêt de travail ; le cas échéant depuis combien de temps et quel type d’arrêt de travail</li>
            </ul>
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>1.2 Fiche de suivi du Bénéficiaire</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Immédiatement après la création de son compte, le Bénéficiaire dispose d’une fiche de suivi contenant les informations sur sa situation
            professionnelle qu’il a transmis.
            <br />
            Cette fiche de suivi ne pourra être visualisée par un Consultant, qu’après autorisation exprès du Bénéficiaire. Aucun autre Utilisateur ne peut
            visualiser ces informations personnelles.
            <br />
            La fiche de suivi du Bénéficiaire permet à des Consultants indépendants ou salariés d’entreprises et organismes sociaux d’avoir un accès centralisé
            aux informations relatives à son statut administratif et à son parcours professionnel.
            <br />
            Le Bénéficiaire peut envoyer une invitation à un Consultant afin (i) de lui donner accès à sa Fiche de suivi ou (ii) de lui demander de s’inscrire
            sur la Plateforme si ce n’est pas déjà le cas.
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>1.3 Suppression de son compte Bénéficiaire</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Le Bénéficiaire peut, à tout moment, supprimer son compte en cliquant sur la rubrique <i>« Supprimer mon compte »</i> de son profil.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 2. Parcours Consultant
          </Typography>
          <Typography variant='body2' component='div'>
            Attention, cette fonctionnalité n’est accessible seulement pour les Utilisateurs justifiant de la capacité d’être Consultant, à savoir être salarié
            d’une entreprise ou d’un organisme d’accompagnement social et professionnel, adhérent à la Plateforme SAM.I.{' '}
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>2.1 Compte Consultant</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Pour la création d’un Compte Consultant, l’Utilisateur doit fournir au moment de son inscription :<br />
            <ul className={classes.listHyphen}>
              <li>une adresse email,</li>
              <li>un mot de passe,</li>
              <li>son nom et prénom,</li>
              <li>son adresse,</li>
              <li>son numéro de téléphone (facultatif),</li>
              <li>son entreprise/organisme,</li>
              <li>son poste,</li>
            </ul>
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>2.2 Accès à la fiche du suivi du Bénéficiaire par un Consultant</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Le Consultant doit, pour avoir accès à la fiche de suivi d’un Bénéficiaire, soit :
            <ol>
              <li style={{ listStyleType: 'lower-alpha' }}>
                si le Bénéficiaire est déjà inscrit sur SAM.I, avoir envoyé une invitation d’accès au Bénéficiaire, ou{' '}
              </li>
              <li style={{ listStyleType: 'lower-alpha' }}>
                si le Bénéficiaire n’est pas déjà inscrit sur SAM.I, avoir envoyé, par mail depuis la Plateforme, au Bénéficiaire une demande de création de
                compte en cliquant sur « Envoyer une demande d’inscription ».
              </li>
            </ol>
            <br />
            Le Bénéficiaire invité par un Consultant à créer un Compte sur SAM.I reçoit sur sa boite mail un lien d’inscription le renvoyant vers la Plateforme
            pour créer un compte Bénéficiaire.
            <br />
            <br />
            Cette demande d’accès à la fiche de suivi du Bénéficiaire par le Consultant reste en attente jusqu’à l’autorisation d’accès du Bénéficiaire.
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>2.3 Ajout et modification d’actions</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Le Consultant peut, une fois que le Bénéficiaire lui a autorisé l’accès à sa fiche de suivi, ajouter une action à effectuer sur sa fiche de suivi
            telle que par exemple, un bilan de compétences à effectuer, un essai encadré, une visite de pré-reprise etc.
            <br />
            <br />
            Le Consultant peut également ajouter une description détaillée de l’action qu’il enregistre sur la fiche Bénéficiaire.
            <br />
            <br />
            Attention, toutefois, le champ de description de l’action ne doit contenir aucune information soumise au secret médical. Le Consultant engage son
            entière responsabilité s’il contraint cette règle.
            <br />
            <br />
            Le statut d’une action peut être modifié à tout moment. La date de la dernière modification est indiquée pour permettre une traçabilité des
            modifications apportées par le Consultant sur la fiche de suivi du Bénéficiaire.
            <br />
            <br />
            Le statut de l’action est choisi par le Consultant entre « A réaliser », « En cours », ou « Réalisée ».
            <br />
            <br />
            Le Consultant doit mettre à jour la fiche du Bénéficiaire dès qu’une action est créée ou lors de la modification du statut d’une action.
            <br />
            <br />
            Le Consultant peut consulter la liste de ses Bénéficiaires dans l’onglet « Mes Bénéficiaires ».
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>2.4 Suppression du compte Consultant</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Le Consultant peut, à tout moment, supprimer son compte en cliquant sur la rubrique « Supprimer mon compte » de son profil.
            <br />
            <br />
            Le compte Consultant sera automatiquement supprimé par TECH FOR WORK au jour où le Consultant quitte l’entreprise ou l’organisme social adhérent à
            la Plateforme, supprimant ainsi tous les accès du Consultant à la Plateforme.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 3. Engagements de l’Utilisateur
          </Typography>
          <Typography variant='body2' component='div'>
            Dans tous les cas, l’Utilisateur doit s’assurer que l&apos;adresse email qu’il renseigne est valide. Cette adresse et toutes les autres informations
            qu’il choisit de communiquer à la Plateforme à son sujet seront traitées conformément à la Politique de confidentialité de la Plateforme disponible
            sur le site. (lien).
            <br />
            <br />
            L’Utilisateur s&apos;engage notamment à ne pas créer une fausse identité de nature à induire TECH FOR WORK, les autres Utilisateurs ou les tiers en
            erreur et à ne pas usurper l&apos;identité d&apos;une autre personne. L&apos;Utilisateur s&apos;engage à mettre immédiatement à jour, en cas de
            modification, les données qu&apos;il a communiquées lors de sa première connexion sur son Compte.
            <br />
            <br />
            Dans l&apos;hypothèse où l&apos;Utilisateur fournirait des informations fausses, inexactes, obsolètes, incomplètes ou de nature à induire en erreur,
            TECH FOR WORK pourra, immédiatement sans préavis ni indemnité, suspendre l’accès au Compte et lui refuser l&apos;accès, de façon temporaire ou
            définitive à tout ou partie des Services.
            <br />
            <br />
            Il incombe à l’Utilisateur de garder son mot de passe confidentiel et en sécurité. Il sera tenu responsable de toute utilisation de son mot de passe
            ainsi que de toute activité émanant de son Compte, que cette activité ait été ou non autorisée par ce dernier. L’Utilisateur devra informer
            immédiatement TECH FOR WORK de toute utilisation non autorisée de son Compte, de son identifiant et mot de passe.
            <br />
            <br />
            L’Utilisateur peut modifier son mot de passe communiqué à partir de son Compte dans la rubrique [« Mot de passe oublié »] et modifier à tout moment
            les informations de son profil en cliquant sur la rubrique « Modifier mon profil ».
            <br />
            <br />
            TECH FOR WORK se réserve le droit de suspendre ou résilier un Compte si, sur ce Compte, interviennent certaines activités qui, à son entière
            discrétion, semblent constituer, ou pourraient constituer une violation des présentes CGU, ou encore une atteinte aux droits des tiers ou de toute
            législation et réglementation en vigueur.
            <br />
            <br />
            L’Utilisateur s’engage de manière générale à : (i) utiliser les Services dans le respect des lois et règlements applicable et respecter les
            présentes CGU ; (ii) ne pas utiliser les Services d’une façon qui serait susceptible de nuire à la réputation de TECH FOR WORK ; (iii) à ce que les
            Données qu’il communique dans le cadre de l’utilisation des Services ne portent pas préjudice aux droits des tiers et à être autorisé à les
            diffuser.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 4. Responsabilité et garanties de TECH FOR WORK
          </Typography>
          <Typography variant='body2' component='div'>
            TECH FOR WORK met en place les moyens et les mesures nécessaires à la bonne marche et au maintien de la continuité et de la qualité de ses Services.
            <br />
            <br />
            L’Utilisateur reconnaît que le rôle de TECH FOR WORK, à travers la Plateforme SAM.I, se limite à celui d’un simple intermédiaire et prestataire
            technique.
            <br />
            <br />
            L’Utilisateur reconnaît que TECH FOR WORK ne peut être tenue responsable d’une interruption des Services ou d’un retard d’exécution ne relevant pas
            de son contrôle et, en particulier, que la fourniture des Services dépend de la fiabilité, de la disponibilité et de la continuité de connexions
            d’un certain nombre de parties tierces (exploitants du réseau des télécommunications, l’Internet public, les équipements de l’Utilisateur, etc.)
            ainsi que de l’exactitude et de l’intégrité des données fournies par l’Utilisateur.
            <br />
            <br />
            TECH FOR WORK pourra être amenée à suspendre les Services à des fins de maintenance.
            <br />
            <br />
            Il est entendu entre les Parties que TECH FOR WORK n’est en aucun cas tenu responsable de réparer d’éventuels dommages indirects subis par les
            Utilisateurs à l’occasion de l’utilisation de la Plateforme et de ses Services.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 5. Propriété intellectuelle
          </Typography>
          <Typography variant='body2' component='div'>
            La structure générale de la Plateforme et tout autre élément la composant (tels que la charte graphique, le nom de domaine, le logo etc) sont la
            propriété exclusive de TECH FOR WORK.
            <br />
            <br />
            Toute reproduction et représentation, totale ou partielle de la Plateforme et/ou des éléments les composant par quelque procédé que ce soit, sans
            l’autorisation expresse de TECH FOR WORK sont dès lors interdites et constitueraient une contrefaçon sanctionnée par les articles L. 335-2 et
            suivants du Code de la propriété intellectuelle.
            <br />
            <br />
            En particulier, l’Utilisateur s’engage à ne pas :
            <ol>
              <li style={{ listStyle: 'inside lower-roman' }}>tenter d’accéder ou copier les codes sources de la Plateforme ;</li>
              <li style={{ listStyle: 'inside lower-roman' }}>utiliser la Plateforme à d’autres fins que celles d’utiliser les Services ;</li>
              <li style={{ listStyle: 'inside lower-roman' }}>créer des copies de la Plateforme ;</li>
              <li style={{ listStyle: 'inside lower-roman' }}>
                reproduire, corriger, extraire, modifier, traduire en toutes langues ou tous langages, réutiliser, arranger, adapter, décompiler (à l’exception
                et dans la seule mesure où la loi applicable l’autorise de manière expresse), ni incorporer la Plateforme dans un autre logiciel ou créer des
                travaux dérivés sur la base de la Plateforme quels qu’en soient le moyen et le support ;{' '}
              </li>
              <li style={{ listStyle: 'inside lower-roman' }}>faire des tests d’intrusion ou tenter d’obtenir un déni de service sur les Services.</li>
            </ol>
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 6. Suppression d’un compte
          </Typography>
          <Typography variant='body2' component='div'>
            <strong>
              <i>6.1 Suppression par TECH FOR WORK</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            En cas de faute grave de l’Utilisateur, telle que l’utilisation d’un ou plusieurs Services (a) contraire aux stipulations des présentes CGU, (b)
            contraire aux lois et réglementations applicables (c), susceptible de porter préjudice à la réputation de TECH FOR WORK ou à l’intégrité physique ou
            mentale d’un autre Utilisateur, TECH FOR WORK se réserve le droit de résilier, par tout moyen, tout ou partie des Services avec un préavis de sept
            (7) jours sans indemnité.
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>6.2 Suppression par l’Utilisateur</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            L’Utilisateur peut résilier les présentes CGU à tout moment en supprimant son Compte en cliquant sur la rubrique « Supprimer mon compte » de son
            profil.
          </Typography>
          <br />
          <Typography variant='body2' component='div'>
            <strong>
              <i>6.3 Conséquences de la suppression</i>
            </strong>
          </Typography>
          <Typography variant='body2' component='div'>
            Toute suppression d’un Compte par TECH FOR WORK ou l’Utilisateur entraîne automatiquement (i) la fin du droit d’accès à la Plateforme et ses
            Services par l’Utilisateur, (ii) la suppression ou l’anonymisation de toutes ses Données à caractère personnel.
            <br />
            <br />
            TECH FOR WORK est néanmoins autorisée à conserver une copie de ces données pour toutes fins imposées par la loi applicable.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 7. Protection des données à caractère personnel
          </Typography>
          <Typography variant='body2' component='div'>
            La politique de protection des Données à caractère personnel disponible sur [insérer lien] décrit les rôles et les obligations respectifs de
            l’Utilisateur et de TECH FOR WORK concernant le traitement des Données à caractère personnel dans le cadre de l’exploitation et l’utilisation de de
            la plateforme SAM.I. En acceptant les présentes CGU, l’Utilisateur et TECH FOR WORK s’engagent à respecter les termes et conditions de ladite
            politique de protection des données à caractère personnel.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 8. Force Majeure
          </Typography>
          <Typography variant='body2' component='div'>
            Sont considérés comme des cas de force majeure, les situations dont les éléments constitutifs sont caractérisés au sens de l’article 1218 du Code
            civil et de la jurisprudence de la Cour de cassation. En cas de survenance d’un tel évènement, la partie victime devra en informer immédiatement par
            écrit l’autre Partie de leur survenance ainsi que de leur disparition.
            <br />
            <br />
            Le cas de force majeure suspend les obligations de la Partie concernée pendant le temps où jouera la force majeure. Néanmoins, les Parties
            s’efforceront d’en minimiser dans toute la mesure du possible les conséquences.
          </Typography>
        </div>
        <div>
          <Typography variant='h6' component='div' style={{ marginTop: '1rem' }}>
            Article 9. Modalités de résolution des litiges
          </Typography>
          <Typography variant='body2' component='div'>
            Les présentes CGU sont régies par le droit français.
            <br />
            Toute réclamation relative à l’utilisation des Services et/ou à l’exécution des présentes CGU pourra être adressée à l’adresse suivante:{' '}
            <u>support@tech4work.fr</u> afin qu’elle puisse être traitée dans les meilleurs délais.<br />
            <br />
            En cas d’échec des tentatives de résolution amiable, toutes contestations à la validité, l’interprétation et/ou l’exécution des présentes CGU
            devront être portées même en cas de pluralité des défendeurs ou d’appel en garantie, devant les tribunaux compétents.
          </Typography>
        </div>
      </Paper>
      <DefaultButton variant='contained' onClick={() => history.goBack()} label='retour' />
    </Grid>
  )
}
