import React, { FC, useState } from 'react'
import { Grid, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface InputFieldProps {
  control: Control<any> | undefined
  error: FieldErrors
  name: string
  label: string
  defaultValue?: string
  placeholder?: string
  styleProps?: string
  isPassword?: boolean
  numberOfLigne?: number
  disabled?: boolean
  helperText?: string
  widthInnerContainer?: string
}

export const InputField: FC<InputFieldProps> = ({
  control,
  error,
  name,
  label,
  defaultValue,
  placeholder,
  styleProps,
  isPassword,
  numberOfLigne,
  disabled,
  helperText,
  widthInnerContainer,
}: InputFieldProps) => {
  const classes = useStyles()
  const [ hide, setHide ] = useState(isPassword)
  const isMultiline = !!(
    numberOfLigne && numberOfLigne > 1
  )
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <Grid item style={{ width: widthInnerContainer }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            inputRef={ref}
            disabled={disabled}
            multiline={isMultiline}
            rows={numberOfLigne}
            label={label}
            value={value}
            onBlur={onBlur}
            error={!!error[ name ]}
            onChange={(value) => onChange(value)}
            variant='outlined'
            margin='normal'
            placeholder={placeholder}
            type={hide ? 'password' : 'text'}
            className={clsx(classes.input, styleProps, tablet ? classes.inputWidthMobile : classes.inputWidth)}
            helperText={(error[ name ] && error[ name ]?.message) || helperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isPassword ? (
                    <IconButton onClick={() => setHide(!hide)} edge='end'>
                      {hide ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ) : (
                    ''
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Grid>
  )
}

InputField.defaultProps = {
  defaultValue: '',
  numberOfLigne: 1,
}

const useStyles = makeStyles({
  input: {
    minWidth: '18rem',
    marginTop: '1.3rem',
    '& label.Mui-focused': {
      color: COLORS.cyanForte,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.cyanForte,
      },
    },
  },
  inputWidth: {
    width: '32.3vw',
  },
  inputWidthMobile: {
    width: '70vw',
  },
})
