import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { DefaultButton } from '../atoms/DefaultButton'
import { useStateMachine } from 'little-state-machine'
import { BREAKPOINTS, COLUMNS_BENEFICIARIES_LIST } from '../utils/constants'
import { loadBeneficiaries } from '../stores/fbBeneficiary'
import { updateBeneficiariesProfiles, updateSelectedBeneficiary } from '../stores/store'
import { BeneficiariesRows } from '../utils/type'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const BeneficiaryList: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const [ beneficiariesRows, setBeneficiariesRows ] = useState<BeneficiariesRows[]>([])
  const { state, actions } = useStateMachine({ updateBeneficiariesProfiles, updateSelectedBeneficiary })

  useEffect(() => {
    loadBeneficiaries(state.consultant.followedBeneficiaries).then((result) => {
      actions.updateBeneficiariesProfiles(result!)
    })
  }, [ state.consultant.followedBeneficiaries ])

  useEffect(() => {
    if (Array.isArray(state.beneficiariesProfiles) && state.beneficiariesProfiles.length) {
      const rows: BeneficiariesRows[] = []
      state.beneficiariesProfiles.forEach((value) => {
        const date = value.profile?.birthday ? new Date(value.profile.birthday).toLocaleDateString() : ''
        rows.push({
          id: value.id,
          firstname: value.profile?.firstname!,
          lastname: value.profile?.lastname!,
          birthday: date,
          isBOE: value.beneficiary?.isBOE ? 'BOETH' : 'Non BOETH',
          isWorkArrest: value.beneficiary?.isWorkArrest ? 'Oui' : 'Non',
        })
      })
      setBeneficiariesRows(rows)
    }
  }, [ state.beneficiariesProfiles ])

  const onRowClick = (row: BeneficiariesRows) => {
    actions.updateSelectedBeneficiary(row)
    history.push('/liste-des-actions')
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems='center' justifyContent={tablet ? 'center' : 'space-between'}>
          <Typography variant={mobile ? 'h5' : 'h4'} className={clsx(classes.title, tablet && classes.alignCenter)}>
            {content.title}
          </Typography>
          <DefaultButton icon={<AddIcon />} label={mobile ? content.beneficiary : content.invitingButton} onClick={() => history.push('/envoie')} />
        </Grid>
        <Grid container item xs={12} zeroMinWidth>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {COLUMNS_BENEFICIARIES_LIST.map((column) => (
                    <TableCell key={column.field} align={column.align} style={{ minWidth: column.width }}>
                      {column.description}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {beneficiariesRows.map((row: any) => {
                  return (
                    <TableRow onClick={() => onRowClick(row)} hover key={row.id} style={{cursor: 'pointer'}}>
                      {COLUMNS_BENEFICIARIES_LIST.map((column) => {
                        const value = row[ column.field ]
                        return (
                          <TableCell key={column.field} align={column.align}>
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

const content = {
  invitingButton: 'Inviter un bénéficiaire',
  beneficiary: 'Bénéficiaire',
  title: 'Liste des bénéficiaires',
}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  alignCenter: {
    textAlign: 'center',
  },
  tableContainer: {
    maxHeight: '50vh',
  },
})
