import React, { FC, useState } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { REGISTRATION_STEP } from '../utils/constants'
import { useStateMachine } from 'little-state-machine'
import { EditProfileForm } from './EditProfileForm'
import { EditConsultantForm } from './EditConsultantForm'
import { EditBeneficiaryForm } from './EditBeneficiaryForm'

export const EditRegistrationSteps: FC = () => {
  const classes = useStyles()
  const [ currentStep, setCurrentStep ] = useState(1)
  const { state } = useStateMachine()

  const stepRender = () => {
    switch (currentStep) {
      case REGISTRATION_STEP.PROFESSIONAL:
        if (state.profile.isConsultant) {
          return <EditConsultantForm previousStep={() => setCurrentStep(1)} />
        }
        return <EditBeneficiaryForm previousStep={() => setCurrentStep(1)} />
      default:
        return <EditProfileForm nextStep={() => setCurrentStep(2)} />
    }
  }

  return (
    <>
      <Typography variant='h5' className={classes.title}>
        {content.title}
      </Typography>
      {stepRender()}
    </>
  )
}

const content = {
  title: 'Modifier mon profil',
}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
})

