import React, { FC, useEffect } from 'react'
import { HalfSplitTemplate } from '../templates/HalfSplitTemplate'
import { Logo } from '../atoms/Logo'
import { SignUpForm } from '../organisms/SignUpForm'
import { useStateMachine } from 'little-state-machine'
import { updateIsConsultant } from '../stores/store'
import { useParams } from 'react-router'
import { IType } from '../utils/interfaces'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { BREAKPOINTS } from '../utils/constants'
import makeStyles from '@material-ui/core/styles/makeStyles'

const SignUp: FC = () => {
  const classes = useStyles()
  const { type } = useParams<IType>()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const { state, actions } = useStateMachine({ updateIsConsultant })

  useEffect(() => {
    actions.updateIsConsultant(type === 'consultant')
  }, [ state.isLoadingUser ])

  return (
    <HalfSplitTemplate
      main={
        <>
          <Logo styleProps={mobile ? classes.logoWidthMobile : classes.logoWidth} />
          <SignUpForm />
        </>
      }
    />
  )
}

const useStyles = makeStyles({
  logoWidth: {
    width: '15rem',
  },
  logoWidthMobile: {
    width: '10rem',
  },
})

export default SignUp
