import React, { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SignUp from '../pages/SignUp'
import SignIn from '../pages/SignIn'
import RegistrationSteps from '../pages/RegistrationSteps'
import PortalConsultant from '../pages/PortalConsultant'
import PortalBeneficiary from '../pages/PortalBeneficiary'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import RequestAccess from '../pages/RequestAccess'
import { useStateMachine } from 'little-state-machine'
import { PrivateRoute } from './PrivateRoute'
import NotFound from '../pages/NotFound'
import { CGU } from '../organisms/CGU'
import { PrivacyPolicies } from '../organisms/privacyPolicies'

export const Routes: FC = () => {
  const { state } = useStateMachine()
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/connexion' component={SignIn} />
        <Route exact path='/:type/inscription' component={SignUp} />
        <Route exact path='/:type/politique-de-confidentialite' component={PrivacyPolicies}/>
        <Route exact path='/mot-de-passe-oublie' component={ForgotPassword} />
        <Route exact path='/reinitialisation-mot-de-passe' component={ResetPassword} />
        <Route exact path='/conditions-generales-utilisation' component={CGU} />
        <Route exact path='/acces' component={RequestAccess} />
        <PrivateRoute exact path='/' component={state.profile.isConsultant ? PortalConsultant : PortalBeneficiary} />
        <PrivateRoute exact path='/mon-parcours' component={PortalBeneficiary} />
        <PrivateRoute exact path='/envoie' component={PortalConsultant} />
        <PrivateRoute exact path='/liste-des-beneficiaires' component={PortalConsultant} />
        <PrivateRoute exact path='/liste-des-actions' component={PortalConsultant} />
        <PrivateRoute exact path='/modifier-mon-compte' component={state.profile.isConsultant ? PortalConsultant : PortalBeneficiary} />
        <PrivateRoute exact path='/profil' component={RegistrationSteps} />
        <Route exact path='*' component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}
