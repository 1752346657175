export const mailAccess = (lastname: string, uid: string | undefined) =>
  'Bonjour,%0A%0A' +
  'Le consultant ' + lastname +
  ' vous demande l’autorisation d’accès à votre parcours numérique sur la plateforme SAM.I afin de renseigner l’étape de votre accompagnement et' +
  ' faciliter vos démarches de retour dans l’emploi: ' +
  `https://sami.tech4work.fr/acces?p=${btoa(JSON.stringify({uid, newAccount: false}))}` + '%0A%0A' +
  'SAM.I Plateforme collaborative Maintien dans l\'emploi %0A%0A'

export const mailRegistration = (lastname: string, uid: string | undefined) => {
  return 'Bonjour,%0A%0A' +
  'Le consultant ' + lastname +
  ' vous invite à créer votre compte sur la plateforme afin de faciliter vos démarches de retour et de maintien dans l\'emploi. %20' +
  `https://sami.tech4work.fr/acces?p=${btoa(JSON.stringify({uid, newAccount: true}))}` + '%0A%0A' +
  'SAM.I Plateforme collaborative Maintien dans l\'emploi %0A%0A'
}
