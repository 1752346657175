import React, { FC } from 'react'
import Slider from 'react-slick'
import { makeStyles, Typography } from '@material-ui/core'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { BREAKPOINTS, IMAGES_CARROUSEL, IMAGES_CARROUSEL_MOBILE } from '../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const Carousel: FC = () => {
  const classes = useStyles()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const images = [
    {
      key: 1,
      name: 'image1',
      url: IMAGES_CARROUSEL[ 0 ],
      urlMobile: IMAGES_CARROUSEL_MOBILE[ 0 ],
    },
    {
      key: 2,
      name: 'image2',
      url: IMAGES_CARROUSEL[ 1 ],
      urlMobile: IMAGES_CARROUSEL_MOBILE[ 1 ],
    },
    {
      key: 3,
      name: 'image3',
      url: IMAGES_CARROUSEL[ 2 ],
      urlMobile: IMAGES_CARROUSEL_MOBILE[ 2 ],
    },
    {
      key: 4,
      name: 'image4',
      url: IMAGES_CARROUSEL[ 3 ],
      urlMobile: IMAGES_CARROUSEL_MOBILE[ 3 ],
    },
  ]

  const settings = {
    infinite: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
  }

  return (
    <Slider{...settings} className={tablet ? mobile ? classes.sliderMobile : classes.sliderTablet : classes.slider}>
      {images.map((image) => {
        return (
          <div key={image.key}>
            <Typography style={{ height: 0 }}>.</Typography>
            <img
              src={tablet ? image.urlMobile : image.url}
              alt={image.name}
              className={tablet ? classes.imageTablet : classes.image}
            />
          </div>
        )
      })}
    </Slider>
  )
}

const useStyles = makeStyles({
  slider: {
    height: '100vh',
    display: 'flex',
  },
  image: {
    height: '100vh',
    margin: '0 auto',
    width: '100%',
  },
  sliderTablet: {
    height: '40vh',
    display: 'flex',

  },
  imageTablet: {
    height: '40vh',
    margin: '0 auto',
    width: '100%',
  },
  sliderMobile: {
    display: 'none',
  },
})
