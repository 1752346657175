import { Action, ActionHistory } from '../utils/type'
import { db } from '../utils/firebase'

export const saveAction = async(uid: string | undefined, action: Action) => {
  return await db
    .collection('beneficiary')
    .doc(uid)
    .collection('action')
    .add(action)
    .then((payload) => payload.id)
    .catch((error) => console.log('Error saveAction =>', error))
}

export const updateAction = async(uid: string | undefined, id: string | undefined, action: Action) => {
  await db
    .collection('beneficiary')
    .doc(uid)
    .collection('action')
    .doc(id)
    .update(action)
    .then((payload) => payload)
    .catch((error) => console.log('Error updatedAction =>', error))
}

export const deleteAction = (uid: string | undefined) => {
  db.collection('beneficiary')
    .doc(uid)
    .collection('action')
    .doc()
    .delete()
    .then((payload) => payload)
    .catch((error) => console.log('Error deleteBeneficiary ======>', error))
}

export const generateActionFromForm = (formData: any, createdDate: Date, updateDate: Date | undefined, organism: string, consultant: string): Action => ({
  status: formData.status,
  deviceType: formData.deviceType,
  actionDescription: formData.actionDescription,
  createdDate,
  updateDate,
  organism,
  consultant,
})

export const fetchActions = async(uid: string | undefined) => {
  if (uid) {
    return await db
      .collection('beneficiary')
      .doc(uid)
      .collection('action')
      .orderBy('createdDate', 'desc')
      .get()
      .then((payload) => {
        const actionCache: Action[] = []
        for (const doc of payload.docs) {
          actionCache.push({ id: doc.id, ...(doc.data() as Action) })
        }
        return actionCache
      })
  }
}

export const fetchActionHistory = async(uid: string | undefined, actionId: string | undefined) => {
  if (uid && actionId) {
    return await db
      .collection(`beneficiary/${uid}/action/${actionId}/history`)
      .orderBy('updateDate', 'desc')
      .get()
      .then((payload) => payload.docs.map((d) => d.data()))
      .catch((error) => console.log('ERROR FETCHING HISTORY OF ACTION', error?.message))
  }
}

export const updateActionHistory = async(uid: string, actionId: string | undefined, actionHistory: ActionHistory[]) => {
  if (actionId && !!actionHistory) {
    return await Promise.all([
      actionHistory.map((actionEdit) =>
        db
          .collection(`beneficiary/${uid}/action/${actionId}/history`)
          .add(actionEdit)
          .then((payload) => payload)
          .catch((error) => console.log(error?.message)),
      ),
    ])
  }
}
