import React, { FC, useEffect, useState } from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { InputField } from '../molecules/InputField'
import { DefaultButton } from '../atoms/DefaultButton'
import { Link, useHistory } from 'react-router-dom'
import { auth } from '../utils/firebase'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signInSchema } from '../utils/validation'
import { LoginField } from '../utils/type'
import { useStateMachine } from 'little-state-machine'
import { updateIsAuthenticated } from '../stores/store'
import { Popup } from '../molecules/Popup'
import { getErrorMessage } from '../utils/PopupMessage'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

export const SignInForm: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(BREAKPOINTS.mobile)
  const tablet = useMediaQuery(BREAKPOINTS.tablet)
  const [ popupMessage, setPopupMessage ] = useState<string>('')
  const [ open, setOpen ] = useState(false)
  const { state, actions } = useStateMachine({ updateIsAuthenticated })

  useEffect(() => {
    if (state.permissionForUidConsultant.uid.length > 0 && state.permissionForUidConsultant.newAccount) {
      history.push('/beneficiaire/inscription')
    }
  }, [ state.permissionForUidConsultant ])

  const signInWithEmailAndPassword = (data: LoginField) => {
    auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        actions.updateIsAuthenticated(true)
        state.permissionForUidConsultant ? history.push('/acces') : history.push('/')
      })
      .catch((error) => {
        setOpen(true)
        setPopupMessage(getErrorMessage(error.code))
      })
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  })

  return (
    <>
      <Popup
        open={open}
        title='Erreur'
        message={popupMessage}
        handleClose={() => setOpen(false)}
        handleValidate={() => undefined}
        backButton='Retour'
      />
      <form className={classes.form}>
        <InputField
          control={control}
          name='email'
          error={errors}
          placeholder={content.placeholderEmail}
          label={content.labelEmail}
          styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
        />
        <InputField
          control={control}
          name='password'
          error={errors}
          label={content.labelPassword}
          isPassword={true}
          styleProps={tablet ? classes.fixedWidthMobile : classes.fixedWidth}
        />
        <div className={clsx(classes.containerSelector, tablet ? classes.fixedWidthMobile : classes.fixedWidth)}>
          <Link className={clsx(classes.link, classes.typo)} to='/mot-de-passe-oublie'>
            {content.forgotPassword}
          </Link>
        </div>
        <DefaultButton
          label={content.signIn}
          onClick={handleSubmit(signInWithEmailAndPassword)}
          styleProps={clsx(classes.customButton, tablet ? classes.fixedWidthMobile : classes.fixedWidth)}
        />
        <Typography align='center' variant='body2' className={classes.typo}>{content.noAccount}</Typography>
        <Grid container item xs={10} justifyContent='space-around'>
          <DefaultButton variant='text' type='info' onClick={() => history.push('/beneficiaire/inscription')} label={content.noAccountBeneficiary} />
          <Divider flexItem orientation='vertical' />
          <DefaultButton variant='text' type='danger' onClick={() => history.push('/consultant/inscription')} label={content.noAccountConsultant} />
        </Grid>
        <Box component='div' className={clsx(classes.mention, tablet && classes.fixedMentionTablet)} display='flex' flexDirection={mobile ? 'column' : 'row'}>
          <Typography variant='caption'>Tech for Work © - Copyright 2022 - </Typography>
          <Typography variant='caption'> Tous droits réservés</Typography>
        </Box>
      </form>
    </>
  )
}

const content = {
  labelEmail: 'E-mail',
  placeholderEmail: 'Saisissez votre e-mail',
  labelPassword: 'Mot de passe',
  signIn: 'Connexion',
  signUp: 'S\'inscrire',
  forgotPassword: 'Mot de passe oublié ?',
  noAccount: 'Pas encore de compte ? S\'inscrire en tant que',
  noAccountBeneficiary: 'bénéficiaire',
  noAccountConsultant: 'consultant',
}

const useStyles = makeStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  containerSelector: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '17rem',
  },
  fixedWidth: {
    width: '40vw',
  },
  fixedWidthMobile: {
    width: '80vw',
  },
  customButton: {
    marginBottom: '2rem',
    minWidth: '19rem',
  },
  link: {
    color: COLORS.black,
    fontWeight: 'bold',
    textDecorationLine: 'none',
  },
  typo: {
    fontSize: 12,
    marginBottom: '1rem',
  },
  mention: {
    zIndex: 2,
    color: COLORS.white,
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  fixedMentionTablet: {
    color: COLORS.black,
    width: '100%',
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: '3rem',
    bottom: 5,
    right: 5,
  },
})
