import { Profile } from '../utils/type'
import { db } from '../utils/firebase'
import firebase from 'firebase/app'

export const searchProfile = async(uid: string | undefined) =>
  await db
    .collection('profile')
    .doc(uid)
    .get()
    .then((payload) => payload.data() as Profile)
    .catch((error) => {
      console.log('Error searchProfile ======>', error)
      return undefined
    })

export const saveProfile = async(uid: string | undefined, profile: Profile) => {
  await db
    .collection('profile')
    .doc(uid)
    .set(profile)
    .then((payload) => payload)
    .catch((error) => console.log('Error saveProfile ======>', error))
}

export const deleteProfile = (uid: string | undefined) => {
  db
    .collection('profile')
    .doc(uid)
    .delete()
    .then((payload) => payload)
    .catch((error) => console.log('Error deleteProfile ======>', error))
}

export const generateProfileFromForm = (formData: any, isConsultant: boolean): Profile => ({
  firstname: formData.firstname,
  lastname: formData.lastname,
  phone: formData.phone,
  street: formData.street,
  city: formData.city,
  postcode: formData.postcode,
  birthday: formData.birthday,
  isConsultant,
})

export const fetchProfiles = async(beneficiaryList: string[]) => {
  return await db
    .collection('profile')
    .where(firebase.firestore.FieldPath.documentId(), 'in', beneficiaryList)
    .get()
    .then((payload) => {
      const profileCache: Map<string, Profile> = new Map()
      payload.forEach((doc) => {
        profileCache.set(doc.id, doc.data() as Profile)
      })
      return profileCache
    })
}
