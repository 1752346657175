const AIDE_FORMATION = { key: 'AIDE_FORMATION', label: 'Aide financière à la formation' }
const AIDE_MAINTIEN_EMPLOI = { key: 'AIDE_MAINTIEN_EMPLOI', label: 'Aide financière maintien de l\'emploi' }
const AAH = { key: 'AAH', label: 'Allocation adulte handicapé (AAH)' }
const BILAN_DE_COMPETENCES = { key: 'BILAN_DE_COMPETENCES', label: 'Bilan de compétences' }
const CEP = { key: 'CEP', label: 'CEP (Conseil en Évolution Professionnelle)' }
const CRPE = { key: 'CRPE', label: 'CRPE (Contrat de Rééducation Professionnelle en Entreprise)' }
const INFOS_RETRAITES = { key: 'INFOS_RETRAITES', label: 'Infos droits retraites' }
const ACTION_REMISE_NIVEAU = { key: 'ACTION_REMISE_NIVEAU', label: 'Action de remise à niveau' }
const PAS = { key: 'PAS', label: 'PAS (Prestation d\'Appui Spécifique)' }
const PAS_HANDICAP_AUDITIF = { key: 'PAS_HANDICAP_AUDITIF', label: 'PAS handicap auditif' }
const PAS_HANDICAP_PSYCHIQUE = { key: 'PAS_HANDICAP_PSYCHIQUE', label: 'PAS handicap psychique' }
const PAS_HANDICAP_VISUEL = { key: 'PAS_HANDICAP_VISUEL', label: 'PAS handicap visuel' }
const PENSION_INVALIDE = { key: 'PENSION_INVALIDE', label: 'Pension d\'invalidité' }
const PMSMP = { key: 'PMSMP', label: 'PMSMP (Période de Mise en Situation en Milieu Professionnel)' }
const IPF = { key: 'IPF', label: 'Inclu Pro Formation (IPF)' }
const RQTH = { key: 'RQTH', label: 'RQTH (Reconnaissance Qualité de Travailleur Handicapé)' }
const TEMPS_PARTIEL_THERAPEUTIQUE = { key: 'TEMPS_PARTIEL_THERAPEUTIQUE', label: 'Temps partiel thérapeutique' }
const VAE = { key: 'VAE', label: 'VAE (Validation des Acquis de l\'Expérience)' }
const TRAVAIL_LEGER = { key: 'TRAVAIL_LEGER', label: 'Travail léger' }
const DPI = { key: 'DPI', label: 'DPI (Démarche Précoce d\'Insertion socio profesionnelle)' }
const STAGE_UEROS = { key: 'STAGE_UEROS', label: 'Stage UEROS' }
const VISITE_PRE_REPRISE = { key: 'VISITE_PRE_REPRISE', label: 'Visite de pré reprise' }
const ESSAI_ENCADRE = { key: 'ESSAI_ENCADRE', label: 'Essai encadré' }
const ORIENTATION_PROFESSIONNELLE = { key: 'ORIENTATION_PROFESSIONNELLE', label: 'Orientation professionnelle' }
const EPAAST = { key: 'EPAAST', label: 'Étude préalable à l\'aménagement et l\'adaptation de la situation de travail (EPAAST)' }
const INFOS_IJSS = { key: 'INFOS_IJSS', label: 'Infos droits IJSS (Indémnité journalière versée par le Sécurité Sociale)' }
const RETRAITE_ANTICIPE_CARRIER_LONGUE = { key: 'RETRAITE_ANTICIPE_CARRIER_LONGUE', label: 'Retraite anticipée dispositif carrière longue' }
const RETRAITE_ANTICIPE_PENIBILITE = { key: 'RETRAITE_ANTICIPE_PENIBILITE', label: 'Retraite anticipée dispositif pénibilité' }
const RETRAITE_ANTICIPE_INAPTITUDE = { key: 'RETRAITE_ANTICIPE_INAPTITUDE', label: 'Retraite anticipée pour inaptitude au travail' }
const BILAN_FONCTIONNEL_PROFESSIONNEL = { key: 'BILAN_FONCTIONNEL_PROFESSIONNEL', label: 'Bilan fonctionnel et professionnel' }
const BILAN_UEROS = { key: 'BILAN_UEROS', label: 'Bilan UEROS (Unité d\'Evaluation de Ré entraînement et d\'Orientation Sociale)' }
const INDEMNITE_TEMPORAIRE_INAPTITUDE = { key: 'INDEMNITE_TEMPORAIRE_INAPTITUDE', label: 'Indemnité temporaire d\'inaptitude' }
const ETUDE_POSTE_DE_TRAVAIL = { key: 'ETUDE_POSTE_DE_TRAVAIL', label: 'Étude ergonomique aménagement du poste de travail' }
const STAGE_PREORIENTATION_PROFESSIONNELLE = { key: 'STAGE_PREORIENTATION_PROFESSIONNELLE', label: 'Stage de préorientation professionnelle' }
const OTHER = { key: 'OTHER', label: 'Autre' }

export const DEVISE_BOETH = [
  AIDE_FORMATION,
  AIDE_MAINTIEN_EMPLOI,
  AAH,
  BILAN_DE_COMPETENCES,
  CEP,
  CRPE,
  EPAAST,
  ETUDE_POSTE_DE_TRAVAIL,
  INFOS_IJSS,
  INFOS_RETRAITES,
  ACTION_REMISE_NIVEAU,
  PAS,
  PAS_HANDICAP_AUDITIF,
  PAS_HANDICAP_PSYCHIQUE,
  PAS_HANDICAP_VISUEL,
  PENSION_INVALIDE,
  PMSMP,
  IPF,
  RETRAITE_ANTICIPE_CARRIER_LONGUE,
  RETRAITE_ANTICIPE_PENIBILITE,
  RETRAITE_ANTICIPE_INAPTITUDE,
  RQTH,
  TEMPS_PARTIEL_THERAPEUTIQUE,
  VAE,
  TRAVAIL_LEGER,
  OTHER,
]
export const DEVISE_BOETH_ARREST = [
  ACTION_REMISE_NIVEAU,
  AIDE_FORMATION,
  AIDE_MAINTIEN_EMPLOI,
  AAH,
  BILAN_DE_COMPETENCES,
  BILAN_FONCTIONNEL_PROFESSIONNEL,
  BILAN_UEROS,
  CEP,
  CRPE,
  DPI,
  EPAAST,
  ESSAI_ENCADRE,
  ETUDE_POSTE_DE_TRAVAIL,
  INDEMNITE_TEMPORAIRE_INAPTITUDE,
  INFOS_IJSS,
  INFOS_RETRAITES,
  ORIENTATION_PROFESSIONNELLE,
  PAS,
  PAS_HANDICAP_AUDITIF,
  PAS_HANDICAP_PSYCHIQUE,
  PAS_HANDICAP_VISUEL,
  PENSION_INVALIDE,
  IPF,
  RETRAITE_ANTICIPE_CARRIER_LONGUE,
  RETRAITE_ANTICIPE_PENIBILITE,
  RQTH,
  STAGE_PREORIENTATION_PROFESSIONNELLE,
  STAGE_UEROS,
  TEMPS_PARTIEL_THERAPEUTIQUE,
  VAE,
  VISITE_PRE_REPRISE,
  OTHER,
]
export const DEVISE_NOT_BOETH = [
  ACTION_REMISE_NIVEAU,
  BILAN_DE_COMPETENCES,
  ETUDE_POSTE_DE_TRAVAIL,
  INFOS_IJSS,
  INFOS_RETRAITES,
  PENSION_INVALIDE,
  PMSMP,
  RETRAITE_ANTICIPE_CARRIER_LONGUE,
  RETRAITE_ANTICIPE_PENIBILITE,
  RETRAITE_ANTICIPE_INAPTITUDE,
  RQTH,
  TEMPS_PARTIEL_THERAPEUTIQUE,
  VAE,
  TRAVAIL_LEGER,
  OTHER,
]
export const DEVISE_NOT_BOETH_ARREST = [
  ACTION_REMISE_NIVEAU,
  BILAN_DE_COMPETENCES,
  BILAN_FONCTIONNEL_PROFESSIONNEL,
  BILAN_UEROS,
  DPI,
  ESSAI_ENCADRE,
  ETUDE_POSTE_DE_TRAVAIL,
  INDEMNITE_TEMPORAIRE_INAPTITUDE,
  INFOS_IJSS,
  INFOS_RETRAITES,
  PENSION_INVALIDE,
  RETRAITE_ANTICIPE_CARRIER_LONGUE,
  RETRAITE_ANTICIPE_PENIBILITE,
  RETRAITE_ANTICIPE_INAPTITUDE,
  RQTH,
  TEMPS_PARTIEL_THERAPEUTIQUE,
  VAE,
  VISITE_PRE_REPRISE,
  TRAVAIL_LEGER,
  OTHER,
]

export const ALL_DEVICE = [
  AIDE_FORMATION,
  AIDE_MAINTIEN_EMPLOI,
  AAH,
  BILAN_DE_COMPETENCES,
  CEP,
  CRPE,
  INFOS_RETRAITES,
  ACTION_REMISE_NIVEAU,
  PAS,
  PAS_HANDICAP_AUDITIF,
  PAS_HANDICAP_PSYCHIQUE,
  PAS_HANDICAP_VISUEL,
  PENSION_INVALIDE,
  PMSMP,
  IPF,
  RQTH,
  TEMPS_PARTIEL_THERAPEUTIQUE,
  VAE,
  TRAVAIL_LEGER,
  DPI,
  STAGE_UEROS,
  VISITE_PRE_REPRISE,
  ESSAI_ENCADRE,
  ORIENTATION_PROFESSIONNELLE,
  EPAAST,
  INFOS_IJSS,
  RETRAITE_ANTICIPE_CARRIER_LONGUE,
  RETRAITE_ANTICIPE_PENIBILITE,
  RETRAITE_ANTICIPE_INAPTITUDE,
  BILAN_FONCTIONNEL_PROFESSIONNEL,
  BILAN_UEROS,
  INDEMNITE_TEMPORAIRE_INAPTITUDE,
  ETUDE_POSTE_DE_TRAVAIL,
  STAGE_PREORIENTATION_PROFESSIONNELLE,
  OTHER,
]
