import React, { FC } from 'react'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { BREAKPOINTS, COLORS } from '../utils/constants'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface SelectFieldProps {
  control: Control<any> | undefined
  error: FieldErrors
  name: string
  label: string
  options: { key: string; label: string }[]
  defaultValue?: string
  styleProps?: string
  disabled?: boolean
  widthInnerContainer?: string
}

export const SelectField: FC<SelectFieldProps> = ({
  control,
  error,
  name,
  label,
  defaultValue,
  options,
  styleProps,
  disabled,
  widthInnerContainer,
}: SelectFieldProps) => {
  const classes = useStyles()
  const tablet = useMediaQuery(BREAKPOINTS.tablet)

  return (
    <Grid item style={{ width: widthInnerContainer }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            disabled={disabled}
            select
            inputRef={ref}
            label={label}
            value={value}
            onBlur={onBlur}
            error={!!error[ name ]}
            className={clsx(classes.input, styleProps, tablet ? classes.inputWidthMobile : classes.inputWidth)}
            onChange={(value) => onChange(value)}
            variant='outlined'
            SelectProps={{
              native: true,
            }}
          >
            <option aria-label='None' value='' />
            {options.map((option) => (
              <option key={option.key} value={option.key}>
                {option.label}
              </option>
            ))}
          </TextField>
        )}
      />
    </Grid>
  )
}

SelectField.defaultProps = {
  defaultValue: '',
}

const useStyles = makeStyles({
  input: {
    minWidth: '18rem',
    marginTop: '1.3rem',
    '& label.Mui-focused': {
      color: COLORS.cyanForte,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.cyanForte,
      },
    },
  },
  inputWidth: {
    width: '32.3vw',
  },
  inputWidthMobile: {
    width: '70vw',
  },
})
