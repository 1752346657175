import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

interface FormTemplateProps {
  main: JSX.Element | null
  next?: JSX.Element | null
  previous?: JSX.Element | null
}

export const FormTemplate: FC<FormTemplateProps> = ({ main, next, previous }: FormTemplateProps) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root} justifyContent='center'>
      <Grid item xs={12} className={classes.rootItem}>
        {main}
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='space-around'>
          <Grid item>{previous}</Grid>
          <Grid item>{next}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  rootItem: {
    padding: '1rem',
  },
})
